import { create } from "zustand"
export const useConfirmDialog = create((set, get) => ({
    confirmed: false,
    setConfirmed: (newData) => set({ confirmed: newData }),
    openQRcode: { visible: false, data: null },
    setOpenQRcode: (newData) => set({ openQRcode: newData })

}))















