import { create } from "zustand"
import { persist } from "zustand/middleware"

export const useStoreCategoryProducts = create(persist((set, get) => ({
    categoryProducts: [],
    kodeCategory: '004',
    _hasHydratedCategoryProd: false,
    setHasHydratedCategoryProd: (state) => {
        set({  _hasHydratedCategoryProd: state  });
    },
    inserProducts: (category) => {
        set((state) => ({
            categoryProducts: [...state.categoryProducts, category],
        }));
    }
}),
    {
        name: `product_category`,
        onRehydrateStorage: () => (state) => {
            state.setHasHydratedCategoryProd(true)
        }
    }

))