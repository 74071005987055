import { useEffect } from 'react';
import { setLocalStorage } from '@data/localStorageSetter';
import { useStoreAppData } from '@state/useGlobalState';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStorePayment } from '@state/useStorePaymentMethod';
import { useStoreFeedback } from '@state/useStoreFeedback';
import { useStoreLogo } from '@state/useStoreLogo';
import { useStoreSpreadProduct } from '@state/useStoreSpreadProduct';
import { useStoreProduct } from '@state/useStoreProduct';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { saveProductData, saveFlatProductData } from '@data/localStorageSetter';
import { useStoreToggleDrawer } from '@state/useStoreToggleDrawer';
import { useStoreTabData } from '@state/useStoreTabData';
import { useStoreItemsDetails } from '@state/useStoreItemsDetails';
import { useStoreProductCategory } from '@state/useStoreProductCategory';
import { useStoreOrderType } from '@state/useStoreOrderType';

export default function useSetupApp() {
  const { appData } = useStoreAppData((state) => ({ appData: state.appData }));
  const data = appData;
  const { updateSettings } = useStoreSettings((state) => ({
    updateSettings: state.updateSettings,
  }));
  const { updateOutlet } = useStoreOutlet((state) => ({
    updateOutlet: state.updateOutlet,
  }));
  const { updateAppColor } = useStoreAppColor((state) => ({
    updateAppColor: state.updateAppColor,
  }));
  const { updateLogoUrl } = useStoreLogo((state) => ({
    updateLogoUrl: state.updateLogoUrl,
  }));
  const { updateFeedback } = useStoreFeedback((state) => ({
    updateFeedback: state.updateFeedback,
  }));
  const { updatePayment } = useStorePayment((state) => ({
    updatePayment: state.updatePayment,
  }));
  const { updateProducts, setFlatProducts } = useStoreProduct((state) => ({
    updateProducts: state.updateProducts,
    setFlatProducts: state.setFlatProducts,
  }));

  const { setItemsDetail } = useStoreItemsDetails((state) => ({
    setItemsDetail: state.setItemsDetail,
  }));

  const { setCategoryList, setFlatCategoryList } = useStoreToggleDrawer(
    (state) => ({
      setCategoryList: state.setCategoryList,
      setFlatCategoryList: state.setFlatCategoryList,
    }),
  );
  const { setFlatTabData } = useStoreTabData((state) => ({
    setFlatTabData: state.setFlatTabData,
  }));
  const { setProdCategory } = useStoreProductCategory((state) => ({
    setProdCategory: state.setProdCategory,
  }));
  const { order_type } = useStoreOrderType((state) => ({
    order_type: state.order_type,
  }));

  useEffect(() => {
    const constructSetting = {
      isOrderable: data.config?.is_orderable,
      isDisplayCategory: data.config?.display_category,
      isDisplaySearch: data.config?.display_search,
      isDirectOrder: data.config?.direct_order,
      isImageDetail: data.config?.image_detail,
      isDisplayLogin: data.config?.display_login,
      orderMethod: data.config?.order_method || 'normal_order', // normal_order || payment_order || waiter_order || quick_order
      soldOutText: data.config?.sold_out_text,
      confirmOrderText: data.config?.confirm_order_text || '',
      placeholderParameter: data.config?.placeholder_parameter,
      isQRStoreParameter: data.config?.qrstore_parameter,
      tableName: data.config?.tableName,
      subFunctionID: data.config?.sub_function_id_stripe,
      qrcodeTitlePage: data.config?.qrcode_title_page,
      miniAppCheck: data.config?.miniapp_check,
      isShowViewBill: data.config?.is_view_bill_active,
      feedbackText: data.config?.feedback_text,
      isInfinityScroll: data.config?.view_type !== 'tab_menu',
      isShowImageType: data.config?.show_image_type,
      isProductOptionFold: data.config?.product_option_fold,
      isShowQrScanner: data.config?.show_qr_scanner,
      languages: data.config?.languages,
      maintenance: data.config?.maintenance_mode,
      customTable: data.config?.custom_table,
      useSiccMember: data.config?.use_sicc_member,
      weatherWarning: data.config?.use_weather_warning,
      qrStoreOrderType: data.config?.qr_store_order_type,
      qrTableOrderType: data.config?.qr_table_order_type,
      hasServiceCharge: data.config?.has_service_charge,
      timezone: data.config?.timezone,
    };

    const salestype = {
      active: order_type?.result?.order_types.length,
      sales: order_type?.result?.order_types,
    };

    const constructOutlet = {
      outletId: data.config?.storeUUID,
      openingHours: data.config?.openingHours,
      outletName: data.config?.restaurantName,
      tableId: data.config?.tableID,
      tableName: data.config?.tableName,
      salestype: salestype,
      currency: data.config?.currency,
      currencySymbol: data.config?.currency_symbol,
      localeCode: data.config?.locale_code,
      out_operational_time_text: data.config?.custom_message,
    };

    const constructColorApp = {
      primaryThemeColor: data.config?.primary_color || '#FF8A37',
      secondaryThemeColor: data.config?.secondary_color || '#FEEDE2',
      primaryFontColor: data.config?.primary_font_color || '#000000',
      secondaryFontColor: data.config?.secondary_font_color || '#ffffff',
      backgroundColor: data.config?.background_color || '#FFE7D7',
      backgroundProduct: data.config?.product_background_color || '#FFFFFF',
      headerAccordion: data.config?.header_color || '#FF911C',
      successAccordion: data.config?.success_color || '#00AA13',
      addButton: data.config?.add_to_cart_color || '#00AA13',
      backgroundAccordion: data.config?.background_accordion_color || '#FFFFFF',
    };
    if (data.config?.logoUrl) {
      updateLogoUrl(data.config?.logoUrl);
    }
    if (data.status && data.status === 'closed') {
      const constructOutlet = {
        out_operational_time_text: data.out_operational_time_text,
        status: data.status,
      };
      updateOutlet(constructOutlet);
    } else {
      updateOutlet(constructOutlet);
      updateSettings(constructSetting);
      updateAppColor(constructColorApp);
      if (data && data.config) {
        updateFeedback(data.feedback);
        updatePayment(data.config.payment_channels);
        saveProductData(data.product);
        setFlatProducts(data.product);
        updateProducts(data.product);
        setItemsDetail(data.product);
        setCategoryList(data.product);
        setFlatTabData(data.product);
        setProdCategory(data.product);
        setFlatCategoryList(data.product);
      }
    }
  }, [data, order_type]);

  return appData;
}
