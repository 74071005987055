import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames"

export const useStoreSettings = create(persist((set, get) => ({
    settings: {},
    kodeSetting: '009',
    _hasHydratedSetting: false,
    setHasHydratedSetting: (state) => {
        set({  _hasHydratedSetting: state  });
    },
    updateSettings: (newSettings) => {
        set({ settings: newSettings })
    },
    getLanguages: () => {
        return get().settings.languages
    },
    getOrderMethod: () => {
        let settings = get().settings || {};
        return settings['orderMethod'] || 'normal_order';
    },
    getSetting: () => {
        return get().settings
    },
    //UNTUK DEV PANEL
    setOrderMethod: (value) => {
        set((state) => ({
            settings: { ...state.settings, orderMethod: value }
        }));
    },
    clearSetting: () => set({ settings: {} })
}),
    {
        name: localStorageNames.SETTING,
        onRehydrateStorage: () => (state) => {
            state.setHasHydratedSetting(true)
        }
    }

))