import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useRealmContext } from '@context/RealmContext';
import * as Realm from 'realm-web';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCartContext } from '@context/CartContext';
import { useGeneralContext } from '@context/GeneralContext';
import ReBillLayout from '@components/ReBillLayout';
import URLS from '@/URLS';
import moment from 'moment';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreViewBill } from '@state/useStoreViewBill';
import { useStoreSiccMember } from '@state/useStoreSiccMember';
import { useCheckoutState } from '@state/checkout/useCheckoutState';
import { getTableNames } from '@libs/Checkout/getTableNames';

const ViewBillPage = () => {
  const history = useHistory();
  const { getOutletInfo } = useStoreOutlet((state) => ({
    getOutletInfo: state.getOutletInfo,
  }));
  const { getSetting, getOrderMethod } = useStoreSettings((state) => ({
    getSetting: state.getSetting,
    getOrderMethod: state.getOrderMethod,
  }));
  const {
    getTransactionsId,
    getLastTransaction,
    getDetailTransaction,
    watchMiniAppReq,
  } = useRealmContext();

  const { viewBillId: transId } = useStoreViewBill((state) => ({
    viewBillId: state.viewBillId,
  }));

  const { siccMember } = useStoreSiccMember((state) => ({
    siccMember: state.siccMember,
  }));

  const { clearCart, setRealmCartItems } = useCartContext();
  const [showViewBill, setShowViewBillPage] = useState(true);
  const { showLoading } = useGeneralContext();

  const { tableName } = useCheckoutState((state) => ({
    tableName: state.tableName,
  }));

  const { t } = useTranslation();
  const [localState, setLocalState] = useState({
    outlet: '',
    table: '',
    billData: {},
    billTaxes: [],
    billDiscount: [],
    billSurcharge: [],
    billSubTotal: 0,
    billGrandTotal: 0,
    disabledRefresh: false,
    isShowHistory: false,
    loadingUpdate: false,
    isAddress: '',
    isSalesNo: '',
    rcptNo: '',
  });
  const mountedRef = useRef(true);
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const updateLocalState = useCallback((newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  }, []);

  const getLocalState = (key) => {
    return key ? localState[key] : localState;
  };

  const calculateSumTaxes = (oldTaxes, newTaxes) => {
    let sumTaxes = [];
    newTaxes.map((tax) => {
      let oldIndex = oldTaxes.findIndex((x) => x.TTax === tax.TTax);
      if (oldIndex !== -1) {
        oldTaxes[oldIndex].value = oldTaxes[oldIndex].value + tax.value;
        sumTaxes.push(oldTaxes[oldIndex]);
      } else {
        sumTaxes.push(tax);
      }
      return null;
    });

    return sumTaxes;
  };

  const onConfirm = () => {
    let outletInfo = getOutletInfo();

    if (outletInfo.currency === 'sgd') {
      history.push(URLS.PAYMENT_STRIPE, {
        data: getLocalState('billGrandTotal'),
      });
    } else {
      history.push(URLS.PAYMENT, { data: getLocalState('billGrandTotal') });
    }
  };

  const standardizeData = (paramData) => {
    let dataBySalesType = {};
    let sumTaxes = [];
    let sumDiscount = [];
    let sumSurcharge = [];
    let sumSubTotal = 0;
    let sumGrandTotal = 0;

    paramData.map((data) => {
      let {
        transaction_items,
        sub_total,
        grand_total,
        taxes,
        discounts,
        surcharge,
      } = data;

      Array.isArray(transaction_items) &&
        transaction_items.map((item) => {
          if (
            typeof dataBySalesType[item.sales_type] === 'object' &&
            Object.keys(dataBySalesType[item.sales_type]).length > 0
          ) {
            // UPDATE OLD BILL DATA
            dataBySalesType[item.sales_type].transaction_items.push(item);
          } else {
            // NEW BILL DATA FOR THIS TYPE SALES
            let insertData = {
              transaction_items: [item],
            };

            dataBySalesType[item.sales_type] = { ...insertData };
          }
          return null;
        });

      sumTaxes = calculateSumTaxes(sumTaxes, taxes);
      sumDiscount = discounts;
      sumSurcharge = surcharge !== undefined ? surcharge : sumSurcharge;
      sumSubTotal = sumSubTotal + sub_total;
      sumGrandTotal = sumGrandTotal + grand_total;
      return null;
    });

    return {
      data: dataBySalesType,
      taxes: sumTaxes,
      discounts: sumDiscount,
      surcharge: sumSurcharge,
      sub_total: sumSubTotal,
      grand_total: sumGrandTotal,
    };
  };

  const getViewBillDetail = async (queryTrans, outletInfo) => {
    if (queryTrans && Array.isArray(queryTrans) && queryTrans.length > 0) {
      setShowWarningMessage(false);
      let isSplit = false;
      queryTrans.forEach((data) => {
        if (data.is_split) {
          isSplit = true;
        }
      });

      if (isSplit) {
        setShowWarningMessage(true);
      } else {
        setShowWarningMessage(false);
      }

      let result = queryTrans.filter((data) => !data.is_split);

      // 3. standardize data structure & join multiple data by typesales
      let standardData = standardizeData(result);

      // 4. set state
      updateLocalState({
        outlet: outletInfo.outletName,
        table: result[0]?.table_name,
        billData: { ...standardData.data },
        billTaxes: [...standardData.taxes],
        billDiscount: [...standardData.discounts],
        billSurcharge: [...standardData.surcharge],
        billSubTotal: standardData.sub_total,
        billGrandTotal: standardData.grand_total,
        isShowHistory: false,
        isAddress: result[0].store_address,
        isSalesNo: result[0].sales_no,
        rcptNo: result[0].RcptNo,
      });
    } else {
      updateLocalState({ billData: {} });
      updateLocalState({ isShowHistory: false });
    }

    let result = queryTrans.filter((data) => !data.is_split);
    if (result) {
      if (Array.isArray(result) && result.length > 0) {
        insertCartItemsDataFromRealm(result[0].transaction_items);
      }

      // 3. standardize data structure & join multiple data by typesales
      let standardData = standardizeData(result);

      // 4. set state
      updateLocalState({
        outlet: outletInfo.outletName,
        table: result[0]?.table_name,
        billData: { ...standardData.data },
        billTaxes: [...standardData.taxes],
        billDiscount: [...standardData.discounts],
        billSurcharge: [...standardData.surcharge],
        billSubTotal: standardData.sub_total,
        billGrandTotal: standardData.grand_total,
        isShowHistory: false,
        isAddress: result[0]?.store_address,
        isSalesNo: result[0]?.sales_no,
        rcptNo: result[0]?.RcptNo,
      });
    }
  };

  const prepareBillData = async () => {
    showLoading(true);
    setShowViewBillPage(false);
    // 1. cek tipe viewbill
    const orderMethod = getOrderMethod();
    const outletInfo = getOutletInfo();

    // 2. query berdasarkan type
    let filter = {};
    if (orderMethod === 'normal_order') {
      // normal order msh menggunakan table_id
      // sicc + normal order menggunakan parameter
      // cek jika tableName ada menggunakan table name + siccMember jika tdk ada pakai TA-sicc member
      filter = {
        table_id: outletInfo.tableId,
        parameter: getSetting().tableName
          ? getTableNames(siccMember, getSetting(), getSetting().tableName)
          : `TA-${siccMember.substring(0, siccMember.length - 2)}`,
        type_bill: orderMethod,
      };
    }

    if (orderMethod === 'waiter_order') {
      let transactionsId = getTransactionsId();
      if (Array.isArray(transactionsId) && transactionsId.length > 0) {
        let lastTransId = transactionsId[transactionsId.length - 1];
        filter = {
          table_id: outletInfo.tableId,
          status: { $nin: ['expired'] }, // status langsung dari realm
          type_bill: orderMethod,
          _id: new Realm.BSON.ObjectId(lastTransId),
        };
      }
    }

    if (orderMethod === 'payment_order') {
      filter['_id'] = new Realm.BSON.ObjectId(transId);
    }

    let queryCheck = null;
    if (['normal_order', 'waiter_order'].indexOf(orderMethod) > -1) {
      if (getSetting().customTable !== 'off') {
        filter['_id'] = new Realm.BSON.ObjectId(transId);
        delete filter['table_id'];
      }
      // Jika sicc member true hapus _id (transId),
      // jika false hapus parameter,
      // Parameter hanya di gunakan untuk sicc
      if (getSetting().useSiccMember) {
        delete filter['_id'];
      } else {
        delete filter['parameter'];
      }
      queryCheck = await getLastTransaction(filter);
    }

    const substract = moment().subtract(3, 'h');
    filter['createdAt'] = { $gt: substract.toDate() };
    if (queryCheck.is_history_exists) {
      if (queryCheck.calculated_id) {
        let queryTrans = null;
        filter['status'] = 'calculated';
        if (['normal_order', 'waiter_order'].indexOf(orderMethod) > -1) {
          filter['_id'] = new Realm.BSON.ObjectId(queryCheck.calculated_id);
          //jika pertama kali buka view bill dalam mode normal order pake fungsi ini
          queryTrans = await getDetailTransaction(filter);
          if (queryTrans) {
            getViewBillDetail(queryTrans, outletInfo);
          }
        } else {
          if (queryTrans) {
            getViewBillDetail(queryTrans, outletInfo);
          }
        }
        showLoading(false);
        setShowViewBillPage(true);
        if (
          queryTrans[0]?.miniapp_request_id &&
          queryTrans[0]?.miniapp_request_id !== null
        ) {
          //munnculkan loadinng
          //cek update jika ada get detail lagi
          updateLocalState({ loadingUpdate: true });
          const checkMiniappReq = await watchMiniAppReq(
            queryTrans[0]?.miniapp_request_id,
          );

          if (checkMiniappReq) {
            queryTrans = await getDetailTransaction(filter);
            if (queryTrans) {
              getViewBillDetail(queryTrans, outletInfo);
            }
          }
          updateLocalState({ loadingUpdate: false });
        }
      } else {
        updateLocalState({ billData: {} });
        updateLocalState({
          isShowHistory:
            getSetting().useSiccMember && !outletInfo.tableId ? false : true,
        });
      }
    } else {
      updateLocalState({ billData: {} });
      updateLocalState({ isShowHistory: false });
    }

    showLoading(false);
    setShowViewBillPage(true);
  };

  const insertCartItemsDataFromRealm = (data) => {
    if (getSetting() && getSetting().orderMethod === 'payment_order') {
      //Fungsi setRealmCartItems tidak dieksekusi ketika keluar dari halaman viewbill
      if (mountedRef.current) {
        //menambahkan value baseQuantitiy yang digunakan untuk menghitung quantitiy ketika kembali dari viewbill
        const newData = data.map((itemData) => {
          const prepItems =
            Array.isArray(itemData.prep_items) &&
            itemData.prep_items.length > 0 &&
            itemData.prep_items.map((prepItem) => {
              if (prepItem.baseQuantity) {
                return { ...prepItem };
              } else {
                return {
                  ...prepItem,
                  baseQuantity: prepItem.quantity / itemData.quantity,
                };
              }
            });

          return { ...itemData, prep_items: prepItems };
        });
        setRealmCartItems(newData);
      }
      clearCart();
    }
  };

  const getNameSalesType = (paramId) => {
    if (paramId) {
      let outletInfo = getOutletInfo();
      let salesTypes = outletInfo.salestype.sales;

      let index = salesTypes.findIndex(
        (x) => x.type_sales_id.toString() === paramId.toString(),
      );

      return salesTypes[index]?.title;
    }
    return null;
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/safari/i)) {
      window.history.replaceState(null, null, history.location.pathname);
    }
    1;
    // MATIKAN TOMBOL BACK
    window.history.pushState(null, null, history.location.pathname);
    window.addEventListener('popstate', () => {
      history.go(1);
    });

    // watchTransaction();
    prepareBillData();

    return function () {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleClickRefresh = () => {
    showLoading(true);
    prepareBillData();
    updateLocalState({ disabledRefresh: true });
    setTimeout(() => {
      updateLocalState({ disabledRefresh: false });
      showLoading(false);
    }, 100);
  };

  return (
    <ReBillLayout
      onBack={URLS.MENU_CATALOGUE}
      title={
        getLocalState().billData &&
        Object.keys(getLocalState().billData).length !== 0
          ? 'VIEW BILL'
          : t('viewBillTitle')?.toUpperCase()
      }
      showViewBill={showViewBill}
      billData={getLocalState().billData}
      table={getLocalState().table}
      salesType={(param) => getNameSalesType(param)}
      outlet={getLocalState().outlet}
      disableRefresh={getLocalState().disabledRefresh}
      onClickRefresh={() => handleClickRefresh()}
      billDiscount={getLocalState().billDiscount}
      billSurcharge={getLocalState().billSurcharge}
      billSubTotal={getLocalState().billSubTotal}
      billGrandTotal={getLocalState().billGrandTotal}
      billTaxes={getLocalState().billTaxes}
      warningMessage={showWarningMessage}
      showHistory={getLocalState().isShowHistory}
      onConfirm={() => onConfirm}
      loadingUpdate={getLocalState().loadingUpdate}
      addressStore={getLocalState().isAddress}
      salesNo={getLocalState().isSalesNo}
      rcptNo={getLocalState().rcptNo}
    />
  );
};

export default ViewBillPage;
