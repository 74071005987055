import React from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { useStoreAppColor } from "@state/useStoreAppColor"

const useStyle = makeStyles({
  container: {
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(2),
  },
});

const ReFooter = ({ textColor }) => {
  const clases = useStyle();
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))
  return (
    <Box className={clases.container}>
      <Typography
        variant="subtitle2"
        sx={{ color: textColor ? textColor : appColor.secondaryFontColor }}>
        Powered by
        <b>{` Raptor Digital Ordering`}</b>
      </Typography>
    </Box>
  );
};

export default ReFooter;
