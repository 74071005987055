const ID_OVO = 'ID_OVO';
const ID_DANA = 'ID_DANA';
const ID_LINKAJA = 'ID_LINKAJA';
const ID_SHOPEEPAY = 'ID_SHOPEEPAY';

export const getUrlCheckout = (data) => {
    const actions = data.actions;
    switch (data.channel_code) {
        case ID_LINKAJA:
            return actions.mobile_web_checkout_url;
        case ID_SHOPEEPAY:
            return actions.mobile_deeplink_checkout_url;
        case ID_DANA:
            return actions.mobile_web_checkout_url;
        default:
    }
};

export const getQtyPrepItem = (prepItem, item, isViewBill) => {
    const qty = isViewBill
        ? (prepItem.baseQuantity || prepItem.quantity) * item?.quantity
        : prepItem?.quantity * item?.quantity;

    return qty || 0;
};