import { useStoreSiccMember } from '@state/useStoreSiccMember';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreLogo } from '@state/useStoreLogo';
import { getDefaultLogo } from '@functions/getImageUrlPath';
import localStorageNames from '@data/localStorageNames';
import { useGeneralContext } from '@context/GeneralContext';
import { useTranslation } from 'react-i18next';

export default function useDrawer() {
    const { getLocalStorage } = useGeneralContext();
    const { t } = useTranslation();
    const { siccMember } = useStoreSiccMember(state => ({
        siccMember: state.siccMember
    }))

    const { settings } = useStoreSettings(state => ({
        settings: state.settings,
    }))

    const { getTableName } = useStoreOutlet(state => ({
        getTableName: state.getTableName,
    }))

    const { logoUrl } = useStoreLogo()


    const tableNames = () => {
        ///jika custom table custom table tidak ada
        let addSicc = siccMember ? "-" + siccMember.substring(0, siccMember.length - 2) : "";
        if (settings.customTable === "custom") {
            if (settings.isQRStoreParameter || settings.customTable === "custom") {
                return ""
            } else {
                if (getTableName()) {
                    return getTableName() + addSicc;
                } else {
                    return `TA-${siccMember.substring(0, siccMember.length - 2)}`;
                }
            }
        } else {
            if (getTableName()) {
                return getTableName() + addSicc;
            } else if (siccMember) {
                return `TA-${siccMember.substring(0, siccMember.length - 2)}`;
            } else {
                return getTableName()
            }
        }
    }

    const getLogoImage = () => {
        if (logoUrl) return logoUrl;
        return getDefaultLogo();
    };

    const getCredentialUser = () => {
        if (siccMember) {
            return `"${siccMember}"`
        } else {
            return getLocalStorage(localStorageNames.USER) === 'guest'
                ? t('guest')
                : getLocalStorage(localStorageNames.USER);
        }

    };

    return [tableNames, getLogoImage, getCredentialUser]
}
