import defaultSplash from '@assets/images/default_splash.png';
import defaultLogo from '@assets/images/default_logo.png';
import { useState } from 'react';
import { saveSplash } from '@data/localStorageSetter';
export default function useSplashImage() {
    const [getSearchParam] = useState(window.location.href.split('?')[1])

    const getSplashScreen = (key) => {
        const splashFromURL = new URLSearchParams(getSearchParam).get(key);
        if (!splashFromURL && key === "splash") {
            saveSplash(defaultSplash);
            return defaultSplash
        }
        if (!splashFromURL && key === "logo") {
            return defaultLogo;
        }

        if (key === "logo") {
            const logoData = decodeURIComponent(splashFromURL);
            return logoData
        } else {
            const splashData = decodeURIComponent(splashFromURL);
            saveSplash(splashData)
            return splashData
        }

    }

    return getSplashScreen
}
