import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames";

export const useStoreSpreadProduct = create(persist((set, get) => ({
    spreadProducts: {},
    kodeSpreadCategory: '010',
    _hasHydratedSpreadCategory: false,
    setHasHydratedSpreadCategory: (state) => {
        set({ _hasHydratedSpreadCategory: state });
    },
    updateSpreadProduct: (newSpreadProducts) => {
        let index = 0
        const flatProducts = newSpreadProducts.flatMap((category) => {
            if (Array.isArray(category.categories) && category.categories.length > 0) {
                return category.categories.map((subCategory) => ({
                    products: subCategory.products.map((subCategoryProduct) => {
                        return {
                            url: subCategoryProduct.url ? subCategoryProduct.url : '',
                            is_variant: subCategoryProduct.is_variant,
                            uuid_product: subCategoryProduct.uuid_product,
                            is_active: subCategoryProduct.is_active,
                            legend: subCategoryProduct.legend,
                            plu_name: subCategoryProduct.plu_name,
                            plu_number: subCategoryProduct.plu_number,
                            sell_bands: subCategoryProduct.sell_bands,
                            index: index++,

                        }
                    }),
                    menu_type: subCategory.menu_type,
                    category_name: subCategory.category_name,
                    uuid_product_category: subCategory.uuid_product_category,
                    isSubCategory: true
                }));
            } else {
                return {
                    menu_type: category.menu_type,
                    products: category.products.map((categoryProduct) => {
                        return {
                            url: categoryProduct.url ? categoryProduct.url : '',
                            is_variant: categoryProduct.is_variant,
                            uuid_product: categoryProduct.uuid_product,
                            is_active: categoryProduct.is_active,
                            legend: categoryProduct.legend,
                            plu_name: categoryProduct.plu_name,
                            sell_bands: categoryProduct.sell_bands,
                            plu_number: categoryProduct.plu_number,
                            index: index++,
                        }
                    }),
                    category_name: category.category_name,
                    uuid_product_category: category.uuid_product_category,
                    isSubCategory: false
                };
            }
        });
        set({ spreadProducts: flatProducts })
    },
    getSpreadCategories: () => {
        return get().spreadProducts
    }
}),
    {
        name: localStorageNames.SPREAD_CATEGORY,
        onRehydrateStorage: () => (state) => {
            state.setHasHydratedSpreadCategory(true)
        }
    }
))