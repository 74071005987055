import React, { useState, useContext, useEffect } from 'react';
import ReAddToCartModal from '@components/ReAddToCartModal';
import ConfirmDialog from '@pages/checkout/ConfirmDialog';

import CheckoutPage from '@pages/checkout/CheckoutPage';
import { useAuthContext } from '@context/AuthContext';
import { useRealmContext } from '@context/RealmContext';
import { useGeneralContext } from '@context/GeneralContext';
import { useHistory } from 'react-router-dom';
import localStorageNames from '@data/localStorageNames';
import { useTranslation } from 'react-i18next';
import QrCodeDialog from '@pages/checkout/QrCodeDialog';
import LoadingPayment from '@pages/checkout/LoadingPayment';
import { getPriceBySellBand } from '@functions/getItemPriceBySellBand';
import URLS from '@/URLS';
import PaymentDialog from '@pages/checkout/PaymentDialog';
import { v4 as uuidv4 } from 'uuid';
import { sendPostMessageToParent } from '@functions/trafficController';
import { getColorApp } from '@data/localStorageGetter';
import * as Realm from 'realm-web';
import {
  useStoreAppColor,
} from "@state/useStoreAppColor"
import { useStoreSiccMember } from '@state/useStoreSiccMember';
import { useStoreViewBill } from '@state/useStoreViewBill';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreCartItems } from '@state/useStoreCartItems';
import { useStoreCustomParameter } from '@state/useStoreCustomParameter';
import { useShallow } from 'zustand/react/shallow'
import {
  getUrlCheckout,
  getQtyPrepItem
} from '@libs/CartContext/helper';
import { getItem } from '@libs/CartItem/getItem';
import WeatherWarning from '@pages/checkout/WeatherWarning';
import { useCheckoutState } from '@state/checkout/useCheckoutState';
import useOnlineStatus from '@hooks/useOnlineStatus';
import CheckingSubtotalModal from '@components/CheckingSubtotalModal';

import { useStoreGenerateKey } from '@state/useStoreGenerateKey';
import useCheckOpenCloseStore from '@hooks/useCheckOpenCloseStore';
// const TOKEN_VALIDATOR =
//   import.meta.env[`REACT_APP_VALIDATION_${import.meta.env.REACT_APP_ENV}`];

export const CartContext = React.createContext([{}, () => { }]);

const initialAddToCartModal = {
  visible: false,
  data: null,
};

const initialCheckoutPage = {
  visible: false,
};

const initialConfirmModal = {
  visible: false,
  titleDialog: '',
  messageDialog: '',
  orderMethod: null,
};

const initialConfirmPayment = {
  visible: false,
  titleDialog: '',
  messageDialog: '',
  data: null,
};

const initialConfirmPaymentAdyen = {
  visible: false,
  data: null
};

const initialQrcodeModal = {
  visible: false,
  data: null,
};

const initialSuggestionPage = {
  visible: false,
};

const initialPaymentResId = {
  trans_id: '',
  miniapp_request_id: '',
  visible: false
};

const initialWeatherModal = {
  visible: false,
  data: null
};

const initialSubtotalWarning = {
  visible: false,
}

const initialState = {
  cartModal: initialAddToCartModal,
  checkoutPage: initialCheckoutPage,
  confirmModal: initialConfirmModal,
  confirmPayment: initialConfirmPayment,
  confirmPaymentAdyen: initialConfirmPaymentAdyen,
  qrcodeModal: initialQrcodeModal,
  loadingPayment: false,
  loadingModal: false,
  suggestionPage: initialSuggestionPage,
  isRewardsPage: false,
  confirmExitRewards: false,
  cart: {
    items: [],
  },
  realmCart: {
    items: [],
  },
  urlXenditPage: null,
  resultCode: "",
  paymentResId: initialPaymentResId,
  weatherWarning: initialWeatherModal,
  serviceCharge: null,
  subtotalWarning: initialSubtotalWarning,
};

const CartProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const history = useHistory();
  const { getSetting } = useStoreSettings(state => ({
    getSetting: state.getSetting
  }))
  const { getOutletInfo } = useStoreOutlet(state => ({
    getOutletInfo: state.getOutletInfo
  }))
  const {
    hitAPI,
    getLocalStorage,
    getTableName,
    getUserPhone,
  } = useAuthContext();
  const { openErrorSnackBar, getSellBandId, showLoading, showLostConnectionModal } = useGeneralContext();
  const { t } = useTranslation();

  const {
    hitRealmTransaction,
    sendClientPayment,
    isLastTransactionExist,
    checkMiniAppConnection,
    watchMiniAppReq,
    getServiceCharge,
  } = useRealmContext();

  const [isOnline, checkOnlineStatus] = useOnlineStatus()
  const [ ,checkOpenCLose ]= useCheckOpenCloseStore()
  const { orderCategory, getCurrentSalesType } = useStoreOrderCategory(state => ({ orderCategory: state.orderCategory, getCurrentSalesType: state.getCurrentSalesType }))

  const { isLoggedIn, checkTokenIsExpired } = useStoreFirstCredential(state => ({
    checkTokenIsExpired: state.checkTokenIsExpired,
    isLoggedIn: state.isLoggedIn
  }))
  const { credentials } = useStoreFirstCredential(state => ({
    credentials: state.credentials
  }))
  const { viewBillId: transId, updateViewBillId } = useStoreViewBill(useShallow(state => ({
    viewBillId: state.viewBillId,
    updateViewBillId: state.updateViewBillId
  })))

  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))
  const { siccMember } = useStoreSiccMember(state => ({
    siccMember: state.siccMember
  }))

  const { updateCustomParameter } = useStoreCustomParameter(state => ({
    updateCustomParameter: state.updateCustomParameter
  }))

  const { userUuid, generateUniqueKey } = useStoreGenerateKey(state => ({
    userUuid: state.userUuid,
    generateUniqueKey: state.generateUniqueKey
  }))

  const {
    cartItems,
    getCartItems,
    setCartItems,
    setCartItemLocalStorage,
    clearCart,
    getBadgeCount,
    totalPrice,
    changeCartItemQuantity,
    isChooseRewards,
    removeItemInCart
  } = useStoreCartItems(state => ({
    cartItems: state.cartItems,
    getCartItems: state.getCartItems,
    setCartItems: state.setCartItems,
    setCartItemLocalStorage: state.setCartItemLocalStorage,
    clearCart: state.clearCart,
    getBadgeCount: state.getBadgeCount,
    totalPrice: state.totalPrice,
    changeCartItemQuantity: state.changeCartItemQuantity,
    isChooseRewards: state.isChooseRewards,
    removeItemInCart: state.removeItemInCart

  }))

  const {
    isViewBill,
    updateIsViewBill,
    removeIsViewBill,
    removeViewBillId
  } = useStoreViewBill(state => ({
    isViewBill: state.isViewBill,
    updateIsViewBill: state.updateIsViewBill,
    removeIsViewBill: state.removeIsViewBill,
    removeViewBillId: state.removeViewBillId
  }))

  const { selectTime: inputSelectTime, setExpandModifier, removeParameter } = useCheckoutState(state => ({
    selectTime: state.selectTime,
    setExpandModifier: state.setExpandModifier,
    removeParameter: state.removeParameter
  }))

  const forceReset = () => {
    setState(initialState);
  };

  const setContextState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getContextState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const setConfirmModal = (value, orderMethod, parameter) => {
    setContextState({
      confirmModal: {
        visible: value,
        orderMethod: orderMethod,
        parameter: parameter,
      },
    });
  };

  const setConfirmPayment = (value, titleDialog, messageDialog, data) => {
    setContextState({
      confirmPayment: {
        visible: value,
        titleDialog: titleDialog,
        messageDialog: messageDialog,
        data: data,
      },
    });
  };

  const setConfirmPaymentAdyen = (value, data) => {
    if (data && !data.error) {
      data.color = appColor;
      data.result['env'] = "TEST";
      data.result['key'] = import.meta.env[`VITE_REACT_APP_ADYEN_CLIENT_KEY_${import.meta.env.MODE}`];
      if (import.meta.env.MODE === 'PRODUCTION' || import.meta.env.MODE === 'PRODUCTION_B') {
        data.result['env'] = "LIVE"
      }
    }
    setContextState({
      confirmPaymentAdyen: {
        visible: value,
        data: data
      },
    });
  };

  // const handleCloseConfirmPaymentAdyen = () => {
  //   setConfirmPaymentAdyen(false);
  //   window.parent.postMessage(false, import.meta.env[`VITE_REACT_APP_DOMAIN_CONTAINER_${import.meta.env.MODE}`]);
  // };

  // const updateResultCode = (value) => {
  //   setContextState({ resultCode: value });
  // };

  const getResultCode = () => {
    return getContextState('resultCode');
  };

  const getXenditPage = () => {
    const urlXendit = getContextState('urlXenditPage');
    return urlXendit;
  };

  const setCartModal = (value, data) => {
    setContextState({
      cartModal: {
        visible: value,
        data: data || null,
      },
    });
  };

  const setQrcodeModal = (value, data) => {
    setContextState({
      qrcodeModal: {
        visible: value,
        data: data || null,
      },
    });
  };

  const getCartModalData = () => {
    return { ...getContextState('cartModal').data };
  };


  const getCartModalVisibility = () => {
    return getContextState('cartModal').visible;
  };

  const setSuggestionPage = (value) => {
    setContextState({
      suggestionPage: {
        visible: value,
      },
    });
  };

  const getSuggestionPage = () => {
    return getContextState('suggestionPage').visible;
  };

  const setCheckoutPage = (value) => {
    setContextState({
      checkoutPage: {
        visible: value,
      },
    });
  };


  const insertProductDetail = (product) => {
    let productDetail = JSON.parse(
      getLocalStorage(`product.${product.uuid_product}`),
    );
    product.modifier_group = productDetail.modifier_group;
    product.prep_group = productDetail.prep_group;
    product.sub_product = productDetail.sub_product;
    product.variant_item = productDetail.variant_item;
    return product;
  };

  const addToCart = (itemProducts, isUsingMock) => {
    generateUniqueKey()
    const item = { ...itemProducts, isParentGroup: true }

    let item_plu_name = item.plu_name
    let localPrepGroup = [];
    let localItem = [];
    delete item.prep_group;
    if (item.promoFrom) {
      localItem.push(...getCartItems());
      const indexOf = getCartItems().findIndex(x => x.timestamp === item.promoFrom) + 1;
      localItem.splice(indexOf, 0, item);
    } else {
      localItem.push(...getCartItems());
      localItem.splice(getCartItems().length, 0, item);
    }

    if (item.is_variant) {
      const variantChecked = item.variant_item.find((data) => data.checked);
      if (variantChecked) {
        if (variantChecked.prep_group && variantChecked.prep_group.length > 0) {
          localPrepGroup = [...variantChecked.prep_group];
        }
      }
    } else {
      localPrepGroup = [...item.group];
    }

    // CEK DAN FILTER PREP GROUP YANG TERISI SAJA
    if (localPrepGroup && localPrepGroup.length > 0) {
      localPrepGroup = localPrepGroup
        .map((group) => {
          if (group.prep_item) {
            let localPrepItem = [...group.prep_item.filter((x) => x.checked)];
            group.prep_item = localPrepItem;
            if (group.prep_item.length > 0) {
              return group;
            }
            return null;
          } else {
            let localPrepItem = [...group.item.filter((x) => x.checked && !x.is_product)];
            group.item.map((x, index) => {
              if (x.checked && x.is_product) {
                x.childGroup = item_plu_name
                x.signature_token = uuidv4();
                x.timestamp = new Date().getTime() + index + (Math.random() * 10);
                x.quantity = itemProducts.quantity;
                localItem.push(x);
              }
            })
            group.item = localPrepItem;
            if (group.item.length > 0) {
              return group;
            }
            return null;
          }
        })
        .filter((y) => y);

    }

    if (isUsingMock) {
      // HANYA KETIKA MENGGUNAKAN TOMBOL MOCK DI DEV PANEL
      console.log("dev panel-----")
      setCartItems([...getCartItems(), item]);
    } else {
      // setCartItems([...getCartItems(), item]);
      setCartItems(localItem);
    }

    if (item.is_have_promo) {
      let data = {
        uuid: item.uuid_product,
        stamp: item.timestamp,
        count: item.quantity,
        suggestion: item.is_have_suggestion,
      };
      setContextState({ isRewardsPage: true });
      sendPostMessageToParent('path', URLS.REWARDS);
      history.replace(URLS.REWARDS, data);
    } else if (item.is_have_suggestion) {
      const dataSuggestion = item.uuid_product;
      setSuggestionPage(true);
      sendPostMessageToParent('path', URLS.SUGGESTION);
      history.replace(URLS.SUGGESTION, dataSuggestion);
    }
  };

  // const generateUniqueKey = () => {
  //   const randomPart = Math.random().toString(36).substring(2, 10);
  //   const timestampPart = Date.now().toString(36);
  //   return randomPart + timestampPart;
  // }

  const sendTransaction = async (orderMethod, parameter) => {
    if (orderMethod === 'payment_order') showLoading(true);

    let credential = credentials || {};
    let typesales = orderCategory || {};
    let cartItem = [...getCartItems()];
    let data = {};
    let tokenValidation = {};

    // const uniqueKey = generateUniqueKey();

    //! Block IF 1
    if (Array.isArray(cartItem) && cartItem.length > 0) {
      data = {
        uuid_table: credential.uuid || credential.table,
        parameter: parameter,
        uuid_sales_type: typesales.id,
        order_items: cartItem.map((item) => {
          //TODO:menyusun prep lagi dari service charge
          let prepGroup = [];
          let productId = '';
          let productName = '';
          //cek jika type variant
          if (item.is_variant) {
            const variantChecked = item.variant_item.find(
              (data) => data.checked,
            );
            productId = variantChecked.uuid_product;
            productName = variantChecked.plu_name;
            if (variantChecked) {
              if (
                variantChecked.prep_group &&
                variantChecked.prep_group.length > 0
              ) {
                prepGroup = [...variantChecked.prep_group];
              }
            }
          } else {
            prepGroup = item.group;
            productId = item.uuid_product;
            productName = item.plu_name;
          }

          // Spread semua prep group kedalam 1 array of prep item
          let selectedPrepItem = [];
          if (prepGroup.length > 0) {
            // SPREAD PREP GROUP NYA
            prepGroup.map((group) => {
              let choiceGroup = group.item ? group.item : group.prep_item;
              selectedPrepItem = [...selectedPrepItem, ...choiceGroup];
              // menghindari error eslint (array.map harus return sesuatu)
              return null;
            });

            // MENGHILANGKAN KEY YANG TIDAK BERGUNA & MANIPULASI QTY
            selectedPrepItem = selectedPrepItem.map((prepItem) => {
              let localPrepItem = { ...prepItem, user_uuid: userUuid };
              delete localPrepItem.checked;
              delete localPrepItem.use_quantity;
              if (orderMethod !== 'waiter_order') {
                delete localPrepItem.price;
              } else {
                localPrepItem['price'] = getPriceBySellBand(
                  localPrepItem,
                  getSellBandId(),
                );
              }
              delete localPrepItem.sell_bands;

              localPrepItem.quantity =
                localPrepItem.quantity !== null && localPrepItem.quantity > 0
                  ? localPrepItem.quantity * item.quantity
                  : 1;
              localPrepItem.product_name = localPrepItem.plu_name;
              return localPrepItem;
            });
          }

          return {
            uuid_product: productId,
            plu_name: item.plu_name,
            product_name: productName,
            quantity: item.quantity,
            note: item.additional_request || [],
            prep_items: selectedPrepItem,
          };
        }),
      };

      // CHECK PRODUCT AND TOKEN
      // tokenValidation = await hitAPI(TOKEN_VALIDATOR, 'POST', credential, data);
      tokenValidation = {
        error: false,
        result: {
          parameter: parameter
        }
      }
    } else {
      // untuk payment order, jika tidak ada data baru (item product yang dipilih user) maka tidak dilakukan validasi
      // jadi tokenValidation diberi nilai
      tokenValidation.error = false;
    }
    //! Akhir dari Block if 1

    //! Block If 2
    if (tokenValidation) {
      if (tokenValidation.error === false) {
        let typesales = orderCategory || {};
        let outletInfo = getOutletInfo();
        let cartItem = [...getCartItems()];

        let data = {
          table_id: outletInfo.tableId,
          table_name: getSetting().customTable === "custom" ? tokenValidation.result.parameter : getTableName(),
          sales_type: orderCategory.type_sales_id.toString(),
          sales_name: orderCategory.title.toString(),
          sell_band: typesales.sell_band.toString(),
          no_telp: getUserPhone() || '',
          type_bill: orderMethod,
          order_items: cartItem.map((item) => {
            let prepGroup = [];
            let pluNumber = '';
            let productName = '';
            let _id = item._id;

            if (item.is_variant) {
              const variantChecked = item.variant_item.find(
                (data) => data.checked,
              );
              pluNumber = variantChecked.plu_number;
              productName = variantChecked.plu_name;
              if (variantChecked) {
                if (
                  variantChecked.prep_group &&
                  variantChecked.prep_group.length > 0
                ) {
                  prepGroup = [...variantChecked.prep_group];
                }
              }
            } else {
              prepGroup = item.group;
              pluNumber = item.plu_number;
              productName = item.plu_name;
            }

            // Spread semua prep group kedalam 1 array of prep item
            let selectedPrepItem = [];
            if (prepGroup.length > 0) {
              // SPREAD PREP GROUP NYA
              prepGroup.map((group) => {
                if (group._id && group._id !== null && group._id !== '') {
                  selectedPrepItem = [...selectedPrepItem, ...group.item];
                } else {
                  selectedPrepItem = [...selectedPrepItem, ...group.prep_item];
                }
                // menghindari error eslint (array.map harus return sesuatu)
                return null;
              });
              // MENGHILANGKAN KEY YANG TIDAK BERGUNA & MANIPULASI QTY
              selectedPrepItem = selectedPrepItem.map((prepItem) => {
                const amount = getPriceBySellBand(prepItem, getSellBandId());
                let localPrepItem = { ...prepItem, user_uuid: userUuid };

                delete localPrepItem.checked;
                delete localPrepItem.use_quantity;
                delete localPrepItem.is_active;
                delete localPrepItem.uuid_product;
                delete localPrepItem._id;
                delete localPrepItem._partition;
                if (orderMethod !== 'waiter_order') {
                  delete localPrepItem.price;
                  delete localPrepItem.sell_bands;
                } else {
                  delete localPrepItem.amount;
                  localPrepItem['price'] = getPriceBySellBand(
                    localPrepItem,
                    getSellBandId(),
                  );

                  delete localPrepItem.sell_bands;
                }

                localPrepItem.quantity =
                  localPrepItem.quantity !== null && localPrepItem.quantity > 0
                    ? getQtyPrepItem(prepItem, item, isViewBill)
                    : 1;

                localPrepItem['amount'] = amount;
                return localPrepItem;
              });
            }

            if (getContextState('serviceCharge')) {
              const prepService = getContextState('serviceCharge');
              let selectedPrepService
              if (item.is_variant) {
                const itemVariant = getItem(item, "name")
                selectedPrepService = prepService.find(service => itemVariant.uuid_product === service._id)
              } else {
                selectedPrepService = prepService.find(service => item.uuid_product === service._id)
              }
              if (selectedPrepService) {
                let dataPrepService = {
                  "uuid_sales_type": selectedPrepService.uuid_sales_type,
                  "amount": selectedPrepService.amount * item.quantity,
                  "plu_name": selectedPrepService.plu_name,
                  "plu_number": selectedPrepService.plu_number,
                  "title": selectedPrepService.title,
                  "quantity": selectedPrepService.quantity * item.quantity,
                  "sell_bands": selectedPrepService.sell_bands,
                  "user_uuid": userUuid
                }
                selectedPrepItem.push(dataPrepService);
              }

            }

            let type = '';
            let subProduct = [];

            if (item.is_package || item.type === 'package') {
              type = 'package';
              subProduct = item.sub_product;
            } else if (item.is_variant) {
              type = 'variant';
            } else {
              type = 'product';
            }

            if (orderMethod === 'payment_order') {
              item.signature_token = uuidv4();
            }

            let selectTime = [];
            let requestWithSelectTime = []
            if (!outletInfo.tableId && orderMethod !== 'quick_order' && siccMember && item.isParentGroup) {
              if (Array.isArray(item.additional_request) && inputSelectTime !== "") {
                requestWithSelectTime.push(...item.additional_request, 'TakeAway ' + inputSelectTime)
              }
              else {
                selectTime.push('TakeAway ' + inputSelectTime)
              }
            } else {
              requestWithSelectTime = item.additional_request
            }

            const newData = {
              _id: _id,
              plu_number: pluNumber,
              plu_name: productName,
              quantity: item.quantity,
              note: requestWithSelectTime || selectTime,
              prep_items: selectedPrepItem || [],
              type,
              sub_product: subProduct,
              taxes: [],
              signature_token: item.signature_token,
              user_uuid: userUuid
            };

            if (orderMethod === 'waiter_order') {
              const amount = getPriceBySellBand(item, getSellBandId());
              newData['amount'] = amount;
              newData['sales_name'] = orderCategory.title.toString();
            }

            return newData;
          }),
        };

        //custom table rdo atau raptor gak perlu kirim table name dan table id
        if (getSetting().customTable === "rdo" || getSetting().customTable === "raptor") {
          delete data.table_id;
          delete data.table_name;
        }

        if (getSetting().customTable === "custom") {
          delete data.table_id;
        }
        //dipotong 00 dua digit di belakang

        if (siccMember) {
          data.sicc_member = siccMember;

          if (getSetting().customTable === "rdo" || getSetting().customTable === "raptor") {
            data.parameter = siccMember.substring(0, siccMember.length - 2);
          } else {
            if (!outletInfo.tableId) {
              data.table_name = "TA";
              delete data.table_id;
            }
            data.parameter = data.table_name + "-" + siccMember.substring(0, siccMember.length - 2);
            data.table_name = data.table_name + "-" + siccMember.substring(0, siccMember.length - 2);
          }
        }

        // 2. SETELAH DICEK, KIRIM TRANSAKSI SESUAI DENGAN DETAIL DITAHAP 1
        if (orderMethod === 'normal_order' || orderMethod === 'waiter_order') {
          delete data._id; // change untuk mengubah setiap transaksi == transaksi baru

          const isMiniAppCheck = getSetting().miniAppCheck;
          // console.log(isMiniAppCheck, 'isMiniAppCheck')
          if (isMiniAppCheck) {
            const miniappAvailable = await checkMiniAppConnection(
              tokenValidation.result.parameter,
              'confirm',
            );

            if (!miniappAvailable) {
              const response = { status: 'error' };
              return JSON.stringify(response);
            }
          }

          let result = await hitRealmTransaction(data);

          if (result?.errorMessage) {
            //cari transaksi apakah sudah masuk atau belum. kalo sudah masuk seperti normal,
            //kalo transaksi tidak masuk, munculkan error
            // const transId = localStorage.getItem(localStorageNames.VIEW_BILL_ID);
            let param = {
              table_id: outletInfo.tableId,
              type_bill: orderMethod,
            }

            if (getSetting().customTable !== "off" && transId) {
              param['_id'] = new Realm.BSON.ObjectId(transId);
              delete param['table_id'];
            }

            handleCloseConfirmModal();

            const lastTransaction = await isLastTransactionExist(param);
            if (lastTransaction) {
              if (result?.errorStatus !== "parameter") clearCart();
              const response = { status: 'success' };
              return JSON.stringify(response);
            } else {
              openErrorSnackBar(t('transactionFailed'));
              const response = { status: 'error' };
              return JSON.stringify(response);
            }
          }

          if (result) {
            updateViewBillId(result.trans_id)
            localStorage.setItem(localStorageNames.VIEW_BILL_ID, result.trans_id);
            clearCart();
            const response = { status: 'success' };
            return JSON.stringify(response);
          } else {
            sendPostMessageToParent('path', URLS.INVALID_TOKEN);
            history.replace(URLS.INVALID_TOKEN);
            const response = { status: 'error' };
            return JSON.stringify(response);
          }
        }
        if (orderMethod === 'payment_order') {
          //is_view_bill set true sebagai tanda jika sudah masuk ke viewbill
          //digunakan untuk handle qty prepitem setelah balik dari viewbill
          /// ini viewbill
          //tidak perlu return karna hit nya beda tempat

          delete data._id; // change untuk mengubah setiap transaksi == transaksi baru
          updateIsViewBill(true)

          // localStorage.setItem(localStorageNames.IS_VIEW_BILL, true);
          let result = await hitRealmTransaction(data);
          if (result) {
            const checkMiniappReq = await watchMiniAppReq(result.miniapp_request_id);
            if (checkMiniappReq) {
              //TODO: fix to state
              localStorage.setItem(localStorageNames.MINIAPP_REQ_ID, result.miniapp_request_id);
              setExpandModifier(true)
              setContextState({
                paymentResId: {
                  trans_id: result.trans_id,
                  miniapp_request_id: result.miniapp_request_id,
                  visible: true,
                },
              });
              removeParameter()
              showLoading(false);
            } else {
              ///show pesan
              showLoading(false);
              openErrorSnackBar(t('failedTax'));
            }
          } else {
            showLoading(false);
            sendPostMessageToParent('path', URLS.INVALID_TOKEN);
            history.replace(URLS.INVALID_TOKEN);
          }
        }
        if (orderMethod === 'quick_order') {
          const isMiniAppCheck = getSetting().miniAppCheck;
          if (isMiniAppCheck) {
            const miniappAvailable = await checkMiniAppConnection(
              tokenValidation.result.parameter,
              'confirm',
            );
            if (!miniappAvailable) {
              const response = { status: 'error' };
              return JSON.stringify(response);
            }
          }
          data = { ...data, parameter: tokenValidation.result.parameter };
          let result = await hitRealmTransaction(data);

          if (result?.errorMessage) {
            openErrorSnackBar(t('transactionFailed'));
            const response = { status: 'error' };
            return JSON.stringify(response);
          }

          if (result) {
            clearCart();
            const response = {
              status: 'success',
              parameter: tokenValidation.result.parameter,
            };
            return JSON.stringify(response);
          } else {
            sendPostMessageToParent('path', URLS.INVALID_TOKEN);
            history.replace(URLS.INVALID_TOKEN);
            const response = { status: 'error' };
            return JSON.stringify(response);
          }
        }
      } else {
        if (tokenValidation.status === 401) {
          sendPostMessageToParent('path', URLS.INVALID_TOKEN);
          history.replace(URLS.INVALID_TOKEN);
        } else {
          if (tokenValidation.messages && tokenValidation.messages.length > 0) {
            // JIKA DATA NOT FOUND, MAKA KELUARKAN POP UP DAN FETCH PRODUK TERBARU
            if (tokenValidation.messages[0].includes('data not found')) {
              let productName = tokenValidation.messages[0].split('"');
              openErrorSnackBar(
                t('itemNotFound', {
                  product: productName[1],
                }),
              );
              realmfetch.fetchSplashConfig()
            } else {
              let message;
              tokenValidation.messages.forEach((data) => {
                message = data + ' \n';
              });

              if (message.trim() !== 'Failed to fetch') {
                openErrorSnackBar(message);
              }
            }
          } else {
            openErrorSnackBar('Error something');
          }
        }
        if (orderMethod === 'payment_order') showLoading(false);
        const response = { status: 'error' };
        return JSON.stringify(response);
      }
    } else {
      if (orderMethod === 'payment_order') showLoading(false);
      openErrorSnackBar('Error something');
      const response = { status: 'error' };
      return JSON.stringify(response);
    }
    //! Akhir Block if Ke 2

    // TOKEN MASIH VALID
  };



  const createChargeEWallet = async () => {
    const SEND_CHARGE_EWALLET =
      import.meta.env[
      `REACT_APP_CREATE_EWALLET_CHARGES_${import.meta.env.MODE}`
      ];
    handleCloseConfirmPayment();
    setContextState({ loadingPayment: true });

    const response = await hitAPI(
      SEND_CHARGE_EWALLET,
      'POST',
      credentials,
      getContextState('confirmPayment').data,
    );

    if (response !== undefined && response !== null) {
      if (response.status === 200) {
        const data = response.result.data;
        const chargeAmount = parseFloat(data.charge_amount).toFixed(0);
        const captureAmount = parseFloat(data.capture_amount).toFixed(0);
        const subFunctionId = response.result.SubFunctionID;
        const newDataPayment = {
          ...response.result.data,
          charge_amount: chargeAmount,
          capture_amount: captureAmount,
        };

        handleCloseCheckoutPage();
        clearOrderCart();

        const result = await sendClientPayment(
          newDataPayment,
          transId,
          subFunctionId,
        );
        if (result) {
          //Selain pembayaran menggunakan ovo akan menerima url untuk checkout pembayaran
          //jika pembayaran menggunakan OVO akan mendapatkan notif di aplikasi

          removeViewBillId()
          removeIsViewBill()
          // localStorage.removeItem(localStorageNames.IS_VIEW_BILL);
          // localStorage.removeItem(localStorageNames.VIEW_BILL_ID);

          if (data.channel_code !== ID_OVO) {
            await window.open(getUrlCheckout(data), '_self');
          }
          setTimeout(() => {
            setContextState({ loadingPayment: false });
            sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
            history.replace(URLS.MENU_CATALOGUE);
          }, 1500);
        }
        return JSON.stringify({ status: 'success' });
      } else {
        setContextState({ loadingPayment: false });

        let messages = '';
        if (response.messages && Array.isArray(response.messages)) {
          response.messages.map((data) => {
            messages += data + '\n';
            return null;
          });
        } else {
          messages = response.messages;
        }
        openErrorSnackBar(messages);
        return JSON.stringify({ status: 'error' });
      }
    } else {
      sendPostMessageToParent('path', URLS.SESSION_EXPIRED);
      history.replace(URLS.SESSION_EXPIRED);
    }
  };

  const clearOrderCart = () => {
    removeIsViewBill()
    // localStorage.removeItem(localStorageNames.IS_VIEW_BILL);
    clearCart();
    setContextState({
      paymentResId: {
        visible: false,
      },
    });
  };

  const handleOpenCheckoutPage = async () => {
    const store = checkOpenCLose(getCurrentSalesType().currentOpen);
    if(store) {
      if (await checkOnlineStatus()) {
        if (getContextState('isRewardsPage')) {
          if (isChooseRewards()) {
            setContextState({ isRewardsPage: false });
            setCheckoutPage(true);
  
          } else {
            setContextState({ confirmExitRewards: true });
          }
        } else if (getSuggestionPage()) {
          setSuggestionPage(false);
          setCheckoutPage(true);
  
        } else {
          setCheckoutPage(true);
  
        }
      } else {
        showLostConnectionModal(true)
      }
    } else {
      history.replace(URLS.OUTLET_CLOSE);
    }
  };

  const handleCloseCheckoutPage = () => {
    setCheckoutPage(false);
    setContextState({
      paymentResId: {
        visible: false,
      },
    });
  };

  const handleOpenConfirmModal = (orderMethod, parameter) => {
    if (checkTokenIsExpired()) {
      sendPostMessageToParent('path', URLS.SESSION_EXPIRED);
      history.replace(URLS.SESSION_EXPIRED);
    } else {
      if (parameter) {
        updateCustomParameter(parameter)
        // localStorage.setItem(localStorageNames.CUSTOM_PARAMETER, parameter);
      }
      setConfirmModal(true, orderMethod, parameter);
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false, null, null);
  };

  const handleCloseCartModal = (param) => {
    if (!getSetting().isDirectOrder && !getCartModalData().isSuggestionPage) {
      const data = getContextState('cartModal').data;
      const suggestion = data.is_have_suggestion;
      if (suggestion && param !== 'close') {
        setSuggestionPage(true);
      }
    }
    setCartModal(false, null);
  };

  const handleCloseQrcodeModal = () => {
    setQrcodeModal(false, null);
  };

  const handleCloseConfirmPayment = () => {
    setConfirmPayment(false);
  };

  // const handleModalWeather = (orderMethod, parameter) => {

  //   setContextState({
  //     weatherWarning: {
  //       visible: true,
  //     },
  //     confirmModal: {
  //       orderMethod: orderMethod,
  //       parameter: parameter,
  //     },
  //   });
  // };

  const clickModalWeather = (data) => {
    setContextState({
      weatherWarning: {
        visible: false,
      },
    });
    if (data) {
      if (getContextState('confirmModal').orderMethod !== 'payment_order') {
        handleOpenConfirmModal(getContextState('confirmModal').orderMethod, getContextState('confirmModal').parameter)
      } else if (getContextState('confirmModal').orderMethod === 'payment_order') {
        sendTransaction(getContextState('confirmModal').orderMethod, getContextState('confirmModal').parameter)
      }
    } else {
      // ke page lain
      console.log('ke page lainn-------');
    }
  }

  const prepServiceCharge = async () => {

    const cartItems = getCartItems();
    const uuidCartItems = cartItems.map(item => {
      if (item.is_variant) {
        const itemVariant = getItem(item, "name")
        return itemVariant.uuid_product
      } else {
        return item.uuid_product
      }
    });

    const param = {
      uuid_sales_type: getCurrentSalesType().type_sales_uuid,
      uuid_products: uuidCartItems
    }

    const serviceCharge = await getServiceCharge(param);
    if (!serviceCharge.error) {
      serviceCharge.result.service_charge.checked = true;
      setContextState({
        serviceCharge: serviceCharge.result.service_charge,
      })
      return serviceCharge.result.service_charge;
    }
    return serviceCharge;
  };

  useEffect(() => {
    forceReset();
    // CEK LOCALSTORAGE KALAU ADA VALUE CART NYA, MAKA MASUKKAN KEDALAM STATE
    let LSCart = getCartItems();
    if (isLoggedIn() && LSCart && LSCart.length > 0) {
      setCartItems(LSCart);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getCartItems().length === 0) {
      handleCloseCheckoutPage();
    }
    // KETIKA ADA PERUBAHAN DI STATE, UPDATE JUGA LOCALSTORAGENYA
    setCartItemLocalStorage(getCartItems());
    // eslint-disable-next-line
  }, [cartItems]);

  return (
    <CartContext.Provider
      value={{
        getContextState,
        setContextState,
        setCartModal,
        setQrcodeModal,
        getCartModalVisibility,
        addToCart,
        setCheckoutPage,
        changeCartItemQuantity,
        clearCart,
        removeItemInCart,
        forceReset,
        handleOpenCheckoutPage,
        createChargeEWallet,
        setConfirmPayment,
        getSuggestionPage,
        setSuggestionPage,
        clearOrderCart,
        getXenditPage,
        insertProductDetail,
        getBadgeCount,
        isLoggedIn,
        totalPrice,
        getCartItems,
        setConfirmPaymentAdyen,
        setCartItems,
        getResultCode,
        prepServiceCharge
      }}>
      {children}

      {getCartModalVisibility() && (
        <ReAddToCartModal
          item={getCartModalData()}
          closeModal={(param) => handleCloseCartModal(param)}
          isSuggestion={getSuggestionPage()}
          dataSuggestion={getContextState('cartModal').data.uuid_product}
          isRewardsPage={getContextState('isRewardsPage')}
        />
      )}

      {getContextState('qrcodeModal').visible && (
        <QrCodeDialog closeModal={() => handleCloseQrcodeModal()} />
      )}

      {getContextState('checkoutPage').visible && (
        <CheckoutPage
          onViewBill={(orderMethod, parameter) => sendTransaction(orderMethod, parameter)}
        />
      )}

      {getContextState('confirmModal').visible && (
        <ConfirmDialog
          closeQRcode={() => forceReset()}
          // resultCode={getResultCode()}
          confirmTransaction={async () => {
            return await sendTransaction(
              getContextState('confirmModal').orderMethod,
              getContextState('confirmModal').parameter,
            );
          }}
        />
      )}

      {getContextState('confirmPayment').visible && (
        // confirm dialog pembayaran Xendit
        <ConfirmDialog
          // closeModal={() => handleCloseConfirmPayment()}
          confirmTransaction={async () => {
            return await createChargeEWallet();
          }}
        />
      )}
      {getContextState('loadingPayment') && <LoadingPayment />}
      {getContextState('confirmPaymentAdyen').visible && (
        <PaymentDialog />
      )}
      {getContextState('weatherWarning').visible &&
        <WeatherWarning actionClick={(value) => clickModalWeather(value)} theme={getColorApp()} />
      }
      {
        getContextState('subtotalWarning').visible &&
        <CheckingSubtotalModal />
      }
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const value = useContext(CartContext);
  if (value == null) {
    throw new Error('useCartContext() called outside of a Provider?');
  }
  return value;
};

export default CartProvider;
