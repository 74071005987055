import React, { useEffect, useState } from 'react';
import ReGridviewPanelTab from '@components/ReGridviewPanelTab';
import ReListViewPanelTab from '@components/ReListViewPanelTab';
import { useStoreProduct } from '@state/useStoreProduct';
import { useAuthContext } from '@context/AuthContext';
import { useStoreCategory } from '@state/useStoreCategory';

const ItemProductTab = () => {
  const { flatProducts } = useStoreProduct(state => ({
    flatProducts: state.flatProducts
  }))
  const [selectedProduct, setSelectedProduct] = useState()

  const { getAuthState } = useAuthContext()
  const {activeSection} = useStoreCategory(state => ({
    activeSection: state.activeSection
  }))


  useEffect(() => {
    const tabIndex = activeSection;
    setSelectedProduct(flatProducts[tabIndex])
  }, [activeSection])


  return (
    <>
      {selectedProduct && (
        selectedProduct.menu_type === 'grid' ? (
          <ReGridviewPanelTab category={selectedProduct} />
        ) : (
          <ReListViewPanelTab category={selectedProduct} />
        )
      )}
    </>
  );
};

export default ItemProductTab;
