import { create } from "zustand"
export const useStoreAppData = create((set) => ({
    appData: {},
    updateAppData: (newAppData) => set({ appData: newAppData }),

}))















