import { useState, useEffect } from 'react';

const useScrollDetection = (ref) => {
    const [isScrolling, setIsScrolling] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);

    useEffect(() => {
        const scrollElement = ref.current;
        let scrollTimeout;

        const handleScroll = () => {
            setIsScrolling(true);
            setLastScrollTop(scrollElement.scrollTop);

            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                setIsScrolling(false);
            }, 150);
        };

        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollElement) {
                scrollElement.removeEventListener('scroll', handleScroll);
            }
            clearTimeout(scrollTimeout);
        };
    }, [ref]);

    return { isScrolling, lastScrollTop };
};

export default useScrollDetection;