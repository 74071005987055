import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames";

export const useStoreOrderCategory = create(persist((set, get) => ({
    orderCategory: {},
    kodeOrderCategory: '007',
    _hasHydratedOrderCategory: false,
    setHasHydratedOrderCategory: (state) => {
        set({  _hasHydratedOrderCategory: state  });
    },
    updateOrderCategory: (newData) => {
        set({ orderCategory: newData })
    },
    removeOrderCategory: () => {
        set({ orderCategory: {} })
    },
    getCurrentSalesType: () => {
        return get().orderCategory
    },
    getSellBandId: () => {
        let sellBandId = get().orderCategory;
        return sellBandId ? sellBandId.sell_band : 0;
    },
    getTypeSalesName: () => {
        let typesales = get().orderCategory;
        return typesales ? typesales.title : '';
    }
}),
    {
        name: localStorageNames.ORDERCATEGORY,
        onRehydrateStorage: () => (state) => {
            state.setHasHydratedOrderCategory(true)
        }
    }
)
)