import React from 'react';
import ReDialog from './ReDialog';
import {
  Box,
  ButtonBase,
  Typography,
  useTheme,
} from '@mui/material';
import {
  makeStyles
} from "@mui/styles"
import { ClearRounded } from '@mui/icons-material';
import { QrScanner } from '@yudiel/react-qr-scanner';

const useStyles = makeStyles((theme) => ({
  buttonBase: {
    position: 'absolute',
    left: theme.spacing(3),
  },
}));

const ReQrReader = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const onCloseModalDoalog = () => {
    props.onCloseModal();
  };

  const onSuccessScan = (result) => {
    props.onSuccessScan(result);
    onCloseModalDoalog();
  };

  const onErrorScan = (messages) => {
    props.onErrorScan(messages);
    onCloseModalDoalog();
  };

  return (
    <ReDialog
      fullScreen={false}
      floatingTitle={true}
      closeModal={() => { }}
      title={
        <Box>
          <ButtonBase
            className={classes.buttonBase}
            onClick={() => onCloseModalDoalog()}>
            <ClearRounded style={{ width: 32, height: 32 }} />
          </ButtonBase>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            QR CODE
          </Typography>
        </Box>
      }
      content={
        <Box display="flex" style={{ paddingTop: theme.spacing(10) }}>
          <QrScanner
            onDecode={(result) => onSuccessScan(result)}
            onError={(error) => onErrorScan(error?.message)}
          />
        </Box>
      }
    />
  );
};

export default ReQrReader;
