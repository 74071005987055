import React, { Suspense, startTransition } from 'react';
import { Grid, Typography, ButtonBase, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import ReLazyImage from '@components/ReLazyImage';
import ReHasLinkScroll from './ReHashLinkScroll';
import useWindowSize from 'use-window-size-v2';
import { getImageThumnailGrid } from '@functions/imageOptimization';
import { useStoreAppColor } from '@state/useStoreAppColor';
const cols = 2;

const useStyles = makeStyles({
  panelContainer: { width: '100%', borderRadius: '1rem', padding: ".5rem" },
  panelSplitter: {
    width: `calc(100% / ${cols})`,
    padding: 5

  },
  panelItem: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    overflow: 'hidden',
    textAlign: 'left',
  },
  coverTitle: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  boxCenter: {
    display: 'flex',
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const PanelComponent = ({ index, data, width, onClickItem }) => {
  const classes = useStyles();
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))

  return (
    <Box boxShadow={3} sx={{ borderRadius: "1rem" }}>
      {/* <ReHasLinkScroll to={data.uuid_product_category} id={data.uuid_product_category}> */}
        <ButtonBase
          id={`button-${index}`}
          onClick={onClickItem}
          className={`${classes.panelItem}`}
          focusRipple>
          <Box
            flexDirection="column"
            whiteSpace="normal"
            sx={{
              width: '100%',
              background: appColor.backgroundProduct,
              borderRadius: "1rem",
              overflow: "hidden",
              height: '100%',
              objectFit: 'cover',
            }}
          >
            <Box display="flex" flexGrow={1} justifyContent="center" >
              <ReLazyImage
                index={index}
                url={getImageThumnailGrid(
                  data.url,
                  width / 2 - theme.spacing(6),
                  width / 2 - theme.spacing(6),
                )}

              />
            </Box>
            <Box className={classes.boxCenter}>
              <Box
                component="div"
                className={classes.coverTitle}
                overflow="hidden"
                textOverflow="ellipsis">
                <Typography
                  className="clampOnLine2"
                  variant="button"
                  sx={{ textAlign: 'center' }}>
                  {data.category_name}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ButtonBase >
      {/* </ReHasLinkScroll > */}
    </Box >
  );
};

const ReCategoryItem = ({ dataSet = [], handleClickItem }) => {
  const classes = useStyles();
  const { width } = useWindowSize();


  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: "column" }}>
        <Grid
          id="productCategories"
          container
          direction="row"
          className={classes.panelContainer}
        >
          {Array.isArray(dataSet) &&
            dataSet.map((data, index) => (
              <Grid
                item
                key={index}
                className={classes.panelSplitter}
              >
                <PanelComponent
                  index={index}
                  data={data}
                  width={
                    width > theme.containerWidth ? theme.containerWidth : width
                  }
                  onClickItem={() => handleClickItem(data, index)}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default ReCategoryItem;
