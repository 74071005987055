import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography, Badge, Box } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import { convertCurrency } from '@functions/convertNumber';
import theme from '@styles/theme';
import { getColorApp } from '@data/localStorageGetter';
import { useStoreAppColor } from '@state/useStoreAppColor';


const useStyles = makeStyles({
  buttonContainer: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: `calc(50% - 45%)`,
    width: '100%',
  },
  buttonBigScreenPosition: {
    left: `calc(50% - (${theme.containerWidth}px / 2))`,
  },
  button: {
    height: 50,
    width: '100%',
  },
  badge: {
    height: 15,
    minWidth: 15,
    fontSize: 10,
    lineHeight: 0.3,
    padding: '0 4px',
    backgroundColor: theme.palette.success.main,
  },
  typeSales: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: '0.75rem !important',
    paddingRight: theme.spacing(2),
    color: getColorApp().secondaryFontColor,
  },
  innerButton: {
    justifyContent: 'space-between',
  },
  endIconStyle: {
    alignItems: 'center',
  },
});

const ReFloatingStaticButton = ({ totalPrice, badgeCount, onClick }) => {
  const classes = useStyles();
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      sx={{
        padding: theme.spacing(2), gap: theme.spacing(2),
        backgroundColor: appColor.backgroundColor
      }}>
      <Button
        id={'openCheckoutButton'}
        onClick={onClick}
        variant="contained"
        sx={{
          backgroundColor: appColor.primaryThemeColor,
          '&:hover': {
            backgroundColor: appColor.primaryThemeColor,
          },
          display: "flex",
          justifyContent: 'space-between',
          alignItems: "center"
        }}
        size="large"
        endIcon={
          <>
            <Typography variant="caption" className={classes.typeSales}>
              VIEW CART
            </Typography>
            <Badge badgeContent={badgeCount} classes={{ badge: classes.badge }}>
              <ShoppingCart
                sx={{
                  fontSize: 24,
                  color: appColor.secondaryFontColor,
                }}
              />
            </Badge>
          </>
        }
        classes={{ label: classes.innerButton, endIcon: classes.endIconsx }}
        className={classes.button}>
        <Typography sx={{ color: appColor.secondaryFontColor }}>
        {convertCurrency(totalPrice)}
        </Typography>
      </Button>
    </Box>
  );
};

export default ReFloatingStaticButton;
