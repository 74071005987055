import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames";

export const useStoreCustomParameter = create(persist((set) => ({
    customParameter: {},
    updateCustomParameter: (newData) => {
        set({ customParameter: newData })
    }
}), {
    name: localStorageNames.CUSTOM_PARAMETER
}
)
)
