import { create } from "zustand"
import { persist } from "zustand/middleware"

import localStorageNames from "@data/localStorageNames";


export const useStoreSplashImage = create(persist((set) => ({
    storeSplashImage: "",
    updateSplashScreen: (newSplashImage) => {
        set({ storeSplashImage: newSplashImage })
    }
}),
    {
        name: localStorageNames.SPLASH
    }
))