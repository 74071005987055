import { create } from "zustand"
import { persist } from "zustand/middleware"

import localStorageNames from "@data/localStorageNames";


export const useStoreFeedback = create(persist((set, get) => ({
    feedback: {},
    kodeFeedback: '005',
    _hasHydratedFeedback: false,
    setHasHydratedFeedback: (state) => {
        set({  _hasHydratedFeedback: state  });
    },
    updateFeedback: (newFeedback) => {
        set({ feedback: newFeedback })
    },
    getFeedbackList: () => {
        return get().feedback
    }
}),
    {
        name: localStorageNames.FEEDBACK,
        onRehydrateStorage: () => (state) => {
            state.setHasHydratedFeedback(true)
        }
    }
))