import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  useMediaQuery,
  ButtonBase,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { Cancel } from '@mui/icons-material';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { width } from '@mui/system';

const useStyles = makeStyles({
  paper: {
    width: `95% !important`,
    margin: '32px 0 32px 0 !important',
    overflowY: 'visible',

  },
  modalFullScreen: {
    width: '100% !important',
  },
  limitSize: {
    maxWidth: `${theme.containerWidth}px !important`,
  },
  paperWidthFalse: {
    maxWidth: '100%',
  },
  modalTitle: {
    textAlign: 'center',
    fontWeight: 'bold !important',
  },
  paddingCloseButton: {
    paddingTop: theme.spacing(5),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

const ReDialog = forwardRef(
  (
    {
      closeModal,
      modalStyle,
      title,
      titleStyle,
      content,
      fullScreen,
      floatingTitle,
      scrollType,
      useCloseButton,
      dialogStyle,
      contentStyle,
      backgroundStyle,
      useForwardRef,
    },
    ref,
  ) => {
    const classes = useStyles();
    const bigSizeScreen = useMediaQuery('(min-width:600px)');
    const { appColor } = useStoreAppColor(state => ({
      appColor: state.appColor
    }))

    const [localState, setLocalState] = useState({
      visibilitiy: false,
      content: null,
      title: null,
    });

    const getLocalState = (key) => {
      return key ? localState[key] : localState;
    };

    const updateLocalState = (newData) => {
      setLocalState((prev) => ({
        ...prev,
        ...newData,
      }));
    };

    const closeDialog = (event, reason) => {
      if (reason && reason === "backdropClick") return;
      if (useForwardRef) {
        updateLocalState({ visibilitiy: false });
      } else {
        closeModal();
      }
    };

    const FloatingBox = ({ children }) => (
      <>{floatingTitle ? <Box boxShadow={3}>{children}</Box> : children}</>
    );

    useImperativeHandle(ref, () => ({
      setVisibility(value, data) {
        if (!value) {
          updateLocalState({ visibilitiy: value, content: null, title: null });
        }
        updateLocalState({
          visibilitiy: value,
          content: data?.content,
          title: data?.title,
        });
      },
    }));

    // open = jika ada props useForwardRef, pakai default yaitu true
    // jika tidak ada gunakan local state biar berdiri sendiri
    // onClose = jika ada props useForwardRef, gunakan closeModal
    // jika tidak update local state
    return (
      <Dialog
        open={useForwardRef ? getLocalState()?.visibilitiy : true}
        onClose={(e, r) => {
          closeDialog(e, r);
        }}
        scroll={scrollType || 'body'}
        maxWidth={false}
        fullScreen={fullScreen}
        className={dialogStyle}
        classes={{
          paper: `${classes.paper} ${modalStyle} ${fullScreen && classes.modalFullScreen
            } ${classes.limitSize}`,
          paperWidthFalse: classes.paperWidthFalse,
        }}
      >
        {useCloseButton && (
          <Box className={`${classes.closeButton} closeModal`}>
            <ButtonBase onClick={() => closeDialog()}>
              <Cancel
                fontSize="large"
                sx={{ color: appColor?.primaryFontColor }}
              />
            </ButtonBase>
          </Box>
        )}
        <FloatingBox>
          <DialogTitle
            sx={{ ...backgroundStyle, display: "flex", justifyContent: "center"}}
            className={`${classes.modalTitle} ${titleStyle} ${useCloseButton && classes.paddingCloseButton
              }`}>
              <Box sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "90%"
              }}>
            {title || getLocalState()?.title}
              </Box>
          </DialogTitle>
        </FloatingBox>
        <DialogContent
          sx={{
            ...contentStyle,
            ...backgroundStyle,
          }}>
          {content || getLocalState()?.content}
        </DialogContent>
      </Dialog>
    );
  },
);

export default ReDialog;
