import { create } from "zustand"
import { persist } from "zustand/middleware"

export const useStoreToggleDrawer = create(persist((set) => ({
    categoryList: {},
    flatCategoryList: {},

    setCategoryList: (newCategoryList) => {
        //NOTE: Buat index untuk menghitung comulative index, untuk seluruh item
        let index = 0
        let data = Array.isArray(newCategoryList)
            ? newCategoryList.map((newData) => {
                //NOTE: pengecekan apakah item memiliki categories lagi didalam category
                if (Array.isArray(newData.categories) && newData.categories.length > 0) {
                    //NOTE: Ambil item dari dalam categories yang dibutuhkan untuk rendering saja
                    const updatedCategories = newData.categories.map((category) => {

                        return {
                            uuid_product_category: category.uuid_product_category,
                            categories: category.categories,
                            category_name: category.category_name,
                            url: category.url,
                            index: index++
                        };
                    });
                    //NOTE: Rebuilt uland item dengan attribute yang dibutuhkan untuk rendering saja
                    return {
                        uuid_product_category: newData.uuid_product_category,
                        categories: updatedCategories,
                        category_name: newData.category_name,
                        url: newData.url,
                    };
                } else {
                    return {
                        uuid_product_category: newData.uuid_product_category,
                        categories: [],
                        category_name: newData.category_name,
                        index: index++,
                        url: newData.url,
                    };
                }
            })
            : [];
        set({ categoryList: data });
    },
    setFlatCategoryList: (newCategories) => {
        let categories = [];
        newCategories.forEach((category, index) => {
            //NOTE: pengecekan apakah item memiliki categories lagi didalam category
            if (
                Array.isArray(category.categories) &&
                category.categories.length > 0
            ) {
                //NOTE: Ambil item dari dalam categories yang dibutuhkan untuk rendering saja
                category.categories.forEach((category2, index) => {
                    categories.push({
                        uuid_product_category: category2.uuid_product_category,
                        url: category2.url,
                        index: index,
                        category_name: category2.category_name,
                        menu_type: category2.menu_type,
                    });

                });
            } else {
                categories.push({
                    uuid_product_category: category.uuid_product_category,
                    url: category.url,
                    index: index,
                    category_name: category.category_name,
                    menu_type: category.menu_type,
                });
            }

            set({ flatCategoryList: categories })
        });
    }

}),
    {
        name: 'categoryList'
    }
))