import {
  Box,
  Button,
  Dialog,
  DialogContent,
  ButtonBase,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import React from 'react';
import { useTranslation } from 'react-i18next';
import theme from '@styles/theme';
import { Cancel } from '@mui/icons-material';
import { useStoreAppColor } from '@state/useStoreAppColor';

const useStyles = makeStyles(() => ({
  allPadding: {
    padding: `0px !important`,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

const ReAlertDialog = ({
  open,
  titleDialog,
  messageDialog,
  closeDialog,
  confirmDialog,
}) => {
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="customized-dialog-title">
      <DialogContent className={classes.allPadding}>
        <Box
          id={'reAlertDialog'}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <Box className={classes.closeButton} id="closeButtonAlert">
            <ButtonBase onClick={() => closeDialog()}>
              <Cancel fontSize="large" style={{ color: appColor.primaryFontColor }} />
            </ButtonBase>
          </Box>
          <Box
            id="titleDialog"
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            fontWeight={500}
            fontSize={16}
            style={{ padding: theme.spacing(2), paddingTop: theme.spacing(8) }}>
            <b>
              {titleDialog}
            </b>
          </Box>
          <Box
            id="messageDialog"
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            style={{
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
              paddingLeft: theme.spacing(5),
              paddingRight: theme.spacing(5),
            }}>
            {messageDialog}
          </Box>
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
            style={{
              paddingTop: theme.spacing(3),
              paddingBottom: theme.spacing(6),
            }}>
            <Button
              variant="contained"
              style={{ backgroundColor: appColor.primaryThemeColor, color: appColor.secondaryFontColor }}
              // color={getColorApp().primaryThemeColor}
              onClick={() => confirmDialog()}>
              {t('noThanks')}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReAlertDialog;
