import { create } from "zustand"
import { persist } from "zustand/middleware"
export const useStoreTabData = create(persist((set) => ({
    flatTabData: {},
    setFlatTabData: (newCategories) => {
        let categories = [];
        let index = 0
        //NOTE: Membuat data item baru duntuk digunakan di tab menu
        newCategories.forEach((category) => {
            //NOTE: Pengecekan pakah category memiliki categories didalamanya
            if (
                Array.isArray(category.categories) &&
                category.categories.length > 0
            ) {
                // NOTE: Looping untuk seluruh item dan 
                //NOTE: ambil attribute yang digunakan untuk rendering saja
                category.categories.forEach((category2) => {
                    categories.push({
                        uuid_product_category: category2.uuid_product_category,
                        category_name: category2.category_name,
                        url: category2.url,
                        index: index++
                    });

                });
            } else {
                categories.push({
                    uuid_product_category: category.uuid_product_category,
                    category_name: category.category_name,
                    url: category.url,
                    index: index++
                });
            }

            set({ flatTabData: categories })
        });
    }

}),
    {
        name: 'tabData'
    }
))