import { useStoreProduct } from '@state/useStoreProduct';
import URLS from '@/URLS';
import { useHistory } from 'react-router-dom';

export default function useCheckProducts() {
    const { product } = useStoreProduct(state => ({
        product: state.product
    }))
    const history = useHistory()

    if (product === "") {
        history.replace(URLS.SESSION_EXPIRED)
    }
}