import React, { useEffect, useState, useRef } from 'react';
import { Typography, ButtonBase, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { convertCurrency } from '@functions/convertNumber';
import { useCartContext } from '@context/CartContext';
import { useTranslation } from 'react-i18next';
import { getPriceBySellBand } from '@functions/getItemPriceBySellBand';
import { useGeneralContext } from '@context/GeneralContext';
import { useAuthContext } from '@context/AuthContext';
import { getImageThumnailGrid } from '@functions/imageOptimization';
import {
  AutoSizer,
  Grid,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import { v4 as uuidv4 } from 'uuid';
import ReLegend from './ReLegend';
import ReLazyImage from './ReLazyImage';
import 'react-virtualized/styles.css';
import useWindowSize from 'use-window-size-v2';
import { isProductActive } from '@functions/checkProductActive';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreNutriGrade } from '@state/useStoreNutriGrade';
import { useStoreProduct } from '@state/useStoreProduct';
import AlertProductDialog from './AlertProductDialog';
import { spreadProductData } from '@data/localStorageSetter';
import { useStoreProductCategory } from '@state/useStoreProductCategory';
import { useStoreItemsDetails } from '@state/useStoreItemsDetails';

const useStyles = makeStyles({
  panelItem: {
    width: '100%',
    overflow: 'hidden',
    textAlign: 'left',
  },
  disablePanel: {
    backgroundColor: '#F5F5F5',
    opacity: 0.7,
    filter: 'grayscale(1)',
  },
  paddingText: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  gridRight: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  gridLeft: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  viewGrid: {
    overflowX: 'hidden',
    height: 'auto !important',
    '& .ReactVirtualized__Grid__innerScrollContainer': {
      height: 'inherit !important',
      maxHeight: 'inherit !important',
      display: 'flex',
      flexWrap: 'wrap',
      padding: `0 ${theme.spacing(1)}`,
    },
  },
  panelTitle: {
    padding: theme.spacing(2),
  },
  positionNutriGrade: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(2),
  },
});

const PanelComponent = ({
  classes,
  t,
  getSellBandId,
  getSetting,
  data,
  onClickItem,
}) => {
  const { width } = useWindowSize();
  let fixwidth = width > theme.containerWidth ? theme.containerWidth : width;
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const { urlNutriGrade } = useStoreNutriGrade((state) => ({
    urlNutriGrade: state.urlNutriGrade,
  }));

  return (
    <Box
      boxShadow={3}
      sx={{
        opacity: isProductActive(data) ? 1 : 0.7,
        width: '100%',
        height: '100%',
        borderRadius: '1rem',
      }}>
      <ButtonBase
        disabled={!isProductActive(data)}
        focusRipple
        sx={{ width: '100%', height: '100%' }}>
        <Box
          id={data.plu_name}
          display="flex"
          flexDirection="column"
          disabled={!isProductActive(data)}
          onClick={() => {
            onClickItem(data);
          }}
          className={`${classes.panelItem} ${
            !isProductActive(data) && classes.disablePanel
          }`}
          sx={{
            background: appColor.backgroundProduct,
            borderRadius: '1rem',
            height: '100%',
          }}>
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="center"
            position="relative">
            <ReLazyImage
              url={getImageThumnailGrid(
                data.url,
                fixwidth / 2 - 12,
                fixwidth / 2 - 12,
              )}
              style={{
                width: fixwidth / 2 - 12,
                height: fixwidth / 2 - 12,
              }}
              index="product"
            />
            {urlNutriGrade(data, 'v') && (
              <Box className={`${classes.positionNutriGrade} nutri-grade`}>
                <ReLazyImage
                  url={urlNutriGrade(data, 'v')}
                  style={{ width: 40 }}
                  index="nutri-grade-product"
                />
              </Box>
            )}
          </Box>
          <Box
            className={classes.paddingText}
            display="flex"
            flexDirection="column"
            width={1}
            boxSizing="border-box">
            <Typography
              className="clampOnLine2"
              variant="body1"
              sx={{
                height: 45,
                textAlign: 'left',
                lineHeight: 1,
                marginTop: theme.spacing(1),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                letterSpacing: 0,
              }}>
              {data.plu_name}
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ marginTop: '10px' }}>
              <Box
                component="div"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize={12}
                whiteSpace="noWrap">
                <b>
                  {isProductActive(data)
                    ? convertCurrency(
                        getPriceBySellBand(data, getSellBandId(), true),
                      )
                    : getSetting() && getSetting().soldOutText
                    ? getSetting().soldOutText
                    : t('outOfStock')}
                </b>
              </Box>
              <Box display="flex" flexDirection="row-reverse" flex="1">
                {data.legend && <ReLegend legend={data.legend} type="grid" />}
              </Box>
            </Box>
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
};

const ReGridviewPanelTab = ({ category }) => {
  const classes = useStyles();
  const { addToCart, setCartModal, getBadgeCount, isLoggedIn } =
    useCartContext();
  const { getSellBandId, openSuccessSnackBar } = useGeneralContext();
  const { getSetting, getAuthState } = useAuthContext();
  const { t } = useTranslation();
  const [productList, setProductList] = useState([]);
  const { products } = useStoreProduct((state) => ({
    products: state.products,
  }));
  const [open, setOpen] = useState(false);
  const { prodCategory } = useStoreProductCategory((state) => ({
    prodCategory: state.prodCategory,
  }));
  const { itemsDetails } = useStoreItemsDetails((state) => ({
    itemsDetails: state.itemsDetails,
  }));

  const handleClickItem = (data) => {
    data.group = [];
    if (
      getSetting() &&
      getSetting().isDirectOrder &&
      !data.is_variant &&
      data.modifier_group?.length < 1 &&
      data.group.length < 1 &&
      !data.is_have_group
    ) {
      data.timestamp = new Date().getTime();
      data.quantity = 1;
      addToCart(data);
      openSuccessSnackBar(`1 x "${data.plu_name}" ${t('hasBeenAddedToCart')}`);
    } else {
      setCartModal(true, data);
    }
  };

  const CellRenderer = ({ columnIndex, rowIndex, style }) => {
    const columCount = 2;
    const dataIndex = productList[rowIndex * columCount + columnIndex];
    if (!dataIndex) return null;

    const dataVariants = itemsDetails.get(dataIndex.uuid_product);
    const item = { ...dataIndex, ...dataVariants };

    return (
      <div
        key={item.uuid_product}
        style={{
          width: '50%',
          float: 'left',
          padding: '5px',
        }}>
        <PanelComponent
          classes={classes}
          t={t}
          getSellBandId={getSellBandId}
          getSetting={getSetting}
          data={item}
          onClickItem={(data) =>
            handleClickItem({ ...data, signature_token: uuidv4() })
          }
        />
      </div>
    );
  };

  useEffect(() => {
    const productMap = prodCategory.get(category.uuid_product_category);

    // if (!getAuthState('productTabSelected').products && products) {
    //   console.log('kosong-----');
    //   setOpen(true);
    //   spreadProductData(products);
    // }
    setProductList(productMap.products);
    // eslint-disable-next-line
  }, [getAuthState('tabCategory').index, category]);

  return (
    <div style={{ height: 'inherit' }}>
      <AlertProductDialog
        open={open}
        data={category}
        close={() => setOpen(false)}
      />
      <Box className={classes.panelTitle}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {' '}
          &#8226; {category.category_name}
        </Typography>
      </Box>
      {productList && productList.length > 0 && (
        <AutoSizer>
          {({ height, width }) => {
            const productLength = productList ? productList?.length : 0;
            const rowCount =
              productLength % 2 === 0
                ? productLength / 2
                : Math.round(productLength / 2);
            const columnWidth = Math.floor(width / 2);
            const rowHeight = columnWidth + 74;

            return (
              <Grid
                columnCount={2}
                columnWidth={columnWidth}
                height={height / 0.5}
                width={width}
                className={classes.viewGrid}
                style={{
                  paddingBottom:
                    getBadgeCount() > 0 && isLoggedIn()
                      ? theme.spacing(18)
                      : theme.spacing(1),
                }}
                rowCount={rowCount}
                rowHeight={(params) => {
                  return params.index === rowCount - 1
                    ? rowHeight - 10
                    : rowHeight;
                }}
                cellRenderer={CellRenderer}
              />
            );
          }}
        </AutoSizer>
      )}
    </div>
  );
};

export default ReGridviewPanelTab;
