import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { Backdrop,  Modal, Fade, Typography,IconButton,Alert, AlertTitle } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';


const ModalContent = styled('div')(({ theme }) => ({
    width: "100%",
    maxWidth: "100%",
    padding: theme.spacing(1), // Increased padding
    paddingTop: theme.spacing(1), // Increased top padding
    paddingBottom: theme.spacing(1), // Added bottom padding
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    background: "transparent",
    textAlign: 'center',
    outline: 'none',
    margin: 'auto', // Center horizontally
    position: 'absolute',
    top: '50%', // Center vertically
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Adjust position to ensure exact center
}));
const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: theme.zIndex.drawer + 2,
        outline: 'none', // Remove default modal outline
    },
    modalContent: {
        position: 'relative',
        width: "100%",
        maxWidth: 500, // Limit the maximum width
        padding: theme.spacing(3),
        backgroundColor: 'white',
        borderRadius: theme.shape.borderRadius, // Add rounded corners
        textAlign: 'center', // Center text content
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },

})

const RewardsAlertDialog = (_props, ref) => {
    const classes = useStyles();
    const [visible, setVisible] = useState(false)
    const show = (flag) => {
        setVisible(flag);
    };

    const {t} = useTranslation()

    useImperativeHandle(ref, () => ({ show }));

    const handleClose = () => {
        setVisible(false);
    };
    return (
        <Modal
            aria-labelledby="rewards-alert-modal"
            aria-describedby="resolving-service-charge"
            open={ref ? visible : true}
            slots={{ backdrop: Backdrop }}
            className={classes.modal}
            onClose={handleClose}
        >
            <Fade in={visible}>
                <ModalContent>
                    <Alert
                        icon={<ErrorIcon fontSize="large" />}
                        severity="warning"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            padding: theme.spacing(4), // Increased padding
                            '& .MuiAlert-message': {
                              padding: theme.spacing(2, 0), // Added vertical padding to message
                            },
                        }}
                    >
                         <Typography 
              style={{ 
                fontWeight: "bold", 
                paddingBottom: theme.spacing(2),
              }} 
              variant="body2"
            >
             {t("rewardAlertTitle")}
            </Typography>
            <Typography 
              variant="body2"
              sx={{
                lineHeight: 1.6
              }}
            >
             {t("rewardAlertDescription")}
            </Typography>
                    </Alert>
                </ModalContent>
            </Fade>
        </Modal >
    );

};

export default forwardRef(RewardsAlertDialog)
