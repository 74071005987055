import { useEffect, useState } from "react";
import { useStoreFeedback } from "@state/useStoreFeedback";
import { useAuthContext } from "@context/AuthContext";
import { useGeneralContext } from "@context/GeneralContext";
import { useTranslation } from "react-i18next";
import localStorageNames from "@data/localStorageNames";
export default function useFeedback() {
    const { sendFeedback, getAuthState } = useAuthContext();
    const { openErrorSnackBar, openWarningSnackBar, openSuccessSnackBar } = useGeneralContext();
    const { t } = useTranslation();
    const [listDataFeedback, setListDataFeedback] = useState([]);
    const [isFilledFeedback, setFilledFeedback] = useState(false);
    const { getFeedbackList } = useStoreFeedback(state => ({
        getFeedbackList: state.getFeedbackList
    }))

    const loadFeedback = () => {
        if (getFeedbackList()) {
            let feedbackList = [];
            feedbackList = getFeedbackList();
            if (
                feedbackList &&
                Array.isArray(feedbackList) &&
                feedbackList.length > 0
            ) {
                let mappingData = feedbackList.map((data) => {
                    return { ...data, value: data.type === 'rating' ? '0' : '' };
                });
                setListDataFeedback(mappingData);
            }
        }
    };

    const handleRating = (data) => {
        const list = listDataFeedback.map((item) => {
            if (item.uuid_setting_feedback === data.uuid_setting_feedback) {
                if (!data.value && data.type === 'rating') data.value = '0';
                return { ...item, value: data.value };
            } else {
                return item;
            }
        });
        setListDataFeedback(list);
    };

    const handleBtnSend = () => {
        if (
            listDataFeedback &&
            Array.isArray(listDataFeedback) &&
            listDataFeedback.length > 0
        ) {
            let isComplete = true;

            const isValid = (v) => {
                if (v.is_required && (v.value === '0' || v.value.length === 0)) {
                    return false;
                }
                return true;
            };

            if (!listDataFeedback.every(isValid)) {
                openErrorSnackBar(t('required'));
                isComplete = false;
            }

            if (isComplete) {
                let filterFeedback = listDataFeedback
                    .map((data) => {
                        return data;
                    })
                    .filter((data) => {
                        let isOK = false;
                        if (data.is_required) {
                            isOK = true;
                        } else {
                            if (data.type === 'text') {
                                if (data.value !== null && data.value !== '') {
                                    isOK = true;
                                } else {
                                    isOK = false;
                                }
                            } else if (data.type === 'rating') {
                                if (
                                    data.value !== null &&
                                    data.value !== '' &&
                                    data.value !== '0'
                                ) {
                                    isOK = true;
                                } else {
                                    isOK = false;
                                }
                            } else {
                                isOK = false;
                            }
                        }
                        return isOK;
                    });
                let dataFeedback = { feedbacks: filterFeedback };
                sendFeedback(dataFeedback);
            }
        }
    };

    useEffect(() => {
        const checkFilledFeedback = localStorage.getItem(
            localStorageNames.FILLED_FEEDBACK,
        );
        if (checkFilledFeedback) {
            setFilledFeedback(true);
        } else {
            setFilledFeedback(false);
            loadFeedback();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let resultCreateFeedback = getAuthState('feedbackResult');
        const checkFilledFeedback = localStorage.getItem(
            localStorageNames.FILLED_FEEDBACK,
        );
        if (resultCreateFeedback.message) {
            if (resultCreateFeedback.error) {
                openWarningSnackBar(t("warningFeedback"));
            } else {
                if (!checkFilledFeedback) {
                    openSuccessSnackBar(resultCreateFeedback.message);
                }
                localStorage.setItem(localStorageNames.FILLED_FEEDBACK, true);
                setFilledFeedback(true);
            }
        }
        // eslint-disable-next-line
    }, [getAuthState('feedbackResult')]);

    return [listDataFeedback, isFilledFeedback, handleRating, handleBtnSend]
}
