import React, { useEffect } from 'react';
import { Grid, Typography, Box, ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReLazyImage from '@components/ReLazyImage';
import theme from '@styles/theme';
import { useCartContext } from '@context/CartContext';
import { useTranslation } from 'react-i18next';
import { useGeneralContext } from '@context/GeneralContext';
import { useAuthContext } from '@context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import ReLegend from './ReLegend';
import { getImageThumnailList } from '@functions/imageOptimization';
import { isProductActive } from '@functions/checkProductActive';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreNutriGrade } from '@state/useStoreNutriGrade';
import { styled } from "@mui/system"
import { useStoreItemsDetails } from '@state/useStoreItemsDetails';
const useStyles = makeStyles({
  heightItem: {
    height: 90,
  },
  panelContainer: {
    width: '100%',
    padding: `0 ${theme.spacing(4)}px`,
    margin: `0 0 ${theme.spacing(1)}px 0`,
  },
  panelItem: {
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    textAlign: 'left',
    maxHeight: 90,
  },
  itemImage: {
    display: 'inline-block',
    float: 'left',
    width: 120,
  },
  itemInformationWrapper: {
    display: 'inline-block',
    float: 'left',
    width: '100%',
    height: '100%',
  },
  itemInformationWithImage: {
    padding: '5px 8px',
    flex: 1,
  },
  itemInformationNoImage: {
    padding: '5px 8px',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    height: '100%',
  },
  productName: {
    maxHeight: '3rem',
    width: '55%',
    float: 'left',
    lineHeight: 1,
    marginTop: theme.spacing(1),
    minHeight: 20,
  },
  price: {
    width: '45%',
    float: 'left',
    textAlign: 'right',
  },
  menus: {
    marginBottom: 10,
    borderRadius: 10,
    margin: `${theme.spacing(2)}px 0`,
  },
  disabledMenus: {
    opacity: 0.7,
    filter: 'grayscale(1)',
  },
  disablePanel: {
    backgroundColor: '#F5F5F5',
  },
});



const PanelWithOrNoImage = ({
  index,
  classes,
  t,
  getSellBandId,
  getSetting,
  data,
  handleClickItem,
}) => {
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))
  const { urlNutriGrade } = useStoreNutriGrade(state => ({ urlNutriGrade: state.urlNutriGrade }))

  return (
    <Grid
      id={`item-${index}`}
      item
      className={`${classes.panelItem} ${classes.heightItem} ${!isProductActive(data) && classes.disablePanel
        }`}
      sx={{
        background: appColor.backgroundProduct,
      }}
      onClick={() => {
        handleClickItem(data);
      }}>
      {data.url && (
        <Box className={classes.itemImage}>
          <ReLazyImage url={getImageThumnailList(data.url)} />
        </Box>
      )}
      <Box
        className={classes.itemInformationWrapper}
        sx={data.url ? { width: `calc(100% - 120px)` } : { width: '100%' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          sx={{
            height: '100%', display: "flex",
            justifyItems: "center",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={
              data.url
                ? classes.itemInformationWithImage
                : classes.itemInformationNoImage
            }
          >
            <Typography
              className={`${classes.productName} clampOnLine2`}
              sx={{ width: data.url ? '55%' : '65%', lineHeight: 1 }}
              variant="body1"
            >
              {data.plu_name}
            </Typography>
            <Box
              component="p"
              textOverflow="ellipsis"
              overflow="hidden"
              fontSize={12}
              className={classes.price}
              sx={
                data.url
                  ? { marginTop: theme.spacing(1) }
                  : { whiteSpace: 'nowrap' }
              }
            >
              <b>
                {isProductActive(data)
                  ? data.price
                  : getSetting() && getSetting().soldOutText
                    ? getSetting().soldOutText
                    : t('outOfStock')}
              </b>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="flex-end"
            flexDirection="row"
            flex="1">
            {urlNutriGrade(data, "h") && (
              <Box
                className="nutri-grade"
                sx={{ marginLeft: theme.spacing(3) }}
              >
                <ReLazyImage
                  url={urlNutriGrade(data, "h")}
                  style={{ width: 80 }}
                />
              </Box>
            )}
            {data.legend && <ReLegend legend={data.legend} type="list" />}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const ReListviewPanel = ({ dataSet = {}, index }) => {
  const classes = useStyles();
  const { addToCart, setCartModal } = useCartContext();
  const { openSuccessSnackBar, getSellBandId } = useGeneralContext();
  const { itemsDetails } = useStoreItemsDetails(state => ({
    itemsDetails: state.itemsDetails
  }))
  const { getSetting } = useAuthContext();
  const { t } = useTranslation();
  const handleClickItem = (data) => {
    data.group = [];
    if (
      getSetting().isDirectOrder &&
      !data.is_variant &&
      data.modifier_group.length < 1 &&
      data.group.length < 1 &&
      !data.is_have_group
    ) {
      data.timestamp = new Date().getTime();
      data.quantity = 1;
      addToCart(data);
      openSuccessSnackBar(`1 x "${data.plu_name}" ${t('hasBeenAddedToCart')}`);
    } else {
      setCartModal(true, data);
    }
  };

  return (
    <Grid
      id={dataSet.category_name.replace(/ /g, '')}
      className={classes.panelContainer}>
      <Grid item sx={{
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(1),
      }}>
        <Typography variant="h6"> &#8226; {dataSet.category_name}</Typography>
      </Grid>
      <Grid container direction="column" sx={{ padding: "10px" }}>
        {Array.isArray(dataSet.products) &&
          dataSet.products.map((dataOld, index) => {
            const dataVariant = itemsDetails.get(dataOld.uuid_product)
            const data = {...dataOld, ...dataVariant}
            return (
              <Box
                boxShadow={3}
                className={`${classes.menus} ${!isProductActive(data) && classes.disabledMenus
                  } productItem`}
                key={index} >
                <ButtonBase
                  sx={{ width: '100%' }}
                  disabled={!isProductActive(data)} >
                  <PanelWithOrNoImage
                    index={index}
                    classes={classes}
                    t={t}
                    getSellBandId={getSellBandId}
                    getSetting={getSetting}
                    data={data}
                    handleClickItem={(data) => {
                      handleClickItem({ ...data, signature_token: uuidv4() });
                    }}
                  />
                </ButtonBase>
              </Box>
           )
          }
            
          )}
      </Grid>
    </Grid >
  );
};

export default ReListviewPanel;
