import React, { useState, useEffect, useCallback } from 'react';
import {
  Tabs,
  Divider,
  Grid,
  Typography,
  Box,
  Avatar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReHashLinkScroll from './ReHashLinkScroll';
import theme from '@styles/theme';
import { getImageMenuCategory } from '@functions/imageOptimization';
import { getColorApp } from '@data/localStorageGetter';
import { useStoreCategory } from '@state/useStoreCategory';
import { useStoreTabData } from '@state/useStoreTabData';
import { useStoreAppColor } from '@state/useStoreAppColor';

const useStyles = makeStyles({
  rootTabs: {
    width: "100%",
    backgroundColor: 'white',
  },
  rootTabItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
  },
  indicator: {
    backgroundColor: getColorApp()?.secondaryThemeColor,
    height: 5,
  },
  activeStyle: {
    '& .active': {
      color: getColorApp()?.secondaryThemeColor,
    },
  },
  selectedColor: {
    color: getColorApp()?.secondaryThemeColor,
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});


const ScrollableList = () => {
  const classes = useStyles();
  // const [state, setState] = useState({
  //   indexSelected: 0,
  //   newData: []
  // });

  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))

  const { activeSection, setActiveSection, setTabScrolling } = useStoreCategory(state => ({
    activeSection: state.activeSection,
    setActiveSection: state.setActiveSection,
    setTabScrolling: state.setTabScrolling
  }))
  const { tabData } = useStoreTabData(state => ({
    tabData: state.flatTabData
  }))

  const onClickActive = (value) => {
    // console.log(value)
    setActiveSection(value)
    setTabScrolling(true)

  }


  return (
    <Box boxShadow={2} sx={{ width: "100%" }}>
      <Tabs
        className={classes.rootTabs}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: appColor.primaryThemeColor,
            height: 5,
          }
        }}
        value={activeSection}
        variant="scrollable"
        scrollButtons={false}
        indicator={undefined}
        id="tabtab"
      >
        {Array.isArray(tabData) &&
          tabData.map((data, index) => (
            <Box
              id={`tab-${index}`}
              key={index + data.category_name}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
                color: index === activeSection
                  ? appColor.secondaryThemeColor
                  : 'inherit',  // Default color or undefined
              }}
            >
              {index !== 0 && <Divider orientation="vertical" flexItem />}
              {/* <ReHashLinkScroll
                to={data.uuid_product_category}
                onClick={() => onClickActive(index)}
              > */}
              <Grid className={classes.rootTabItem} onClick={() => onClickActive(index)}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignContent="center"
                  justifyContent="center">
                  <Avatar
                    alt="O"
                    src={getImageMenuCategory(data.url)}
                    variant="square"
                    className={classes.avatar}
                  />
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    style={{ marginBottom: theme.spacing(1) }}>
                    <Typography variant="body2">
                      {data.category_name}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {/* </ReHashLinkScroll> */}
            </Box>
          ))}
      </Tabs>
    </Box>
  );
};

export default ScrollableList;
