import { useState, useEffect } from 'react';

const useIntersectionObserver = (ref, options) => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [boundingClientRect, setBoundingClientRect] = useState(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
            setBoundingClientRect(entry.boundingClientRect);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, options]);

    return { isIntersecting, boundingClientRect };
};

export default useIntersectionObserver;
