import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PhonelinkErase } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { clearAllStorage } from '@context/AuthContext';
import theme from '@styles/theme';
import { useHistory } from 'react-router-dom';
import { sendPostMessageToParent } from '@functions/trafficController';

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    textTransform: 'capitalize',
    marginTop: '2vh',
  },
  helper: {
    display: 'inline-block',
  },
  content: {
    position: 'fixed',
    padding: theme.spacing(2),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: theme.containerWidth
  }
});

const CannotSicc = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  window.onpopstate = function () {
    sendPostMessageToParent('path', history.location.pathname);
    history.replace(history.location.pathname);
    ///clear history
  };

  useEffect(() => {
    clearAllStorage();
  }, []);

  return (
    <Box className={classes.container}>
      <Grid container direction="column" className={classes.content}>
        <Grid item>
          <PhonelinkErase style={{ fontSize: 60 }} />
        </Grid>
        <Grid item>
          <Typography variant="h6" className={classes.title}>
            {t('cannotSicc')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CannotSicc;