export function isProductActive(data) {
  let isActive = true;
  if (data.is_variant) {
    if (data.is_active === false) {
      isActive = data.is_active;
    } else {
      if (Array.isArray(data.variant_item) && data.variant_item.length > 0) {
        let isFalse = 0;
        for (let index = 0; index < data.variant_item.length; index++) {
          if (data.variant_item[index].is_active === false) {
            isFalse++;
          }
          if (isFalse === data.variant_item.length) {
            isActive = false;
          }
        }
      } else {
        isActive = false;
      }
    }
  } else {
    isActive = data.is_active;
  }


  return isActive;
}
