import React, { useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useStoreSettings } from '@state/useStoreSettings';

const ReDropdownLang = ({ }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n } = useTranslation();
  const { getLanguages } = useStoreSettings(state => ({
    getLanguages: state.getLanguages
  }))

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };



  const handleClose = (value) => {
    // if (value.constructor.name !== 'Class') {
    i18n.changeLanguage(value.toLowerCase());
    // }
    setAnchorEl(null);
  };


  return (
    <>
      <Button
        id="DropdownLang"
        onClick={handleClick}
        variant="contained"
        color='inherit'
        size="small"
        style={{ minWidth: 45 }}>
        {i18n.language.toUpperCase()} <KeyboardArrowDown />
      </Button>
      <Menu
        hideBackdrop
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {
          getLanguages().map((lang, index) => {
            return (
              <MenuItem
                key={index}
                id={lang}
                onClick={() => {
                  handleClose(lang);
                }}>
                {lang}
              </MenuItem>
            )
          })
        } 
      </Menu>
    </>
  );
};

export default ReDropdownLang;
