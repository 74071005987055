import URLS from '@/URLS';
import { sendPostMessageToParent } from './trafficController';

// generalErrorHandler(error, url, popUpCallback);
export const generalErrorHandler = (error, url, popUpCallback) => {
  // failed to fetch bisa jadi karena provider ngeblock
  // load failed bisa jadi karena lemot
  let message = error?.message?.toLowerCase();
  if (
    message.includes('failed to fetch') ||
    message.includes('load failed') ||
    message.includes('the network connection was lost') ||
    message.includes('network error') ||
    message.includes('jaringan koneksi')
  ) {
    popUpCallback();
    return false;
  }

  if (message.includes('object.fromentries')) {
    sendPostMessageToParent('path', URLS.BROWSER_NOT_SUPPORTED);
    window.location.replace(URLS.BROWSER_NOT_SUPPORTED);
    return false;
  }

  if (message.includes('timed out')) {
    sendPostMessageToParent('path', URLS.RTO);
    window.location.replace(URLS.RTO);
    return false;
  }

  if (message.includes('status 401')) {
    sendPostMessageToParent('path', URLS.RTO);
    window.location.replace(URLS.RTO);
    return false;
  }

  if (message.includes('cancelled') || message.includes('canceled')) {
    return false;
  }
  // return true == ngirim report ke sentry | return false != ngirim
  return true;
};
