import React, { useEffect, useState } from 'react';
import { Grid, Box, ButtonBase, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import theme from '@styles/theme';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRealmContext } from '@context/RealmContext';
import { convertCurrency } from '@functions/convertNumber';
import moment from 'moment-timezone';
import ReDialog from '@components/ReDialog';
import { useGeneralContext } from '@context/GeneralContext';
import URLS from '@/URLS';
import { getColorApp } from '@data/localStorageGetter';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  innerTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    height: 60,
    position: 'relative',
  },
  detailToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
  },
  iconButton: {
    padding: theme.spacing(1),
    width: 65,
    justifyContent: 'flex-start',
  },
  typeContainer: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  modalTitle: {
    backgroundColor: getColorApp().primaryThemeColor,
    height: 50,
    color: getColorApp().secondaryFontColor,
    padding: `0 ${theme.spacing(2)}px`,
  },
});

const ViewTransactionPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const { getHistoryPaymentOrder } = useRealmContext();
  const { showLoading } = useGeneralContext();

  const { t } = useTranslation();

  const [transactionList, setTransactions] = useState([]);

  const getHistoryTransaction = async () => {
    showLoading(true);
    let transaction = await getHistoryPaymentOrder();
    showLoading(false);
    setTransactions(transaction ? transaction.reverse() : []);
  };

  useEffect(() => {
    getHistoryTransaction();
    // eslint-disable-next-line
  }, []);

  return (
    <ReDialog
      fullScreen={true}
      closeModal={() => {}}
      floatingTitle={true}
      scrollType={'paper'}
      titleStyle={classes.modalTitle}
      contentStyle={{
        backgroundColor: getColorApp().backgroundColor,
        padding: 0,
      }}
      title={
        <Box className={classes.detailToolbar}>
          <ButtonBase
            className={classes.iconButton}
            onClick={() => history.goBack()}>
            <ChevronLeft style={{ width: 32, height: 32 }} />
          </ButtonBase>
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', display: 'flex', flexGrow: 1 }}>
            {t('viewTransaction').toUpperCase()}
          </Typography>
        </Box>
      }
      content={
        <Box className={classes.container}>
          {Array.isArray(transactionList) && transactionList.length > 0 ? (
            transactionList.map((data, index) => (
              <Grid
                container
                direction="column"
                className={classes.typeContainer}>
                <Box boxShadow={3} key={index} style={{ borderRadius: 10 }}>
                  <ButtonBase
                    style={{
                      width: '100%',
                      padding: theme.spacing(3),
                      background: getColorApp().backgroundProduct,
                    }}
                    onClick={() =>
                      history.push(URLS.DETAIL_TRANSACTION, { data: data })
                    }>
                    <Box display="flex" flex="1" flexDirection="column">
                      <Box
                        display="flex"
                        flex="1"
                        flexDirection="row"
                        style={{ justifyContent: 'space-between' }}>
                        <Typography>
                          {t('tableNumber')} : {data.table_name}
                        </Typography>
                        <Typography>
                          {convertCurrency(data.grand_total)}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        style={{ justifyContent: 'space-between' }}>
                        <Typography
                          variant="caption"
                          style={{ textAlign: 'left' }}>
                          {moment(data.updatedAt).format(
                            'DD MMM YYYY,  HH:mm:ss',
                          )}
                        </Typography>
                        <Typography variant="caption">
                          {data.payment_status}
                        </Typography>
                      </Box>
                      <Box display={'flex'} flexDirection="column">
                        {data.payment.length > 0 && (
                          <Typography
                            variant="caption"
                            style={{ textAlign: 'left' }}>
                            {data.payment &&
                            data.payment[0].channel_code.includes('ID_')
                              ? data.payment[0].channel_code.replace('ID_', '')
                              : data.payment[0].channel_code}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </ButtonBase>
                </Box>
              </Grid>
            ))
          ) : (
            <Box display="flex" flex="1" justifyContent="center">
              {t('viewBillTitle')}
            </Box>
          )}
        </Box>
      }
    />
  );
};

export default ViewTransactionPage;
