import { calculateSumTaxes } from '@libs/calculateSumTaxes';

export const standardizeData = (paramData) => {
    let dataBySalesType = {};
    let sumTaxes = [];
    let sumSubTotal = 0;
    let sumGrandTotal = 0;

    paramData.map((data) => {
        let { transaction_items, sub_total, grand_total, taxes } =
            data;

        Array.isArray(transaction_items) &&
            transaction_items.map((item) => {
                if (
                    typeof dataBySalesType[item.sales_type] === 'object' &&
                    Object.keys(dataBySalesType[item.sales_type]).length > 0
                ) {
                    // UPDATE OLD BILL DATA
                    dataBySalesType[item.sales_type].transaction_items.push(item);
                } else {
                    // NEW BILL DATA FOR THIS TYPE SALES
                    let insertData = {
                        transaction_items: [item],
                    };

                    dataBySalesType[item.sales_type] = { ...insertData };
                }
                return null;
            });

        sumTaxes = calculateSumTaxes(sumTaxes, taxes);
        sumSubTotal = sumSubTotal + sub_total;
        sumGrandTotal = sumGrandTotal + grand_total;
        return null;
    });

    return {
        data: dataBySalesType,
        taxes: sumTaxes,
        sub_total: sumSubTotal,
        grand_total: sumGrandTotal,
    };
};