import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames";

// useStoreCategorySectionId
export const useStoreCategory = create(persist((set) => ({
    category: {},
    categorySectionId: {},
    showCategoryPage: false,
    activeSection: 0,
    itemReady: false,
    tabScrolling: false,
    kodeCategory: '003',
    _hasHydratedCategory: false,
    setTabScrolling: (state) => {
        set({ tabScrolling: state })
    },
    setHasHydratedCategory: (state) => {
        set({ _hasHydratedCategory: state });
    },
    updateCategorySectionId: (newData) => {
        set((state) => ({
            categorySectionId: {
                ...state.categorySectionId, // Preserve existing state
                ...newData, // Apply new data
            },
        }));
    },
    setShowCategoryPage: (val) => {
        set({ showCategoryPage: val })
    },
    removeCategorySectionId: () => {
        set({ categorySectionId: {} })
    },
    setItemReady: (newVal) => {
        set({ itemReady: newVal })
    },
    setActiveSection: (newSectionIdx) => {
        set({ activeSection: newSectionIdx })
    },
    removeActiveSection:() => {
        set({activeSection: 0})
    },
    updateCategory: (newData) => {
        set({ category: newData })
    },
    removeCategory: () => {
        set({ category: {} })
    }
}), {
    name: localStorageNames.CATEGORY,
    onRehydrateStorage: () => (state) => {
        state.setHasHydratedCategory(true)
    }
}
)
)

export const useStoreScannerId = create(persist((set) => ({
    scannerResult: useStoreCategory.getState().category || {},
    updateScanner: (newData) => {
        set({ scannerResult: newData })
    }
}),
    {
        name: localStorageNames.CATEGORY
    }
)
)