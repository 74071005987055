import React from 'react';
import { Box } from '@mui/material';
import ReFloatingStaticButton from './ReFloatingStaticButton';
import { useCartContext } from '@context/CartContext';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { getColorApp } from '@data/localStorageGetter';

const useStyles = makeStyles({
  cartFixed: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    maxWidth: theme.containerWidth,
    backgroundColor: getColorApp().backgroundColor,

  },
});

const ReButtonOrder = () => {
  const classes = useStyles();
  const { handleOpenCheckoutPage, getBadgeCount, totalPrice } =
    useCartContext();

  return (
    <Box className={classes.cartFixed}>
      <ReFloatingStaticButton
        totalPrice={totalPrice()}
        badgeCount={getBadgeCount()}
        onClick={() => handleOpenCheckoutPage()}
      />
    </Box>
  );
};

export default ReButtonOrder;
