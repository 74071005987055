import { create } from "zustand"
import { persist } from "zustand/middleware"
// import { detectItemPrice } from "@functions/convertNumber";
// import { getItemsDetailStorage } from "@data/localStorageGetter"


const serializeMap = (map) => {
    return JSON.stringify(Array.from(map.entries()));
};

const deserializeMap = (str) => {
    return new Map(JSON.parse(str));
};
export const useStoreProductCategory = create(persist((set, get) => ({
    prodCategory: new Map(),
    setProdCategory: (products) => {
        set(state => {
            //NOTE:Buat object Map baru dari itemsDetails
            const newItems = new Map(state.itemsDetails);
            //NOTE:Membangung data products untuk variant items
            // NOTE: melakukan flatting, untuk menghilangkan nested items
            // NOTE:menghilangkan attribute yang tidak digunakan
            //NOTE: Rebuild Item dengan attribute yang digunakan saja
            const flattenCategories = (category, index = 0) => {
                if (Array.isArray(category.categories) && category.categories.length > 0) {
                    return category.categories.flatMap(subCategory => flattenCategories(subCategory, index));
                } else {
                    return {
                        // products: Array(10).fill().flatMap((_, repeatIndex) =>
                        //     category.products.map((subCategoryProduct) => ({
                        //         uuid_product: `${subCategoryProduct.uuid_product}-${repeatIndex}`,
                        //         is_active: subCategoryProduct.is_active,
                        //         legend: subCategoryProduct.legend,
                        //         plu_name: subCategoryProduct.plu_name,
                        //         plu_number: subCategoryProduct.plu_number,
                        //         price: detectItemPrice(subCategoryProduct),
                        //         index: index++,
                        //         url: subCategoryProduct.url,
                        //     }))
                        // ),
                        products: category.products.map(product => ({
                            uuid_product: product.uuid_product,
                            is_active: product.is_active,
                            is_have_group: product.is_have_group,
                            legend: product.legend,
                            plu_name: product.plu_name,
                            plu_number: product.plu_number,
                            // price: detectItemPrice(product),
                            sell_bands: product.sell_bands,
                            index: index++,
                            legend: product.legend,
                            url: product.url,
                        })),
                        uuid_product_category: category.uuid_product_category
                    };
                }
            };

            const flattenedCategories = products.flatMap(category => flattenCategories(category));


            flattenedCategories.forEach((category) => {
                newItems.set(category.uuid_product_category, {
                    products: category.products,
                });
            });
            return { prodCategory: newItems };
        })



    },
}), {
    name: "product_category",
    storage: {
        getItem: (name) => {
            const serializedState = localStorage.getItem(name);
            if (!serializedState) return undefined;
            const parsedState = JSON.parse(serializedState);
            parsedState.state.prodCategory = deserializeMap(parsedState.state.prodCategory);
            return parsedState;
        },
        setItem: (name, state) => {
            const serializedState = { ...state };
            serializedState.state.prodCategory = serializeMap(serializedState.state.prodCategory);
            localStorage.setItem(name, JSON.stringify(serializedState));
        },
        removeItem: (name) => {
            localStorage.removeItem(name);
        },
    },
}))