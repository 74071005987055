import { useStoreCartItems } from "@state/useStoreCartItems";
export const insertPrepItems = (data) => {
    if (data[0]?.uuid_group) {
        return data.map((item) => item.item ? item.item : item.prep_item).flat();
    } else {
        return data;
    }
};

export const isProductHaveRewards = (item) => {
    let items = useStoreCartItems.getState().getCartItems();
    items = items.filter((oldItem) => oldItem.promoFrom === item.timestamp);
    return items.length > 0 ? true : false;
};