import React from 'react';
import { Grid, Typography, ButtonBase, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import ReLazyImage from '@components/ReLazyImage';
import { useCartContext } from '@context/CartContext';
import { useTranslation } from 'react-i18next';
import { useGeneralContext } from '@context/GeneralContext';
import { useAuthContext } from '@context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import ReLegend from './ReLegend';
import useWindowSize from 'use-window-size-v2';
import { getImageThumnailGrid } from '@functions/imageOptimization';
import { isProductActive } from '@functions/checkProductActive';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
import { useStoreNutriGrade } from '@state/useStoreNutriGrade';
// NUMBER OF COLUMN
const cols = 2;

const useStyles = makeStyles({
  panelContainer: {
    width: '100%',
    margin: 0,
    padding: 5,
    borderRadius: 10,
  },
  panelSplitter: {
    width: `calc(100% / ${cols})`,
    padding: 5,
  },
  panelTitle: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(1),
  },
  panelItem: {
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    textAlign: 'left',
  },
  disablePanel: {
    backgroundColor: '#F5F5F5',
    opacity: 0.7,
    filter: 'grayscale(1)',
  },
  borderRadius: {
    borderRadius: 10,
  },
  paddingText: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  positionNutriGrade: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(2),
  },
});

const PanelComponent = ({
  index,
  data,
  isSuggestionPage,
  width,
  onClickItem,
}) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))
  const { getSetting } = useStoreSettings(state => ({
    getSetting: state.getSetting
  }))
  const {
    getSellBandId,
  } = useStoreOrderCategory(state => ({
    getSellBandId: state.getSellBandId,
  }))
  const { urlNutriGrade } = useStoreNutriGrade(state => ({ urlNutriGrade: state.urlNutriGrade }))
  
  return (
    <Box
      boxShadow={3}
      className={classes.borderRadius}
      sx={{
        opacity: isProductActive(data) ? 1 : 0.7, 
        width: "100%",
        height: "100%",
        borderRadius: "1rem",
      }}>
      <ButtonBase
        id={`item-${index}`}
        disabled={!isProductActive(data)}
        focusRipple
        sx={{ width: '100%', height: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          disabled={!isProductActive(data)}
          onClick={() => {
            onClickItem(data);
          }}
          className={`${classes.panelItem} ${!isProductActive(data) && classes.disablePanel
            }`}
          sx={{
            background: appColor.backgroundProduct,
            borderRadius: "1rem",
            height: "100%",

          }}>
          <Box display="flex" flexGrow={1} justifyContent="center" position="relative" >
            <ReLazyImage
              url={getImageThumnailGrid(
                data.url,
                width / 2 - 12,
                width / 2 - 12,
              )}
              style={{
                width: width / 2 - 12,
                height: width / 2 - 12,
              }}
              index="product"
            />
            {
              urlNutriGrade(data, "v") && (
                <Box className={`${classes.positionNutriGrade} nutri-grade`} >
                  <ReLazyImage
                    url={urlNutriGrade(data, "v")}
                    style={{ width: 40, }}
                    index="nutri-grade-product"
                  />
                </Box>
              )
            }
          </Box>
          <Box
            className={classes.paddingText}
            display="flex"
            flexDirection="column"
            width={1}>
            <Typography
              className="clampOnLine2"
              variant="body1"
              sx={{
                height: 50,
                textAlign: 'left',
                lineHeight: 1,
                marginTop: theme.spacing(2),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                letterSpacing: 0
              }}>
              {data.plu_name}
            </Typography>
            <Box display="flex" flexDirection="row">
              <Box
                component="p"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize={12}
                whiteSpace="noWrap">
                <b>
                  {data.price
                    ? data.price
                    : getSetting() && getSetting().soldOutText
                      ? getSetting().soldOutText
                      : t('outOfStock')}
                </b>
              </Box>
              <Box display="flex" flexDirection="row-reverse" flex="1">
                {data.legend && <ReLegend legend={data.legend} type="grid" />}
              </Box>
            </Box>
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
};

const ReGridviewPanel = ({ dataSet = {}, isSuggestionPage = false }) => {
  const classes = useStyles();
  const { addToCart, setCartModal } = useCartContext();
  const { openSuccessSnackBar, getSellBandId } = useGeneralContext();
  const { getSetting } = useAuthContext();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const handleClickItem = (data) => {
    data.group = [];
    if (
      getSetting() &&
      getSetting().isDirectOrder &&
      !data.is_variant &&
      data.modifier_group.length < 1 &&
      data.group.length < 1 &&
      !data.is_have_group
    ) {
      data.timestamp = new Date().getTime();
      data.quantity = 1;

      let newData = { ...data, isSuggestionPage: isSuggestionPage };
      addToCart(newData);
      openSuccessSnackBar(`1 x "${data.plu_name}" ${t('hasBeenAddedToCart')}`);
    } else {
      let newData = { ...data, isSuggestionPage: isSuggestionPage };
      setCartModal(true, newData);
    }
  };

  return (
    <Grid
      id={dataSet.category_name.replace(/ /g, '')}
      container
      direction="column"
      key={dataSet.uuid_product_category}
    >
      <Grid item className={classes.panelTitle}>
        <Typography variant="h6" id={dataSet.uuid_product_category}> &#8226; {dataSet.category_name}</Typography>
      </Grid>
      <Grid key={dataSet.uuid_product_category + "gridContainer"} container direction="row" className={`${classes.panelContainer} gridContainer`} >
        {Array.isArray(dataSet.products) &&
          dataSet.products.map((data) =>
            <Grid
              item
              key={data.uuid_product + "productItem"}
              className={`${classes.panelSplitter} productItem`}

            >
              <PanelComponent
                index={data.uuid_product}
                classes={classes}
                t={t}
                getSellBandId={getSellBandId}
                getSetting={getSetting}
                data={data}
                isSuggestionPage={isSuggestionPage}
                width={
                  width > theme.containerWidth ? theme.containerWidth : width
                }
                onClickItem={(data, type) =>
                  handleClickItem({ ...data, signature_token: uuidv4() })
                }
              />
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};

export default ReGridviewPanel;
