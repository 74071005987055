import {
  Box,
  TextField,
  Typography,
  Rating
} from '@mui/material';
import { makeStyles, withStyles } from "@mui/styles"
import { getColorApp } from '@data/localStorageGetter';
import React from 'react';
import theme from '@styles/theme';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles({
  padding1: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  padding2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: 60,
    padding: 0,
    marginLeft: 10,
    '& fieldset': {
      border: 'none', // Hides the border
      borderBottom: '2px solid #ccc', // Adds bottom border
      borderRadius: 0
    },
    '&:hover fieldset': {
      border: 'none', // Hides the border
      borderBottom: '2px solid  black', // Adds bottom border
      borderRadius: 0
    },
    '&.Mui-focused fieldset': {
      borderBottom: '2px solid  black', // Hides the border when focused
      borderRadius: 0
    },
  },
});

const InputRating = (props) => {
  const classes = useStyles();
  return (
    <Box component="fieldset" borderColor="transparent">
      <Box display="flex" flexDirection="row">
        <Typography
          color="error"
          sx={{ visibility: props.data.is_required ? 'visible' : 'hidden', width: theme.spacing(2) }}>
          *
        </Typography>
        <Typography component="legend" sx={{ color: getColorApp().primaryFontColor }}>
          {props.data.label}
        </Typography>
      </Box>

      {props.data.type === 'text' ? (
        <CustomTextField
          multiline
          maxRows={2}
          value={props.data.value}
          className={`rating-${props.index}`}
          onChange={(event) =>
            props.onChange({ ...props.data, value: event.target.value })
          }
          classes={{ root: classes.root }}
          sx={{
            minWidth: '100%',
            min: 0,
            margin: 0,
            textAlign: 'center',
          }}
        />
      ) : (
        <Rating
          name={props.data.label}
          value={parseInt(props.data.value)}
          precision={1}
          className={`${classes.padding1} rating-${props.index}`}
          onChange={(event, newValue) => {
            props.onChange({
              ...props.data, value: newValue !== null && newValue.toString()
            });
          }}
        />
      )}
    </Box>
  );
};

export default InputRating;
