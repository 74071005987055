import {
    Dialog,
    DialogTitle,
    Button,
    DialogContent,
    Grid,
}
    from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAuthContext } from '@context/AuthContext';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { WarningRounded } from '@mui/icons-material';
import theme from '@styles/theme';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    btn: {
        width: '100%',
        marginTop: 8,
        height: 48,
        fontSize: 16, 
    },
    paper: {
        width: '95%',
        margin: 0
    }
});

const AlertProductDialog = (props) => {
    const { onClickTabCategory } = useAuthContext();
    const { appColor } = useStoreAppColor(state => ({
        appColor: state.appColor
    }))
    const classes = useStyles(appColor);
    const { t } = useTranslation();

    const reloadPage = () => {
        setTimeout(() => {
            props.close()
            onClickTabCategory(props.data);
        }, 1000);
    }

    return (
        <Dialog
            open={props.open}
            classes={{
                paper: `${classes.paper}`
            }}>
            <DialogTitle style={{ textAlign: 'center', paddingBottom: 0 }}>
                <WarningRounded
                    style={{ color: appColor.primaryThemeColor, fontSize: '4.5rem' }}
                />
            </DialogTitle>
            <DialogContent style={{paddingBottom: theme.spacing(6)}}>
                <Grid container direction="row" style={{ textAlign: 'center' }}>
                    <Grid item style={{ width: '100%', textAlign: 'center', marginBottom: theme.spacing(4), fontSize: 17 }}>
                        <Trans i18nKey="reloadProduct"> </Trans>
                    </Grid>
                    <Grid item style={{ width: '100%', marginBottom: theme.spacing(2) }}>
                        <Button
                            onClick={reloadPage}
                            className={classes.btn}
                            style={{
                                backgroundColor: appColor.primaryThemeColor,
                                color: appColor.secondaryFontColor,
                                "&:hover": {
                                    background: appColor.primaryThemeColor,
                                }
                            }}
                            variant="contained">
                            Reload
                        </Button>
                    </Grid>
                    <Grid item style={{ width: '100%' }}>
                        <Button
                            onClick={() => {
                                window.top.close();
                            }}
                            className={classes.btn}
                            style={{
                                backgroundColor: appColor.primaryThemeColor,
                                color: appColor.secondaryFontColor,
                                "&:hover": {
                                    background: appColor.primaryThemeColor,
                                }
                            }}
                            variant="contained">
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default AlertProductDialog;
