import { useStoreOutlet } from '@state/useStoreOutlet';
import { getPriceBySellBand } from "@functions/getItemPriceBySellBand";
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
export function convertCurrency(number, withCurrency = true) {
  const { outlet } = useStoreOutlet.getState()

  let param = {
    style: 'currency',
    currency: outlet.currency,
  }
  if (outlet.localeCode === 'id-ID') {
    param.minimumFractionDigits = 0;
    param.maximumFractionDigits = 2;
  }
  if (outlet.currency === "pgk" && !withCurrency) delete param.style;
  let result = new Intl.NumberFormat(outlet.localeCode, param)
    .format(outlet.localeCode === 'id-ID' ? Math.round(number) : number);

  if (outlet.localeCode === 'id-ID') {
    let split = result.split(',');
    result = split[0];
  }

  if (!withCurrency) {
    result = result.replace(outlet.currencySymbol, '').trim();
  }

  return result;
}


// export const detectItemPrice = (data) => {
//   const { getSellBandId } = useStoreOrderCategory.getState()
//   console.log(getSellBandId())
//   return convertCurrency(
//     getPriceBySellBand(data, getSellBandId(), true),
//   )
// }