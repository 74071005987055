import { create } from "zustand"
import { getProductData, getFlatProductsData } from "@data/localStorageGetter";
// import { detectItemPrice } from "@functions/convertNumber";
import { saveFlatProductData } from "@data/localStorageSetter";


export const useStoreProduct = create((set, get) => ({
    products: getProductData() || '',
    flatProducts: getFlatProductsData() || {},
    kodeProd: '001',
    updateProducts: (newCategories) => {
        let index = 0;
        const categorizedProducts = newCategories.map((category) => {
            if (Array.isArray(category.categories) && category.categories.length > 0) {
                return {
                    ...category,
                    categories: category.categories.map((subCategory) => ({
                        ...subCategory,
                        index: index++
                    }))
                };
            } else {
                return {
                    ...category,
                    index: index++
                };
            }
        });
        set({ products: JSON.stringify(categorizedProducts) })
    },
    setFlatProducts: (products) => {
        let index = 0
        const flatProducts = products.flatMap((category) => {
            if (Array.isArray(category.categories) && category.categories.length > 0) {
                return category.categories.map((subCategory) => ({
                    //! Just for testing, remove later
                    // products: Array(10).fill().flatMap((_, repeatIndex) =>
                    //     subCategory.products.map((subCategoryProduct) => ({
                    //         uuid_product: `${subCategoryProduct.uuid_product}-${repeatIndex}`,
                    //         is_active: subCategoryProduct.is_active,
                    //         // legend: subCategoryProduct.legend,
                    //         plu_name: subCategoryProduct.plu_name,
                    //         // plu_number: subCategoryProduct.plu_number,
                    //         price: detectItemPrice(subCategoryProduct),
                    //         index: index++,
                    //     }))
                    // ),
                    // products: subCategory.products.map((subCategoryProduct) => {
                    //     return {
                    //         uuid_product: subCategoryProduct.uuid_product,
                    //         is_active: subCategoryProduct.is_active,
                    //         legend: subCategoryProduct.legend,
                    //         plu_name: subCategoryProduct.plu_name,
                    //         plu_number: subCategoryProduct.plu_number,
                    //         price: detectItemPrice(subCategoryProduct),
                    //         index: index++,
                    //         url: subCategoryProduct.url,

                    //     }
                    // }),
                    index: index++,
                    menu_type: subCategory.menu_type,
                    category_name: subCategory.category_name,
                    uuid_product_category: subCategory.uuid_product_category,
                    isSubCategory: true
                }));
            } else {
                return {
                    menu_type: category.menu_type,
                    //! Just for testing, remove later
                    // products: Array(10).fill().flatMap((_, repeatIndex) =>
                    //     category.products.map((categoryProduct) => ({
                    //         uuid_product: `${categoryProduct.uuid_product}-${repeatIndex}`,
                    //         is_active: categoryProduct.is_active,
                    //         // legend: categoryProduct.legend,
                    //         plu_name: categoryProduct.plu_name,
                    //         price: detectItemPrice(categoryProduct),
                    //         // plu_number: categoryProduct.plu_number,
                    //         index: index++,
                    //     }))
                    // ),
                    // products: category.products.map((categoryProduct) => {
                    //     return {
                    //         uuid_product: categoryProduct.uuid_product,
                    //         is_active: categoryProduct.is_active,
                    //         legend: categoryProduct.legend,
                    //         plu_name: categoryProduct.plu_name,
                    //         price: detectItemPrice(categoryProduct),
                    //         plu_number: categoryProduct.plu_number,
                    //         index: index++,
                    //         url: categoryProduct.url,

                    //     }
                    // }),
                    index: index++,
                    category_name: category.category_name,
                    uuid_product_category: category.uuid_product_category,
                    isSubCategory: false
                };
            }
        });
        saveFlatProductData(flatProducts)
        set({ flatProducts: flatProducts })
    },
    getProductList: () => {
        return JSON.parse(get().products)
    },
    getCategoryList: () => {
        const products = JSON.parse(get().products)
        let data =
            Array.isArray(products) &&
            products.map((newData) => {
                return { ...newData, isShow: false };
            });

        return data;
    }
}))