import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames";

export const useStoreViewBill = create(persist((set) => ({
    viewBillId: null,
    isViewBill: true,
    updateViewBillId: (newViewBill) => {
        set({ viewBillId: newViewBill })
    },
    updateIsViewBill: (newBill) => {
        set({ isViewBill: newBill })
    },
    removeViewBillId: () => {
        set({ viewBillId: null })
    },
    removeIsViewBill: () => {
        set({ isViewBill: false })
    }
}),
    {
        name: localStorageNames.VIEW_BILL
    }
))