import { create } from "zustand"
import { persist } from "zustand/middleware"

import localStorageNames from "@data/localStorageNames";


export const useStoreLogo = create(persist((set) => ({
    logoUrl: "",
    updateLogoUrl: (newUrl) => {
        set({ logoUrl: newUrl })
    }
}),
    {
        name: localStorageNames.LOGO
    }
))