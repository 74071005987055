import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
import URLS from '@/URLS';
import { useStoreSettings } from '@state/useStoreSettings';
import { useEffect } from 'react';
import { useStoreOrderType } from '@state/useStoreOrderType';
import useCheckOpenCloseStore from './useCheckOpenCloseStore';
export default function useCheckSalesType() {
  const { updateOrderCategory } = useStoreOrderCategory((state) => ({
    updateOrderCategory: state.updateOrderCategory,
  }));
  const { outlet, getSalesType, getActiveSalesType, updateOutlet } =
    useStoreOutlet((state) => ({
      outlet: state.outlet,
      getSalesType: state.getSalesType,
      getActiveSalesType: state.getActiveSalesType,
      updateOutlet: state.updateOutlet,
    }));

  const { getSetting } = useStoreSettings((state) => ({
    getSetting: state.getSetting,
  }));

  const { order_type } = useStoreOrderType((state) => ({
    order_type: state.order_type,
  }));
  const [, checkOpenCLose] = useCheckOpenCloseStore();

  const checkSalesType = () => {
    let constructOutlet;
    if (outlet && outlet.status && outlet.status === 'closed') {
      constructOutlet = {
        out_operational_time_text: outlet.out_operational_time_text,
        status: outlet.status,
      };
    } else {
      const listsalestype = {
        active: order_type?.result?.order_types.length,
        sales: order_type?.result?.order_types,
      };
      constructOutlet = {
        outletId: outlet.outletId,
        openingHours: outlet.openingHours,
        outletName: outlet.outletName,
        tableId: outlet.tableId,
        tableName: outlet.tableName,
        salestype: listsalestype,
        currency: outlet.currency,
        currencySymbol: outlet.currencySymbol,
        localeCode: outlet.localeCode,
        out_operational_time_text: outlet.out_operational_time_text,
        status: outlet.status,
      };
    }

    const orderType = outlet.tableId
      ? getSetting().qrTableOrderType
      : getSetting().qrStoreOrderType;
    const salestype = orderType
      ? outlet.salestype.sales.filter((e) => e.type_sales_uuid === orderType)
      : orderType;
    if (
      salestype &&
      getSetting().orderMethod !== 'quick_order' &&
      getSetting().useSiccMember
    ) {
      if (salestype[0].disabled) {
        return URLS.OUTLET_CLOSE;
      } else {
        constructOutlet.salestype.sales = salestype;
        updateOutlet(constructOutlet);
        updateOrderCategory(salestype[0]);
        if (getSetting().isDisplayCategory) {
          return URLS.CATEGORY;
        } else {
          return URLS.MENU_CATALOGUE;
        }
      }
    } else {
      if (getSalesType().active === 1) {
        let localType = getActiveSalesType();
        const check = checkOpenCLose(localType.currentOpen);
        console.log(check);
        if (!check) {
          return URLS.OUTLET_CLOSE;
        } else {
          // KARENA IKON BELUM PERLU UNTUK DISIMPAN
          delete localType.icon;
          updateOrderCategory(localType);
          if (getSetting().isDisplayCategory) {
            return URLS.CATEGORY;
          } else {
            return URLS.MENU_CATALOGUE;
          }
        }
      } else {
        return URLS.ORDER_CATEGORY;
      }
    }
  };

  // useEffect(() => {
  //     checkSalesType()
  // }, [outlet])

  return checkSalesType;
}
