import { create } from "zustand"
import * as Realm from 'realm-web';
import getDiffDate from '@functions/getDiffDate';
import { useStoreOrderCategory } from "./useStoreOrderCategory";

const REALM_APP_ID = import.meta.env[`VITE_REACT_APP_REALM_CONFIGURATION_${import.meta.env.MODE}`];
const realmApp = new Realm.App({ id: REALM_APP_ID });
import moment from 'moment-timezone';
import { getCredsFromStorage } from "@data/localStorageGetter";


const getSearchParam = window.location.href.split('?')[1]
const getTokenFromURL = () => {
    if (getSearchParam) {
        const newUrl = getSearchParam.replace(/&amp;/g, '&');

        const urlToken = new URLSearchParams(`?${newUrl}`).get('token');
        const urlUUID = new URLSearchParams(`?${newUrl}`).get('uuid');
        const urlTable = new URLSearchParams(`?${newUrl}`).get('table');
        const urlSicc = new URLSearchParams(`?${newUrl}`).get('sicc');
        const urlMiniappCHeck = new URLSearchParams(`?${newUrl}`).get('miniapp');
        if (urlToken && urlUUID) {
            let loginStamp = moment(new Date()).format('x');
            const result = {
                'login-stamp': loginStamp,
                token: urlToken,
                uuid: urlUUID || urlTable,
                sicc: urlSicc,
                miniapp: Boolean(urlMiniappCHeck)
            };

            return result
        }
    }
}

export const useStoreFirstCredential = create((set, get) => ({
    credentials: getTokenFromURL() || getCredsFromStorage() || {},
    updateCredentials: (newCreds) => {
        set({ credentials: newCreds })
    },
    getCredentials: () => {
        return get().credentials
    },
    checkValidCredentials: () => {
        let validCred = {};
        let credential = get().credentials
        if (
            credential.token !== null &&
            credential.token !== '' &&
            credential.uuid !== null &&
            credential.uuid !== ''
        ) {
            validCred = credential;
        } else {
            if (import.meta.env.MODE !== 'PRODUCTION') {
                console.log('Dont have credential');
            }
        }
        return validCred
    },
    checkTokenIsExpired: () => {
        let credential = get().credentials
        let diffMinutes = getDiffDate(+credential['login-stamp']);
        // TOKEN EXPIRED FRONTEND DISET 30 MENIT, KETIKA LEBIH DARI ITU, HARUS CLEAR STORAGE
        if (diffMinutes > 30) {
            return true;
        }
        return false;
    },
    getUserLogin: async () => {
        if (getSearchParam) {
            const credentials = Realm.Credentials.function(get().credentials);
            return await realmApp.logIn(credentials);
        } else {
            let user = realmApp.currentUser;
            if (user) {
                return user
            } else {
                const credentials = Realm.Credentials.function(get().credentials);
                return await realmApp.logIn(credentials);
            }
        }

    },
    isLoggedIn: () => {
        if (get().credentials && useStoreOrderCategory.getState().orderCategory) {
            return true
        } else {
            return false
        }

    }
})

)