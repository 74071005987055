import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Divider,
  Grid,
  Typography,
  Box,
  Avatar,
  ButtonBase,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReHashLinkScroll from './ReHashLinkScroll';
import theme from '@styles/theme';
import { getImageAvatar } from '@functions/imageOptimization';
import { useAuthContext } from '@context/AuthContext';
import { getColorApp } from '@data/localStorageGetter';
import { useStoreTabData } from '@state/useStoreTabData';
import { checkTabActive } from '@functions/checkTabActive';
import { useStoreCategory } from '@state/useStoreCategory';
import { useStoreAppColor } from '@state/useStoreAppColor';

const useStyles = makeStyles({
  rootTabs: {
    minHeight: 30,
    backgroundColor: 'white',
  },
  rootTabItem: {
    minHeight: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
  },
  indicator: {
    backgroundColor: getColorApp().primaryThemeColor,
  },
  activeStyle: {
    '& .active': {
      color: getColorApp().secondaryThemeColor,
    },
  },
  selectedColor: {
    color: getColorApp().primaryThemeColor,
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const TabList = ({ dataSet = [], handleClickList }) => {
  const { getAuthState } = useAuthContext();
  const classes = useStyles();

  const { tabData } = useStoreTabData(state => ({
    tabData: state.flatTabData
  }))

  const {appColor} = useStoreAppColor(state => ({
    appColor: state.appColor
  }))

  const {activeSection, setActiveSection} = useStoreCategory(state => ({
    activeSection: state.activeSection,
    setActiveSection: state.setActiveSection
  }))
  // const [newData, setNewData] = useState([]);

  // const onSelectedActive = (data, index) => {
  //   handleClickList(data, index);
  // };

  const onClickActive = (value) => {
    setActiveSection(value)
  }

  // useEffect(() => {
  //   setNewData(dataSet);
  // }, [dataSet]);

  return (
    <Box boxShadow={2} idt="tablist">
      <Tabs
        className={classes.rootTabs}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: getColorApp().primaryThemeColor,
            height: 5, // Set your desired height
          }
        }}
        value={activeSection}
        variant="scrollable"
        scrollButtons={false}>
        {Array.isArray(tabData) &&
          tabData.map((data, index) => (
            <ButtonBase
              key={index + data.category_name}
              sx={
               {
                '&:hover': {
                  cursor: 'pointer',
                },
                color: index === activeSection
                  ? appColor.secondaryThemeColor
                  : 'inherit',  // Default color or undefined
               }
              }>
              {index !== 0 && <Divider orientation="vertical" flexItem />}
                <Grid className={classes.rootTabItem} onClick={() => onClickActive(index)}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignContent="center"
                    justifyContent="center">
                    <Avatar
                      alt="O"
                      src={getImageAvatar(data.url)}
                      variant="square"
                      className={classes.avatar}
                    />
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      sx={data?.uuid_product_category ===
                        getAuthState('tabCategory')?.category?.uuid_product_category
                        ? { marginBottom: theme.spacing(1), color: getColorApp().primaryThemeColor, }
                        : undefined}>
                      <Typography variant="body2">
                        {data.category_name}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
            </ButtonBase>
          ))}
      </Tabs>
    </Box>
  );
};

export default TabList;
