import { useHistory } from 'react-router-dom';
import URLS from '@/URLS';
import {
  failGetConfig,
  sendPostMessageToParent,
} from '@functions/trafficController';
import useRealmFetch from '@hooks/SplashScreen/useRealmFetch';
import { useEffect } from 'react';
import useSetupApp from '@hooks/config/useSetupApp';
import useConfigRedirect from '@hooks/useConfigRedirect';
import { useStoreAppData } from '@state/useGlobalState';
import { useStoreSiccMember } from '@state/useStoreSiccMember';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';
import useCheckValidStore from './useCheckValidStore';
import { useStoreOutlet } from '@state/useStoreOutlet';

export default function useFetchData(theme, setConfig) {
  const { credentials } = useStoreFirstCredential((state) => ({
    credentials: state.credentials,
  }));

  const { updateOutlet } = useStoreOutlet((state) => ({
    updateOutlet: state.updateOutlet,
  }));

  const realmConfig = useRealmFetch();

  const history = useHistory();
  const { updateAppData } = useStoreAppData((state) => ({
    updateAppData: state.updateAppData,
  }));
  // inisiasi setupapp untuk menyimpan config
  // didalam state dan localStorage
  useSetupApp();
  ///hook check valid store
  const checkValidStore = useCheckValidStore();
  // Untuk mengambil rute Redirect
  const redirectUrl = useConfigRedirect(checkValidStore, credentials);
  const { updateSiccMember } = useStoreSiccMember((state) => ({
    updateSiccMember: state.updateSiccMember,
  }));
  const directPage = async () => {
    if (
      realmConfig?.product &&
      Array.isArray(realmConfig?.product) &&
      realmConfig.product?.length > 0
    ) {
      //fast solution penambahan settimeout
      if (redirectUrl) {
        setTimeout(() => {
          sendPostMessageToParent('path', redirectUrl);
          history.replace(redirectUrl);
        }, 2000);
      }
    } else {
      updateOutlet(realmConfig);
      const failConfig = await failGetConfig(realmConfig);
      if (failConfig) {
        sendPostMessageToParent('path', failConfig);
        history.replace(failConfig);
      }
    }
  };

  const nextSplahscreen = () => {
    let validTable = true;
    //custom gak ada tetep jalan normal
    ///custom off cek tablename ada gak, gak ada error, ada lanjut
    ///qrstore gak perlu pengecekan custom table
    if (realmConfig.id) {
      if (
        realmConfig.config.custom_table &&
        realmConfig.config.custom_table === 'off' &&
        !realmConfig.config.tableName
      ) {
        validTable = false;
      }
      if (realmConfig.config.use_sicc_member)
        updateSiccMember(credentials.sicc);
      if (realmConfig.config.use_sicc_member && !realmConfig.config?.tableID)
        validTable = true;
      if (realmConfig.config.use_sicc_member && !credentials.sicc) {
        sendPostMessageToParent('path', URLS.CANNOT_SICC);
        history.replace(URLS.CANNOT_SICC);
      } else {
        if (!validTable) {
          sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
          history.replace(URLS.CONNECTION_FAILURE);
        }
        if (realmConfig.config.maintenance_mode) {
          if (realmConfig.config.order_method === 'payment_order') {
            sendPostMessageToParent('path', URLS.CANNOT_PAYMENT);
            history.replace(URLS.CANNOT_PAYMENT);
          } else {
            directPage();
          }
        } else {
          directPage();
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // Digunakan untuk mencegah error ketika pertama kali running
      // Gunakan untuk mencegah fetchdata berjalan jika realmConfig masih null/undefined
      // Mencegah terjadiinya error pada konsol akibat realmConfig null/undefined
      if (!realmConfig) {
        // Jika realmConfig belum terisi return
        // jika realmConfig sudah terisi kodingan ini akan dihiraukan
        return;
      }

      if (!realmConfig.id) {
        updateOutlet(realmConfig);
        const failConfig = await failGetConfig(realmConfig);
        if (failConfig) {
          sendPostMessageToParent('path', failConfig);
          history.replace(failConfig);
        }
      }

      theme(realmConfig.config);
      setConfig(realmConfig.config);
      updateAppData(realmConfig);
    };

    fetchData();
  }, [realmConfig, redirectUrl]);

  return [realmConfig, nextSplahscreen, credentials];
}
