import defaultSplash from '@assets/images/default_splash.png';
import defaultLogo from '@assets/images/default_logo.png';
import { useStoreSplashImage } from '@state/useStoreSplashImage';
import { useStoreLogo } from '@state/useStoreLogo';

const { storeSplashImage: getSplashName } = useStoreSplashImage.getState()
const { logoUrl: getLogoName } = useStoreLogo.getState()

export const getSplashUrlPath = () => {
  return getSplashName;
};

export const getLogoUrlPath = () => {
  return getLogoName;
};

export const getDefaultSplash = () => {
  return defaultSplash;
};

export const getDefaultLogo = () => {
  return defaultLogo;
};