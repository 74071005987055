import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import theme from '@styles/theme';
import { styled } from '@mui/material/styles';
import icon from '@assets/images/icon_full.svg';

const LegendTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
}));

const useStyles = makeStyles({
  boxLegendGrid: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: '4px',  // Add consistent spacing between icons
  },
  boxLegendList: {
    position: 'absolute',  // Changed to absolute positioning
    right: theme.spacing(2),
    bottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: '4px',  // Add consistent spacing between icons
  },
  legendIcon: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '24px',  // Consistent height container
  },
  halal: {
    background: `url(${icon}) 0 0`,
  },
  beef: {
    background: `url(${icon}) -20px 0`,
  },
  chicken: {
    background: `url(${icon}) -60px 0`,
  },
  pork: {
    background: `url(${icon}) -80px 0`,
  },
  fish: {
    background: `url(${icon}) -120px 0`,
  },
  thumbs_up_like: {
    background: `url(${icon}) -140px 0`,
  },
  spicy: {
    background: `url(${icon}) -100px 0`,
  },
  chef_recommended: {
    background: `url(${icon}) -160px 0`,
  },
  vegetarian: {
    background: `url(${icon}) -180px 0`,
  },
  lamb: {
    background: `url(${icon}) -40px 0`,
  },
  nutri_grade_a: {
    background: `url(${icon}) -200px 0`,
    scale: '1.5',
    marginRight: '6px',
    marginLeft: '5px',
  },
  nutri_grade_b: {
    background: `url(${icon}) -220px 0`,
    scale: '1.5',
    marginRight: '6px',
    marginLeft: '5px',
  },
  nutri_grade_c: {
    background: `url(${icon}) -240px 0`,
    scale: '1.5',
    marginRight: '6px',
    marginLeft: '5px',
  },
  nutri_grade_d: {
    background: `url(${icon}) -260px 0`,
    scale: '1.5',
    marginRight: '6px',
    marginLeft: '5px',
  },
  gluten: {
    background: `url(${icon}) -280px 0`,
  },
  vegan: {
    background: `url(${icon}) -300px 0`,
  },
  gluten_free: {
    background: `url(${icon}) -320px 0`,
  },
});

const ReLegend = ({ legend, type }) => {
  const classes = useStyles();

  const getClassName = (icon) => {
    switch (icon) {
      case 'halal':
        return classes.halal;
      case 'beef':
        return classes.beef;
      case 'chicken':
        return classes.chicken;
      case 'pork':
        return classes.pork;
      case 'fish':
        return classes.fish;
      case 'thumbs_up_like':
        return classes.thumbs_up_like;
      case 'spicy':
        return classes.spicy;
      case 'chef_recommended':
        return classes.chef_recommended;
      case 'vegetarian':
        return classes.vegetarian;
      case 'lamb':
        return classes.lamb;
      case 'nutri_grade_a':
        return classes.nutri_grade_a;
      case 'nutri_grade_b':
        return classes.nutri_grade_b;
      case 'nutri_grade_c':
        return classes.nutri_grade_c;
      case 'nutri_grade_d':
        return classes.nutri_grade_d;
      case 'gluten':
        return classes.gluten;
      case 'vegan':
        return classes.vegan;
      case 'gluten_free':
        return classes.gluten_free;
      default:
        return null;
    }
  };

  return (
    <Box
      id={'legend'}
      className={type === 'grid' ? classes.boxLegendGrid : classes.boxLegendList}
    >
      {legend && legend.type === 'icon' ? (
        legend.icon.map((data, index) => (
          <Box
            key={data}
            className={classes.iconWrapper}
          >
            <Box
              className={`${classes.legendIcon} ${getClassName(data)}`}
            />
          </Box>
        ))
      ) : (
        <LegendTypography variant="caption">
          {legend ? legend.text : ''}
        </LegendTypography>
      )}
    </Box>
  );
};

export default ReLegend;
