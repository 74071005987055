import ReGridviewPanel from '@components/ReGridviewPanel';
import ReListviewPanel from '@components/ReListviewPanel';
import React, { memo } from 'react';

const ItemProduct = memo(({ category, theme, index }) => {

  return (
    <section
      name={category.uuid_product_category}
      key={category.uuid_product_category}
      id={category.uuid_product_category}
      style={{ paddingTop: index === 0 ? theme.spacing(2) : theme.spacing(0) }}>
      {category.menu_type === 'grid' ? (
        <ReGridviewPanel dataSet={category} />
      ) : (
        <ReListviewPanel dataSet={category} />
      )}
    </section>
  );
})

export default ItemProduct;
