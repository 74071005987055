import URLS from '@/URLS';

export const failGetConfig = (response) => {
  if (response && response.status === "closed") {
    return URLS.OUTLET_CLOSE;
  }
  return URLS.INVALID_TOKEN;
};


export const sendPostMessageToParent = (key, message) => {
  const data = {
    key: key,
    message: message
  };

  window.parent.postMessage(data, "*");
  // window.parent.postMessage(data, import.meta.env[`VITE_REACT_APP_DOMAIN_CONTAINER_${import.meta.env.MODE}`]);
};