import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './i18n';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import localStorageNames from '@data/localStorageNames';
import packageJson from '../package.json';
// Create a root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Initial render
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const outletInfo = JSON.parse(localStorage.getItem(localStorageNames.OUTLET));
const title = import.meta.env[`VITE_REACT_APP_TITLE_${import.meta.env.MODE}`];
Sentry.init({
  release: `RDO_${title}_FE_v` + packageJson.version,
  dsn: import.meta.env[`VITE_REACT_APP_KEY_SENTRY_${import.meta.env.MODE}`],
  environment: import.meta.env[`VITE_REACT_APP_SENTRY_ENV_${import.meta.env.MODE}`],
  initialScope: {
    tags: {
      RDO_CUSTOMER: outletInfo?.outletName,
      RDO_VERSION: packageJson.version,
    }
  },
  ignoreErrors: [
    "Unexpected token '<'",
    "Loading chunk ",
    "Failed to fetch",
    "undefined is not an object (evaluating 'a.N')",
    "Load failed",
    "network error",
    "Attempt to use history.replaceState() more than 100 times per 10 seconds",
    "Unable to preload CSS"
  ]
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

