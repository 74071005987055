import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getColorApp } from '@data/localStorageGetter';
import { useStoreAppColor } from '@state/useStoreAppColor';
import theme from "@styles/theme.js"


const useStyles = makeStyles((theme) => ({
  accordionIdle: {
    backgroundColor: getColorApp().backgroundColor,
    color: getColorApp().primaryFontColor,
  },
  accordionActive: {
    backgroundColor: getColorApp().headerAccordion,
    color: getColorApp().secondaryFontColor,
  },
  headingTitle: {
    height: 48,
    minHeight: `48px !important`,
  },
  headingContent: {
    width: '80%',
  },
  textWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  accordionDetail: {
    backgroundColor: getColorApp().backgroundAccordion,
    color: getColorApp().primaryFontColor,
    padding: '4px 8px 8px !important',
  },
}));

const RePackageItem = ({ subProduct, qtyProduct }) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))


  return (
    <Grid sx={{margin:0}}>
      <Accordion className={classes.accordionActive} expanded={true}>
        <AccordionSummary
          classes={{
            root: classes.headingTitle,
            content: classes.headingContent,
          }}
          sx={{
            backgroundColor: appColor.primaryThemeColor,
            color: appColor.secondaryFontColor
          }}>
          <Typography
            id={'packageTitle'}
            variant="subtitle2"
            className={classes.textWrapper}>
            {t('detailPackage')}
          </Typography>
        </AccordionSummary>
        {Array.isArray(subProduct) &&
          subProduct.map((product, index) => (
            <AccordionDetails
              key={product.uuid_product}
              className={classes.accordionDetail}
              sx={{
                padding:1, 
              }}
             >
              <Grid
                id={`package-${index}`}
                sx={{display:"flex", justifyContent:"space-between",padding:0, gap:1}}
              >
                <Grid item>
                  <Typography sx={{ padding:0, lineHeight:1}}
                    id={'title'}
                    variant="caption">{`${product.plu_name}`}</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ padding: 0 }} id={'quantity'} variant="caption">
                    {` x ${product.quantity * qtyProduct}`}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          ))}
      </Accordion>
    </Grid>
  );
};

export default RePackageItem;
