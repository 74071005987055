import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeft, Refresh, HistoryRounded } from '@mui/icons-material';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Box,
  ButtonBase,
  Typography,
  Fab,
  Divider,
  Button,
} from '@mui/material';
import { convertCurrency } from '@functions/convertNumber';
import Alert from '@mui/lab/Alert';
import { useTranslation } from 'react-i18next';
import URLS from '@/URLS';
import { getColorApp } from '@data/localStorageGetter';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreProduct } from '@state/useStoreProduct';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreAppColor } from '@state/useStoreAppColor';

import { styled } from '@mui/system';
const ModalOverlay = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1300,
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflowY: 'auto',
  fontSize: 14,
  lineHeight: 1.5,
}));

const useStyles = makeStyles((theme) => ({
  layoutConfirm: {
    width: '100%',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  payment: {
    background: 'white',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    // padding: '0 10px 10px 10px'
  },
  buttonConfirm: {
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    width: '100%',
    color: getColorApp().secondaryThemeColor,
  },
  buttonBase: {
    // left: theme.spacing(3),
  },
  refreshButton: {
    // right: theme.spacing(3),
  },
  calculationText: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.7,
  },
  fab: {
    position: 'absolute !important',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  boxResult: {
    // padding: theme.spacing(3),
    borderRadius: theme.spacing(4),
    position: 'absolute',
    top: '30%',
    opacity: 0.5,
    width: '80%',
    transform: 'translateX(-50%)',
    left: '48%',
    rotate: '345deg',
  },
  boxResultSuccess: {
    border: '2px solid green',
  },
  boxResultFail: {
    border: '2px solid red',
  },
  boxResult1: {
    // padding: theme.spacing(2),
  },
  boxResultSuccess1: {
    border: '5px dotted green',
  },
  boxResultFail1: {
    border: '5px dotted red',
  },
  boxResult2: {
    borderRadius: theme.spacing(3),
    // padding: theme.spacing(4),
    textAlign: 'center',
    fontSize: theme.spacing(6),
    textTransform: 'uppercase',
  },
  boxResultSuccess2: {
    border: '2px solid green',
  },
  boxResultFail2: {
    border: '2px solid red',
  },
  footerBill: {
    textAlign: 'center',
    // paddingTop: theme.spacing(15),
    position: 'relative',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  borderDouble: {
    borderStyle: 'double',
    // paddingTop: 2,
    borderColor: 'black',
    borderRight: 0,
    borderLeft: 0,
  },
}));

// EXPECTED PROPS 30/09/2022
// onBack*
// title*
// billData*
// table*
// outlet*
// salesType*
// disableRefresh*
// onClickRefresh*
// onConfirm*
// loading*
// billDiscount
// billSubTotal
// billGrandTotal
// billTaxes
// warningMessage
// showHistory

const ReBillLayout = (props) => {
  const history = useHistory();
  const { getProductList } = useStoreProduct();
  const { outlet: outletInfo } = useStoreOutlet();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const ref = useRef(null);
  const [heightOrder, setHeightOrder] = useState({});
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));

  const { getSetting } = useStoreSettings();

  const PackageItem = ({ item }) => {
    // Mencari Produknya di product list
    let subProduct = [];

    getProductList().forEach((productList, index) => {
      if (
        Array.isArray(productList.categories) &&
        productList.categories.length > 0
      ) {
        productList.categories.forEach((categories, index) => {
          let productIndex = categories.products.findIndex(
            (product) => product.plu_number === item.plu_number,
          );

          if (productIndex !== -1) {
            subProduct = categories.products[productIndex].sub_product || [];
          }
        });
      } else {
        let productIndex = productList.products.findIndex(
          (product) => product.plu_number === item.plu_number,
        );

        if (productIndex !== -1) {
          const selectedProduct =
            getProductList()[index].products[productIndex];
          subProduct = selectedProduct.sub_product || [];
        }
      }
    });
    if (subProduct.length > 0) {
      return (
        <>
          {subProduct.map((packageItem) => (
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', flex: 0.7 }}>
                <Box sx={{ marginLeft: theme.spacing(3) }}>
                  {`* ${packageItem.quantity * item.quantity} x ${
                    packageItem.plu_name
                  }`}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flex: 0.3 }} />
            </Grid>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  const BillItem = ({ item, index }) => {
    return (
      <>
        <Grid
          item
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
          }}
          className="list-order"
          id={`list-${index}`}>
          <Box sx={{ width: '60%' }}>
            {`${item.quantity} x ${item.plu_name}`}
          </Box>
          <Box sx={{ width: '8%', margin: '0 8px' }}>
            {outletInfo.currencySymbol}
          </Box>
          <Box sx={{ width: '32%', textAlign: 'right' }}>
            {item.amount === 0
              ? '0'
              : convertCurrency(item.quantity * item.amount, false)}
          </Box>
        </Grid>

        {/* PREP ITEM */}
        {Array.isArray(item.prep_items) &&
          item.prep_items.map((prep) => (
            <Grid
              item
              key={prep.plu_number}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}>
              <Box sx={{ width: '60%' }}>
                <Box sx={{ marginLeft: theme.spacing(3) }}>
                  {`+ ${prep.quantity} x ${prep.plu_name}`}
                </Box>
              </Box>
              <Box sx={{ width: '8%', margin: '0 8px' }}>
                {(prep.amount || 0) * (prep.quantity || 0) !== 0
                  ? outletInfo.currencySymbol
                  : ''}
              </Box>
              <Box sx={{ width: '32%', textAlign: 'right' }}>
                {`${
                  (prep.amount || 0) * (prep.quantity || 0) !== 0
                    ? convertCurrency(prep.amount * prep.quantity, false)
                    : ''
                }`}
              </Box>
            </Grid>
          ))}

        {/* PACKAGE */}
        {item.type === 'package' && <PackageItem item={item} />}

        {/* MODIFIER */}
        {Array.isArray(item.note) &&
          item.note.map((note, index) => (
            <Grid
              item
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}>
              <Box sx={{ width: '60%' }}>
                <Box sx={{ marginLeft: theme.spacing(3) }}>{`* ${note}`}</Box>
              </Box>
              <Box sx={{ display: 'flex', flex: 0.3 }} />
            </Grid>
          ))}

        {/* DISCOUNT */}
        {Array.isArray(item.discounts) &&
          item.discounts.map((discount) => (
            <Grid
              item
              key={item.plu_number}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}>
              <Box sx={{ width: '60%' }}>
                <Box sx={{ marginLeft: theme.spacing(3) }}>
                  {`- ${discount.discount_name}`}
                </Box>
              </Box>
              <Box sx={{ width: '8%', margin: '0 8px' }}>
                {outletInfo.currencySymbol}
              </Box>
              <Box sx={{ width: '32%', textAlign: 'right' }}>
                &#40;
                {convertCurrency(discount.amount, false) === '0'
                  ? '0'
                  : convertCurrency(discount.amount, false)}
                &#41;
              </Box>
            </Grid>
          ))}
      </>
    );
  };

  const BillContent = () => (
    // HISTORY transaction_items bentuknya array
    // VIEWBILL bentuknya langsung data
    <>
      {Object.keys(props.billData || []).map((typeSales, index) => (
        <Box id={`typeSales-${typeSales}`} key={index} ref={ref}>
          <Grid item sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            <Box>{props.salesType(typeSales)}</Box>
          </Grid>
          {props.billData[typeSales].transaction_items.map((item, index) => (
            <>
              {Array.isArray(item) ? (
                item.map((data) => <BillItem item={data} index={index} />)
              ) : (
                <BillItem item={item} index={index} />
              )}

              {Array.isArray(item) ? (
                <>
                  <hr style={{ borderTop: '1px dashed black' }} />
                </>
              ) : (
                <>
                  {!props.billData[typeSales].transaction_items[index + 1] && (
                    <hr style={{ borderTop: '1px dashed black' }} />
                  )}
                </>
              )}
            </>
          ))}
        </Box>
      ))}
    </>
  );

  const PositionGenerate = () => {
    if (window.innerHeight <= 640) {
      if (heightOrder <= 110) {
        return 'static';
      }
    } else {
      if (heightOrder > 100) {
        return 'static';
      }
    }
  };

  const BillInformation = () =>
    props.showViewBill ? (
      <Grid
        container
        key={props.outlet}
        sx={{
          display: 'block',
          paddingTop: '1rem',
          position: 'relative',
          minHeight: '100%',
          padding: 2,
        }}>
        {props.loadingUpdate && (
          <div style={{ textAlign: 'center' }}>Loading...</div>
        )}
        {props.warningMessage && (
          <div
            style={{
              width: '100%',
              '& > * + *': { marginTop: theme.spacing(2) },
            }}>
            <Alert severity="warning">{t('transaction_split_info')}</Alert>
          </div>
        )}
        {props.billData && Object.keys(props.billData).length !== 0 ? (
          <>
            <Grid item sx={{ textAlign: 'center' }}>
              <Typography>{props.outlet}</Typography>
            </Grid>
            <Grid item sx={{ textAlign: 'center' }}>
              <Typography>{props.addressStore}</Typography>
            </Grid>
            <hr style={{ borderTop: '1px dashed black' }} />
            <Grid item>
              <Typography>
                {/* TODO:table digannti ordr nnumber custom raptor/rdo*/}
                {getSetting().customTable === 'raptor' ||
                getSetting().customTable === 'rdo'
                  ? `${t('orderNumber')} : ${props.table}`
                  : `${t('table')} : ${props.table}`}
              </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex' }}>
              <Typography sx={{ width: '50%' }}>
                Rcpt# : {props.rcptNo}
              </Typography>
              <Typography sx={{ width: '50%', textAlign: 'right' }}>
                Sales No : {props.salesNo}
              </Typography>
            </Grid>
            <hr style={{ borderTop: '1px dashed black' }} />
            <Grid item>
              <Box>Your orders :</Box>
            </Grid>
            <hr style={{ borderTop: '1px dashed black' }} />

            <BillContent />

            {props.billSurcharge &&
              props.billSurcharge.map((surcharge) => (
                <>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                    }}>
                    <Box sx={{ width: '60%' }}>{surcharge.plu_name}</Box>
                    <Box sx={{ width: '8%', margin: '0 8px' }}>
                      {outletInfo.currencySymbol}
                    </Box>
                    <Box sx={{ width: '32%', textAlign: 'right' }}>
                      {convertCurrency(surcharge.sub_total, false) === '0'
                        ? '0'
                        : convertCurrency(surcharge.sub_total, false)}
                    </Box>
                  </Grid>
                </>
              ))}

            {props.billDiscount &&
              props.billDiscount.map((discount) => (
                <>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                    }}>
                    <Box sx={{ width: '60%' }}>{discount.discount_name}</Box>
                    <Box sx={{ width: '8%', margin: '0 8px' }}>
                      {outletInfo.currencySymbol}
                    </Box>
                    <Box sx={{ width: '32%', textAlign: 'right' }}>
                      &#40;
                      {convertCurrency(discount.amount, false) === '0'
                        ? '0'
                        : convertCurrency(discount.amount, false)}
                      &#41;
                    </Box>
                  </Grid>
                </>
              ))}

            {(props.billSurcharge.length !== 0 ||
              props.billDiscount.length !== 0) && (
              <hr style={{ borderTop: '1px dashed black' }} />
            )}

            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}>
              <Box sx={{ width: '60%' }}>SUBTOTAL</Box>
              <Box sx={{ width: '8%', margin: '0 8px' }}>
                {outletInfo.currencySymbol}
              </Box>
              <Box sx={{ width: '32%', textAlign: 'right' }}>
                {convertCurrency(props.billSubTotal, false) === '0'
                  ? '0'
                  : convertCurrency(props.billSubTotal, false)}
              </Box>
            </Grid>

            {props.billTaxes &&
              props.billTaxes.map(
                (item) =>
                  item.value !== 0 && (
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                      }}>
                      <Box sx={{ width: '60%' }}>{item.title}</Box>
                      <Box sx={{ width: '8%', margin: '0 8px' }}>
                        {outletInfo.currencySymbol}
                      </Box>
                      <Box sx={{ width: '32%', textAlign: 'right' }}>
                        {convertCurrency(item.value, false) === '0'
                          ? '0'
                          : convertCurrency(item.value, false)}
                      </Box>
                    </Grid>
                  ),
              )}

            {
              <>
                <hr style={{ borderTop: '1px dashed black' }} />
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                  }}>
                  <Box sx={{ width: '60%' }}>TOTAL</Box>
                  <Box sx={{ width: '8%', margin: '0 8px' }}>
                    {outletInfo.currencySymbol}
                  </Box>
                  <Box sx={{ width: '32%', textAlign: 'right' }}>
                    {convertCurrency(props.billGrandTotal, false) === '0'
                      ? '0'
                      : convertCurrency(props.billGrandTotal, false)}
                  </Box>
                </Grid>
              </>
            }

            {props.paymentMethod && (
              <>
                <Grid
                  item
                  sx={{ textAlign: 'center', paddingTop: theme.spacing(4) }}>
                  <Typography variant="h6">{props.paymentMethod}</Typography>
                </Grid>
                <Box
                  sx={{ borderTop: '1px dashed black', marginBottom: '2px' }}
                />
                <Box sx={{ borderTop: '1px dashed black' }} />
              </>
            )}

            {props.dateTime && (
              <Grid item sx={{ textAlign: 'center' }}>
                <Typography>{props.dateTime}</Typography>
              </Grid>
            )}

            {getSetting()?.orderMethod === 'payment_order' && (
              <Grid
                className={classes.footerBill}
                sx={{
                  position: PositionGenerate(),
                  paddingTop: 5,
                }}>
                <Typography>This is the Official Receipt</Typography>
                <Typography>Generated by RDO</Typography>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90%', // Full height of the viewport
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '400px',
                  padding: '24px',
                }}>
                {/* Credit card icon */}
                <Fab
                  sx={{
                    marginBottom: '16px',
                    backgroundColor: 'gray.100',
                  }}
                  disabled>
                  <ReceiptLongIcon
                    fontSize="medium"
                    sx={{ color: appColor.primaryThemeColor }}
                  />
                </Fab>

                {/* Title */}
                <Typography
                  variant="h6"
                  sx={{ fontWeight: '600', marginBottom: '8px' }}>
                  {t('noTransaction')}
                </Typography>

                {/* Subtitle */}
                <Typography
                  sx={{
                    color: 'text.secondary',
                    textAlign: 'center',
                    marginBottom: '24px',
                  }}>
                  {t('noTransactionDescription')}
                </Typography>

                {/* Refresh button */}
                <Button
                  onClick={props.onClickRefresh}
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: appColor.primaryThemeColor,
                    borderColor: appColor.primaryThemeColor,
                  }}>
                  <RefreshRoundedIcon
                    sx={{
                      color: 'inherit',
                    }}
                  />
                  {t('refresh')}
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Grid>
    ) : null;

  // const ContentManager = () => {
  //   if (props.showHistory) {
  //     return (

  //     );
  //   }

  //   return <BillInformation />;
  // };

  useEffect(() => {
    if (ref.current) {
      setHeightOrder(ref.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [props, ref]);

  return (
    <ModalOverlay>
      <ModalContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            padding: `10px ${theme.spacing(4)}`,
            height: theme.spacing(13),
          }}>
          <ButtonBase
            onClick={() => {
              if (props.onBack) {
                sendPostMessageToParent('path', props.onBack);
                history.replace(props.onBack);
              } else {
                sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
                history.replace(URLS.MENU_CATALOGUE);
              }
            }}>
            <ChevronLeft style={{ width: 32, height: 32 }} />
          </ButtonBase>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {props.title}
          </Typography>
          {/* Note: Jika  */}
          <ButtonBase
            sx={{
              color: props.disableRefresh
                ? theme.palette.text.disabled
                : 'inherit',
            }}
            onClick={() => {
              props.onClickRefresh();
            }}
            disabled={props.disableRefresh}>
            <Refresh sx={{ width: 32, height: 32 }} />
          </ButtonBase>
        </Box>
        {props.showHistory ? (
          <Box>
            <Fab
              id="gotoHistoryBill"
              sx={{
                backgroundColor: appColor.secondaryThemeColor,
                color: appColor.secondaryFontColor,
              }}
              className={classes.fab}
              onClick={() => {
                sendPostMessageToParent('path', URLS.HISTORY_BILL);
                history.replace(URLS.HISTORY_BILL);
              }}>
              <HistoryRounded />
            </Fab>
          </Box>
        ) : (
          <BillInformation />
        )}
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ReBillLayout;
