import React, { useEffect, useState } from 'react';
import { Grid, Button, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCartContext } from '@context/CartContext';
import theme from '@styles/theme';
import ReDialog from '@components/ReDialog';
import { useRealmContext } from '@context/RealmContext';
import { getColorApp } from '@data/localStorageGetter';
import { useGeneralContext } from '@context/GeneralContext';
import * as Realm from 'realm-web';
import moment from 'moment-timezone';
import { SendSharp } from '@mui/icons-material';
import { useStoreViewBill } from '@state/useStoreViewBill';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useShallow } from 'zustand/react/shallow'
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useAuthContext } from '@context/AuthContext';
import { SEND_EMAIL_ENDPOINT, GET_AUTH_EMAIL_ENDPOINT } from '@context/AuthContext';
import { useStoreCartItems } from '@state/useStoreCartItems';
const useStyles = makeStyles({
  emailReceipt: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    '& .MuiInputLabel-root': {
      color: props => props.primaryFontColor
    },
    '& ::placeholder': {
      color: theme.palette.text.disabled,
    },
    '& label.Mui-focused': {
      color: props => props.primaryFontColor
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: props => props.primaryFontColor
      },
    },
  },
});

const SendEmailDialog = () => {
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))
  const classes = useStyles(appColor);
  const { setAuthState, hitAPI } = useAuthContext()
  const { forceReset, clearOrderCart } = useCartContext();
  const { getDetailTransaction } = useRealmContext();
  const { openErrorSnackBar } = useGeneralContext();
  const [emailReceipt, setEmailReceipt] = useState('');
  const [isErrorTextField, setErrorTextField] = useState(false);

  const { clearCart } = useStoreCartItems(state => ({
    clearCart: state.clearCart
  }))

  const { getSetting } = useStoreSettings(state => ({
    getSetting: state.getSetting
  }))
  const { outlet: outletInfo, getNameSalesType } = useStoreOutlet(state => ({
    outlet: state.outlet,
    getNameSalesType: state.getNameSalesType
  }))
  const { getOrderMethod } = useStoreSettings(state => ({
    getOrderMethod: state.getOrderMethod
  }))
  const { viewBillId: transId } = useStoreViewBill(useShallow(state => ({
    viewBillId: state.viewBillId
  })))

  const closeModal = () => setAuthState({ sendEmailVisible: false })

  const onSendEmail = async (data) => {
    setAuthState({ sendEmailVisible: false })
    let param = {
      email: data.to
    }

    hitAPI(GET_AUTH_EMAIL_ENDPOINT, 'POST', '', param).then((response) => {
      if (!response.error) {
        const param2 = {
          to: data.to,
          from: import.meta.env[`VITE_REACT_APP_USER_EMAIL`],
          subject: 'RDO Receipts',
          path: 'https://email.rdo89.com/rdobody',
          body: {
            key: data.databody._id,
            data: data.databody
          }
        }
        hitAPI(SEND_EMAIL_ENDPOINT, 'POST', '', param2, response.token).then((response2) => {
          console.log(response2);
          //belum handle response
        });
      }
    });
  };
  useEffect(() => {
    clearCart()
  }, [])

  const handleSendEmail = async () => {
    const valid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailReceipt);
    if (valid) {
      setErrorTextField(false);

      let filter = {
        _id: new Realm.BSON.ObjectId(transId),
        table_id: outletInfo.tableId,
        status: "close",
        type_bill: getOrderMethod(),
      };

      const substract = moment().subtract(3, 'h');
      filter['createdAt'] = { $gt: substract.toDate() };
      let queryTrans = await getDetailTransaction(filter);
      queryTrans[0].salestype = getNameSalesType(queryTrans[0].transaction_items[0].sales_type);
      queryTrans[0].createdAt = moment(queryTrans[0].createdAt).format('MM-DD-YYYY HH:mm:ss');
      queryTrans[0].outletInfo = outletInfo;
      queryTrans[0].customTable = getSetting().customTable;

      ///send email
      let dataEmail = {
        to: emailReceipt,
        databody: queryTrans[0],
      }
      await onSendEmail(dataEmail);
      forceReset();
      clearOrderCart();
    } else {
      setErrorTextField(true);
      openErrorSnackBar("Please fill in your email validly");
    }
  };

  return (
    <>
      <ReDialog
        closeModal={closeModal}
        useCloseButton={true}
        title={
          <SendSharp
            style={{ color: theme.palette.success.main, fontSize: '3rem', transform: 'rotate(340deg)', }}
          />
        }
        content={
          <Grid container direction="column" sx={{ textAlign: 'center' }}>
            <Typography variant="h5" sx={{color: "black"}}>
              Enter email address
            </Typography>
            <Grid item style={{ marginTop: theme.spacing(2) }}>
              <TextField
                inputProps={{ maxLength: 36 }}
                className={classes.emailReceipt}
                autoComplete="off"
                id="emailReceipt"
                variant="outlined"
                size="small"
                type="email"
                required={true}
                error={isErrorTextField}
                onChange={(event) => {
                  setEmailReceipt(event.target.value);
                }}
              />
              <Grid item style={{ width: '100%' }}>
                <Button
                  onClick={() => {
                    handleSendEmail();
                  }}
                  style={{ color: getColorApp().secondaryFontColor, backgroundColor: getColorApp().primaryThemeColor, width: '100%', marginTop: 10, }}
                  variant="contained">
                  Send E-Receipt
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default SendEmailDialog;
