import React, { useEffect, useState } from 'react';
import * as Realm from 'realm-web';
import moment from 'moment-timezone';
import { useGeneralContext } from '@context/GeneralContext';
import { useRealmContext } from '@context/RealmContext';
import { useCartContext } from '@context/CartContext';
import { useStoreOrderNumber } from '@state/useStoreOrderNumber';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { standardizeData } from '@libs/standardizeData';
import { useCheckoutState } from '@state/checkout/useCheckoutState';
import { useShallow } from 'zustand/react/shallow';
import useOnlineStatus from '@hooks/useOnlineStatus';
import { useStoreCartItems } from '@state/useStoreCartItems';
import { useTranslation } from 'react-i18next';

function usePrepareBill() {
    const { t } = useTranslation();
    const [error, setError] = useState(null)
    const { settings, getOrderMethod } = useStoreSettings(state => ({
        settings: state.settings,
        getOrderMethod: state.getOrderMethod
    }))

    const hasServiceCharge = settings.hasServiceCharge

    const { getContextState, prepServiceCharge, setContextState } = useCartContext()
    const paymentResId = getContextState('paymentResId')


    const { showLoading, showServiceLoading, openErrorSnackBar } = useGeneralContext();
    const { getDetailTransaction } = useRealmContext();
    const { updateOrderNumber } = useStoreOrderNumber(state => ({
        updateOrderNumber: state.updateOrderNumber
    }))

    const [isOnline,] = useOnlineStatus()

    const {
        cartItems, totalQty
    } = useStoreCartItems(state => ({
        cartItems: state.cartItems,
        totalQty: state.totalQty,
    }))

    const { outlet: outletInfo } = useStoreOutlet(state => ({
        outlet: state.outlet
    }))
    const {
        serviceCharge,
        updateBillTaxes,
        updateBillGrandTotal,
        updateBillSubTotal,
        updateAmountCurrency,
        updateTableName,
        updateShowButtonPay,
        updateServiceCharge,
        updateAmountServiceCharge,
    } = useCheckoutState(useShallow(state => ({
        serviceCharge: state.serviceCharge,
        updateBillTaxes: state.updateBillTaxes,
        updateBillGrandTotal: state.updateBillGrandTotal,
        updateBillSubTotal: state.updateBillSubTotal,
        updateAmountCurrency: state.updateAmountCurrency,
        updateTableName: state.updateTableName,
        updateShowButtonPay: state.updateShowButtonPay,
        updateServiceCharge: state.updateServiceCharge,
        updateAmountServiceCharge: state.updateAmountCurrency,
    })))


  const comparingSubtotal = (fetchSubTotal) => {
    console.log(fetchSubTotal, '"------fetchSubTotal"');
    console.log(cartItems, '------cartItems');
    const transaction_items = [...fetchSubTotal[0].transaction_items];
    const transactionMap = new Map(
      transaction_items.map((item) => [item.plu_number, item.quantity]),
    );
    const isValidItem = cartItems.some((cartItem) => {
      if (cartItem.is_variant) {
        return cartItem.variant_item.some((varItem) => {
          const transactionQty = transactionMap.get(varItem.plu_number);
          return transactionQty === varItem.quantity;
        });
      } else {
        const transactionQty = transactionMap.get(cartItem.plu_number);
        return transactionQty === cartItem.quantity;
      }
    });

    // Jika item tidak valid tampilkan modal warning service charge
    if (!isValidItem) {
      setContextState({
        subtotalWarning: {
          visible: true,
        },
      });
    }
  };

  const prepItemService = async () => {
    if (hasServiceCharge) {
      showServiceLoading(true);
    }

    const serviceCharge = await prepServiceCharge();
    if (!serviceCharge.error) {
      if (hasServiceCharge) {
        showServiceLoading(false);
      }
      updateServiceCharge(serviceCharge);
      updateAmountServiceCharge(serviceCharge.amount);
    }
    setError(serviceCharge);
    if (hasServiceCharge) {
      showServiceLoading(false);
    }
  };

  const prepareBillData = async (transId, miniappReqId) => {
    let orderMethod = getOrderMethod();
    let filter = {};
    filter = {
      _id: new Realm.BSON.ObjectId(transId),
      table_id: outletInfo.tableId,
      status: 'calculated',
      type_bill: orderMethod,
    };

    const substract = moment().subtract(3, 'h');
    filter['createdAt'] = { $gt: substract.toDate() };
    let queryTrans = null;
    let realmData;
    if (miniappReqId && miniappReqId !== 'undefined') {
      queryTrans = await getDetailTransaction(filter);

      //TODO : handle try catch jika get detail trans error
    }

    if (queryTrans && Array.isArray(queryTrans) && queryTrans.length > 0) {
      let result = queryTrans.filter((data) => !data.is_split);
      let standardData = standardizeData(result);
      //TODO: save table no ke localstorage dengan key order nummber hanya untuk custom table raptor/rdo
      if (settings.customTable === 'raptor' || settings.customTable === 'rdo') {
        //TODO ubah saveOrderNumber menjadi state
        updateOrderNumber(queryTrans[0].table_name);
        // saveOrderNumber(queryTrans[0].table_name);
      }

            updateBillTaxes([...standardData.taxes])
            updateBillSubTotal(standardData.sub_total)
            updateBillGrandTotal(standardData.grand_total)
            updateAmountCurrency(outletInfo.currency)
            updateTableName(queryTrans[0].table_name)

            comparingSubtotal(queryTrans)

            if (standardData.grand_total === 0) {
                setContextState({
                    paymentResId: {
                        visible: false,
                    },
                })
                openErrorSnackBar(t('failedTax'));
            }

        } else {
            setContextState({
                paymentResId: {
                    visible: false,
                },
            })
            showLoading(false);
            openErrorSnackBar(t('failedTax'));
        }

    //TODO: Comparing subtotal from realm and servie charge
    comparingSubtotal(queryTrans);

    //TODO : penambahan handle error querytrans null
    let result = queryTrans.filter((data) => !data.is_split);

    // 3. standardize data structure & join multiple data by typesales
    let standardData = standardizeData(result);

    // 4. set state
    updateBillTaxes([...standardData.taxes]);
    updateBillSubTotal(standardData.sub_total);
    updateBillGrandTotal(standardData.grand_total);
    updateAmountCurrency(outletInfo.currency);
    updateTableName(queryTrans[0].table_name);

    showLoading(false);
  };

  useEffect(() => {
    if (settings.hasServiceCharge) {
      prepItemService();
    }
    // eslint-disable-next-line
  }, [isOnline]);

  useEffect(() => {
    if (paymentResId.visible) {
      showLoading(true);
      if (
        paymentResId.trans_id &&
        paymentResId.miniapp_request_id &&
        paymentResId.trans_id !== 'undefined' &&
        paymentResId.miniapp_request_id !== 'undefined'
      ) {
        prepareBillData(paymentResId.trans_id, paymentResId.miniapp_request_id);

        updateShowButtonPay(true);
      } else {
        showLoading(false);
      }
    }

    // eslint-disable-next-line
  }, [paymentResId.visible, isOnline]);
}

export default usePrepareBill;
