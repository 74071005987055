import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { Backdrop, CircularProgress, Modal, Fade, Typography } from '@mui/material';


const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: theme.zIndex.drawer + 2,
        outline: 'none', // Remove default modal outline
    },
    modalContent: {
        position: 'relative',
        width: "50%",
        maxWidth: 500, // Limit the maximum width
        padding: theme.spacing(3),
        backgroundColor: 'white',
        borderRadius: theme.shape.borderRadius, // Add rounded corners
        textAlign: 'center', // Center text content
    },

})

const ServiceChargeLoading = (_props, ref) => {
    const classes = useStyles();
    const [visible, setVisible] = useState(false)
    const show = (flag) => {
        setVisible(flag);
    };

    useImperativeHandle(ref, () => ({ show }));
    return (
        <Modal
            aria-labelledby="service-charge-modal"
            aria-describedby="resolving-service-charge"
            open={ref ? visible : true}
            slots={{ backdrop: Backdrop }}
            className={classes.modal}
        >
            <Fade in={ref ? visible : true}>
                <div className={classes.modalContent}>
                    <Typography style={{ fontWeight: "bold", paddingBottom: 5 }} variant="body2">Loading Bill...</Typography>
                    <CircularProgress color="primary" size={50} />
                </div>
            </Fade>
        </Modal >
    );

};

export default forwardRef(ServiceChargeLoading)
