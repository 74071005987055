import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import theme from '@styles/theme';
import OrderedPackage from './OrderPackage';
import OrderedPrepItem from './OrderPrepItem';
import { useStoreAppColor } from '@state/useStoreAppColor';
import useStyles from './checkout.styles';
import { useTranslation } from 'react-i18next';
import { useCartContext } from '@context/CartContext';
import {
  checkValue,
  checkAdditionalRequest,
  checkPrepGroup,
  checkSubProduct,
  checkPrepService,
  checkSelectTime
} from '@libs/DetailOrder/helper';
import { useCheckoutState } from '@state/checkout/useCheckoutState';
import { useShallow } from 'zustand/react/shallow';
import { getItem } from '@libs/CartItem/getItem';
const DetailOrder = ({
  selectedItem,
  prepGroup = [],
  additionalRequest = [],
  subProduct = [],
  qtyProduct,
  isParentGroup
}) => {
  const { getContextState } = useCartContext()
  const showPayment = getContextState('paymentResId').visible
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor
  }))


  const { t } = useTranslation()
  const classes = useStyles(appColor)

  const { serviceCharge, selectTime, expandModifier, setExpandModifier } = useCheckoutState(useShallow(state => ({
    serviceCharge: state.serviceCharge,
    selectTime: state.selectTime,
    expandModifier: state.expandModifier,
    setExpandModifier: state.setExpandModifier
  })))

  const showDetail = () => {
    setTimeout(() => {
      setExpandModifier(!expandModifier ? true : false);
    }, 400);
  };


  const filteredServiceCharge = serviceCharge.filter(item => {
    if (selectedItem.is_variant) {
      const itemVariant = getItem(selectedItem, "name")
      return item._id === itemVariant.uuid_product
    } else {
      return item._id === selectedItem.uuid_product
    }
  }
  )

  return (
    <Box>
      {checkValue(additionalRequest, subProduct, prepGroup, selectTime, filteredServiceCharge) && !showPayment && (
        <Typography
          id={'detail'}
          component="button"
          variant="caption"
          onClick={() => {
            showDetail();
          }}
          className={`${classes.modifierText} ${classes.expandButtonModifier}`}>
          {expandModifier ? `${t('closeDetail')}` : `${t('showDetail')}...`}
        </Typography>

      )}

      {expandModifier && (
        <>
          {checkPrepGroup(prepGroup) && (
            <OrderedPrepItem
              prepItems={prepGroup}
              qtyProduct={qtyProduct}
            />
          )}

          {checkSubProduct(subProduct) && (
            <OrderedPackage
              subProduct={subProduct}
              qtyProduct={qtyProduct}
            />
          )}

          {checkAdditionalRequest(additionalRequest) && (
            <Grid item sx={{ marginBottom: theme.spacing(2) }}>
              {Array.isArray(additionalRequest) &&
                additionalRequest.map((note, index) => (
                  <Box>
                    <Typography
                      id={`note-${index}`}
                      variant="caption"
                      className={`${classes.modifierText}`}>
                      {`* ${note}`}
                    </Typography>
                  </Box>
                ))}
            </Grid>
          )}
          {checkPrepService(filteredServiceCharge) && (
            <OrderedPrepItem
              prepItems={filteredServiceCharge}
              qtyProduct={qtyProduct}
            />
          )}

          {(checkSelectTime(selectTime) && isParentGroup) && (
            <Grid item sx={{ marginBottom: theme.spacing(2) }}>
              <Box>
                <b>
                  <Typography
                    id={`takeAway-1`}
                    variant="caption"
                    className={`${classes.modifierText}`}>
                    {`* TakeAway ${selectTime}`}
                  </Typography>
                </b>
              </Box>
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default DetailOrder;
