import { useStoreOutlet } from "@state/useStoreOutlet"
import { create } from "zustand"

const initialState = {
    parameter: '',
    isErrorTextField: false,
    billTaxes: [],
    billSubTotal: 0,
    billGrandTotal: 0,
    amountCurrency: '',
    showButtonPay: false,
    tableName: useStoreOutlet.getState().getTableName(),
    serviceCharge: [],
    amountServiceCharge: 0,
    selectTime: '',
    expandModifier: false,
}
  
export const useCheckoutState = create((set) => ({
    //state replace semua
    ...initialState,
    resetCheckOut: () => { set(initialState)},
    updateParameter: (newData) => set({ parameter: newData }),
    updateIsErrorTextField: (newData) => set({ isErrorTextField: newData }),
    updateBillTaxes: (newAppData) => set({ billTaxes: newAppData }),
    updateBillSubTotal: (newAppData) => set({ billSubTotal: newAppData }),
    updateBillGrandTotal: (newAppData) => set({ billGrandTotal: newAppData }),
    updateAmountCurrency: (newAppData) => set({ amountCurrency: newAppData }),
    updateShowButtonPay: (newData) => set({ showButtonPay: newData }),
    updateTableName: (newAppData) => set({ tableName: newAppData }),
    updateServiceCharge: (newData) => set({ serviceCharge: newData }),
    updateAmountServiceCharge: (newData) => set({ amountServiceCharge: newData }),
    updateSelectTime: (newData) => set({ selectTime: newData }),
    removeSelectTime: () => set({ selectTime: '' }),
    removeParameter: () => set({ parameter: '' }),
    setExpandModifier: (newData) => set({ expandModifier: newData }),
    compareSubTotal: (fetchSubTotal) => {
        const getSubtotalState = get().subbillSubTotal
        if (fetchSubTotal !== getSubtotalState) {
            return true
        } else {
            return false
        }
    }
}))















