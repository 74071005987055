import React from 'react';
import { Typography, Box } from '@mui/material';
import theme from '@styles/theme';
import { getPriceBySellBand } from '@functions/getItemPriceBySellBand.js';
import { convertCurrency } from '@functions/convertNumber';
import { useStoreAppColor } from '@state/useStoreAppColor';
import useStyles from './checkout.styles';
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
// import { useStoreSettings } from 'state/useStoreSettings';

const OrderPrepContent = ({ item, qtyProduct }) => {
  const { appColor } = useStoreAppColor()
  const classes = useStyles(appColor)
  const { getSellBandId } = useStoreOrderCategory(state => ({
    getSellBandId: state.getSellBandId
  }))
  return (
    getPriceBySellBand(item, getSellBandId()) !== 0 ? (
      <Typography variant="caption" className={classes.modifierText}>
        <Box component="div" whiteSpace="normal">
          <b>
            +
            {convertCurrency(
              getPriceBySellBand(item, getSellBandId()) *
              (item.quantity > 0 ? item.quantity : 1) *
              (qtyProduct > 1 ? qtyProduct : 1),
            )}
          </b>
        </Box>
      </Typography>
    )
      : item.amount > 0 && (
        <Typography variant="caption" className={classes.modifierText}>
          <Box component="div" whiteSpace="normal">
            <b>
              +
              {convertCurrency(
                item.amount * (item.quantity * qtyProduct),
              )}
            </b>
          </Box>
        </Typography>
      )
  )
}

const OrderedPrepItem = ({
  prepItems,
  qtyProduct,
}) => {
  const { appColor } = useStoreAppColor()
  const classes = useStyles(appColor)

  return prepItems.map((item, index) => (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      sx={{ marginBottom: theme.spacing(1) }}>
      <Box
        id={`title-${index}`}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: 'inherit' }}>
        <Typography
          variant="caption"
          className={`${classes.modifierText} ${classes.textWrapper}`}>
          <Box component="div" whiteSpace="normal">
            {`+ ${item.quantity * qtyProduct} x ${item.plu_name}`}
          </Box>
        </Typography>
      </Box>
      <Box
        id={`nominal-${index}`}
        display="flex"
        alignSelf="center"
        justifyContent="flex-end"
        sx={{ width: 165 }}>
        <OrderPrepContent item={item} qtyProduct={qtyProduct} />
      </Box>
    </Box>
  ));
};

export default OrderedPrepItem;
