import localStorageNames from './localStorageNames';
import { useStoreAppColor } from '@state/useStoreAppColor';
// GET LOCALSTORAGE
export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const getCredsFromStorage = () => {
  const creds = JSON.parse(getLocalStorage(localStorageNames.CREDENTIAL))
  return creds
}

export const getSettingData = () => {
  const settingsData = JSON.parse(getLocalStorage(localStorageNames.SETTING))
  return settingsData.state.settings;
};

export const getOutletData = () => {
  const outletData = getLocalStorage(localStorageNames.OUTLET);
  return outletData.state.outlet
};

export const getSplashData = () => {
  return getLocalStorage(localStorageNames.SPLASH);
};

export const getProductData = () => {
  return getLocalStorage(localStorageNames.PRODUCTS);
};

export const getFlatProductsData = () => {
  const flatFromStorage = JSON.parse(getLocalStorage(localStorageNames.FLAT_PRODUCTS));
  let flatProducts = []
  if (flatFromStorage && flatFromStorage.length !== 0) {
     flatProducts = flatFromStorage.flatMap((category, index) => {
      if (Array.isArray(category.categories) && category.categories.length > 0) {
        return category.categories.map((subCategory, subIndex) => ({
          ...subCategory,
          index: subIndex,
          isSubCategory: true
        }));
      } else {
        return {
          ...category,
          index: index,
          isSubCategory: false
        };
      }
    });
    
  }
  return flatProducts
};

export const getItemsDetailStorage = () => {
  const itemsDetail = JSON.parse(getLocalStorage("itemsDetail"));

  // Ensure that variantItems is an array before proceeding
  if (!Array.isArray(itemsDetail)) {
    return new Map();
  }

  const result = new Map();

  itemsDetail.forEach((item) => {
    item.products.forEach((itemD) => {
      result.set(itemD.uuid_product, itemD.variantItem);
    });
  });

  console.log(result, "result items detail")

  return result;

}


export const getColorApp = () => {
  const themeColor = useStoreAppColor.getState().appColor;

  return themeColor;
};

export const getLogoUrl = (data) => {
  return data || getLocalStorage(localStorageNames.LOGO);
};

export const getSpreadCategories = () => {
  return JSON.parse(getLocalStorage(localStorageNames.SPREAD_CATEGORY));
};

export const getCategoryList = () => {
  const products = getProductData();
  let data =
    Array.isArray(products) &&
    products.map((newData) => {
      return { ...newData, isShow: false };
    });

  return data;
};


// export const getOrderCategory = () => {
//   return JSON.parse(getLocalStorage(localStorageNames.ORDERCATEGORY))
// }