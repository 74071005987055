import moment from 'moment-timezone';
import localStorageNames from './localStorageNames';

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

// export const setupFirstCredential = (tokenData) => {
//   if (tokenData) {
//     // LOGIN TIMESTAMP DIGUNAKAN UNTUK CLEARING STORAGE (EXPIRED TOKEN) KETIKA MELEBIHI KETENTUAN
//     let loginStamp = moment(new Date()).format('x');
//     let data = {
//       'login-stamp': loginStamp,
//       token: tokenData.token,
//       uuid: tokenData.uuid,
//       sicc: tokenData.sicc,
//     };

//     setLocalStorage(localStorageNames.CREDENTIAL, JSON.stringify(data));
//   }
// };

export const saveCredentials = (data) => {
  localStorage.setItem(localStorageNames.CREDENTIAL, JSON.stringify(data));
}
export const saveSplash = (data) => {
  setLocalStorage(localStorageNames.SPLASH, data);
};

export const saveLogo = (data) => {
  setLocalStorage(localStorageNames.LOGO, data);
};

export const saveSettingData = (data) => {
  setLocalStorage(localStorageNames.SETTING, JSON.stringify(data));
};

export const saveOutletData = (data) => {
  setLocalStorage(localStorageNames.OUTLET, JSON.stringify(data));
};

export const saveTypeUser = (data) => {
  setLocalStorage(localStorageNames.USER, JSON.stringify(data));
};

export const saveColorData = (data) => {
  setLocalStorage(localStorageNames.THEME_COLOR, JSON.stringify(data));
};

export const savePaymentMethod = (data) => {
  setLocalStorage(localStorageNames.PAYMENT_METHOD, JSON.stringify(data));
};

export const saveProductData = (data) => {
  setLocalStorage(localStorageNames.PRODUCTS, JSON.stringify(data));
};

export const saveFlatProductData = (data) => {
  setLocalStorage(localStorageNames.FLAT_PRODUCTS, JSON.stringify(data));
};

export const saveSpreadCategoryData = (data) => {
  setLocalStorage(localStorageNames.SPREAD_CATEGORY, JSON.stringify(data));
};

export const saveFeedbackData = (data) => {
  setLocalStorage(localStorageNames.FEEDBACK, JSON.stringify(data));
};

export const saveOrderCategoryData = (data) => {
  setLocalStorage(localStorageNames.ORDERCATEGORY, JSON.stringify(data));
};

export const saveNutriGrade = (data) => {
  setLocalStorage(localStorageNames.NUTRI_GRADE, JSON.stringify(data));
};

export const saveSiccMember = (data) => {
  if (data) setLocalStorage(localStorageNames.SICC_MEMBER, data);
};

export const saveOrderNumber = (data) => {
  if (data) setLocalStorage(localStorageNames.ORDER_NUMBER, data);
};

// CLEAR LOCALSTORAGE
export const clearStorageAfterScan = () => {
  //Clear Storage Terlebih Dahulu
  localStorage.clear()
};

//! Sudah tidak digunakan
export const clearAllStorage = (param) => {
  if (param === 'partial') {
    localStorage.removeItem(localStorageNames.CART_ITEMS);
    localStorage.removeItem(localStorageNames.FILLED_FEEDBACK);
    localStorage.removeItem(localStorageNames.USER);
  } else {
    Object.values(localStorageNames).forEach((LSname) => {
      if (LSname !== localStorageNames.TRANSACTIONS_ID) {
        localStorage.removeItem(LSname);
      }
    });
  }
};


const insertProduct = (category) => {
  let products = [];
  category.products.forEach((product) => {
    let tempProduct = { ...product };
    delete tempProduct.prep_group;
    delete tempProduct.sub_product;
    // delete tempProduct.variant_item;
    products.push(tempProduct);

    setLocalStorage(
      `product.${product.uuid_product}`,
      JSON.stringify({
        modifier_group: product.modifier_group,
        prep_group: product.prep_group,
        sub_product: product.sub_product,
        variant_item: product.variant_item,
      }),
    );
  });

  setLocalStorage(
    `product.${category.uuid_product_category}`,
    JSON.stringify(products),
  );
  console.log('insert ',category.uuid_product_category);
  
};

export const  spreadProductData = (data_product) => {
  data_product = JSON.parse(data_product)
  if (Array.isArray(data_product) && data_product.length > 0) {
    let categories = [];
    data_product.forEach((category) => {
      if (
        Array.isArray(category.categories) &&
        category.categories.length > 0
      ) {
        category.categories.forEach((category2) => {
          categories.push({
            uuid_product_category: category2.uuid_product_category,
            url: category2.url,
            category_name: category2.category_name,
            menu_type: category2.menu_type,
          });
          if (
            Array.isArray(category2.products) &&
            category2.products.length > 0
          ) {
            insertProduct(category2);
          }
        });
      } else {
        categories.push({
          uuid_product_category: category.uuid_product_category,
          url: category.url,
          category_name: category.category_name,
          menu_type: category.menu_type,
        });
        if (Array.isArray(category.products) && category.products.length > 0) {
          insertProduct(category);
        }
      }
    });
  } else {
    console.log("spread_categories false")
  }
};