import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames";


export const useStoreOrderNumber = create(persist((set) => ({
    orderNumber: '',
    updateOrderNumber: (newOrderNumber) => {
        set({ orderNumber: newOrderNumber })
    }
}),
    {
        name: localStorageNames.ORDER_NUMBER
    }
)
)