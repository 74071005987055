import { create } from "zustand"
import { persist } from "zustand/middleware"

import localStorageNames from "@data/localStorageNames";


export const useStoreNutriGrade = create(persist((set, get) => ({
    nutriGrade: '',
    kodeNutri: '006',
    _hasHydratedNutri: false,
    setHasHydratedNutri: (state) => {
        set({  _hasHydratedNutri: state  });
    },
    updateNutriGrade: (newData) => {
        set({ nutriGrade: JSON.stringify(newData) })
    },
    urlNutriGrade: (item, key) => {
        if (get().nutriGrade !== '') {
            const nutri = JSON.parse(get().nutriGrade)
            if (nutri.result && !nutri.error) {
                const data = nutri.result.nutri_grade;
                const numberGrade = data.filter((nutri) => nutri.uuid_product === item.uuid_product);
                if (numberGrade.length !== 0) {
                    const urlBunny = import.meta.env[`VITE_REACT_APP_BUNNYCDN_${import.meta.env.MODE}`];
                    const nutriGrade = `${urlBunny}/nutri-grade/nutri-grade-${key}-${numberGrade[0].nutri_grade}.svg`;
                    return nutriGrade;
                }
            }
        }
        return null;
    }
}),
    {
        name: localStorageNames.NUTRI_GRADE,
        onRehydrateStorage: () => (state) => {
            state.setHasHydratedNutri(true)
        }
    }
))

