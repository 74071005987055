import { insertPrepItems } from "./helper";

export const getItem = (item, key) => {
    let data = item.is_variant
        ? item.variant_item.find((data) => data.checked)
        : item;

    if (data) {

        if (key && key === 'name') {
            return { "plu_name": data.plu_name, "uuid_product": data.uuid_product, "price": data.price };
        }

        if (data.group && data.prep_group && data.group.uuid_group === data.prep_group.uuid_group) {
            // Cek apakah uuid_group sama, jika sama hapus data.group dan data.prep_group
            // Jika tidak di hapus isi dari group data prep_group sama
            // Jadi detail item yang muncul akan double
            delete data.group; // or delete data.prep_group
        }

        if (
            (data.group && data.group.length > 0) ||
            data.additional_request ||
            (data.sub_product && data.sub_product.length > 0)
        ) {
            return insertPrepItems(data.group);
        }

        if (
            (data.prep_group && data.prep_group.length > 0) ||
            data.additional_request ||
            (data.sub_product && data.sub_product.length > 0)
        ) {
            return insertPrepItems(data.prep_group);
        }

    }
};