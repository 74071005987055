import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Box, ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { convertCurrency } from '@functions/convertNumber';
import { useCartContext } from '@context/CartContext';
import { useTranslation } from 'react-i18next';
import { getPriceBySellBand } from '@functions/getItemPriceBySellBand';
import { useGeneralContext } from '@context/GeneralContext';
import { useAuthContext } from '@context/AuthContext';
import { getImageThumnailList } from '@functions/imageOptimization';
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import ReLegend from './ReLegend';
import { v4 as uuidv4 } from 'uuid';
import ReLazyImage from './ReLazyImage';
import { isProductActive } from '@functions/checkProductActive';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreNutriGrade } from '@state/useStoreNutriGrade';
import { useStoreProduct } from '@state/useStoreProduct';
import AlertProductDialog from './AlertProductDialog';
import { spreadProductData } from '@data/localStorageSetter';
import { useStoreItemsDetails } from '@state/useStoreItemsDetails';
import { DateRangePickerDay } from '@mui/lab';
import { useStoreProductCategory } from '@state/useStoreProductCategory';

const useStyles = makeStyles({
  itemHeight: {
    height: 90,
  },
  panelItem: {
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    textAlign: 'left',
    maxHeight: 90,
  },
  itemImage: {
    display: 'inline-block',
    float: 'left',
    width: 120,
  },
  itemInformationWrapper: {
    display: 'inline-block',
    float: 'left',
    width: `calc(100% - 120px)`,
    height: '100%',
  },
  itemInformationWithImage: {
    padding: '5px 8px',
    flex: 1,
  },
  itemInformationNoImage: {
    padding: '5px 8px',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  productName: {
    width: '55%',
    float: 'left',
    lineHeight: 1,
    marginTop: theme.spacing(1),
    minHeight: 30,
  },
  price: {
    width: '45%',
    float: 'left',
    textAlign: 'right',
  },
  menus: {
    borderRadius: 10,
  },
  disabledMenus: {
    opacity: 0.7,
    filter: 'grayscale(1)',
  },
  disablePanel: {
    backgroundColor: '#F5F5F5',
  },
  panelTitle: {
    padding: theme.spacing(2),
  },
  viewList: {
    height: 'auto !important',
  },
});

const PanelComponent = ({
  classes,
  t,
  getSellBandId,
  getSetting,
  data,
  onClickItem,
}) => {
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const { urlNutriGrade } = useStoreNutriGrade((state) => ({
    urlNutriGrade: state.urlNutriGrade,
  }));

  return (
    <Grid
      item
      className={`${classes.panelItem} ${classes.itemHeight} ${
        !isProductActive(data) && classes.disablePanel
      }`}
      sx={{
        background: appColor.backgroundProduct,
      }}
      onClick={() => {
        onClickItem(data);
      }}>
      {data.url && (
        <Box className={classes.itemImage}>
          <ReLazyImage url={getImageThumnailList(data.url)} index="product" />
        </Box>
      )}
      <Box
        className={classes.itemInformationWrapper}
        style={!data.url ? { width: '100%' } : {}}>
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          sx={{ height: '100%' }}>
          <Box
            className={
              data.url
                ? classes.itemInformationWithImage
                : classes.itemInformationNoImage
            }>
            <Typography
              className={`${classes.productName} clampOnLine2`}
              sx={{
                width: data.url ? '55%' : '65%',
                lineHeight: 1,
                marginTop: !data.url ? '10px' : '3px',
                alignItems: 'center',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '2',
              }}
              variant="body1">
              {data.plu_name}
            </Typography>

            <Box
              component="div"
              textOverflow="ellipsis"
              overflow="hidden"
              fontSize={12}
              className={classes.price}
              sx={
                data.url
                  ? { marginTop: theme.spacing(1) }
                  : { whiteSpace: 'nowrap' }
              }
              id="outofstock">
              <b>
                {isProductActive(data)
                  ? convertCurrency(
                      getPriceBySellBand(data, getSellBandId(), true),
                    )
                  : getSetting() && getSetting().soldOutText
                  ? getSetting().soldOutText
                  : t('outOfStock')}
              </b>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="flex-end"
            flexDirection="row"
            flex="1">
            {urlNutriGrade(data, 'h') && (
              <Box
                className={`nutri-grade`}
                sx={{ marginLeft: theme.spacing(3) }}>
                <ReLazyImage
                  url={urlNutriGrade(data, 'h')}
                  style={{ width: 80 }}
                  index="nutri-grade-product"
                />
              </Box>
            )}
            {data.legend && <ReLegend legend={data.legend} type="list" />}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const ReListViewPanelTab = ({ category }) => {
  const classes = useStyles();
  const { addToCart, setCartModal, getBadgeCount, isLoggedIn } =
    useCartContext();
  const { getSellBandId, openSuccessSnackBar } = useGeneralContext();
  const { getSetting, getAuthState } = useAuthContext();
  const { t } = useTranslation();
  const [productList, setProductList] = useState([]);
  const { itemsDetails } = useStoreItemsDetails((state) => ({
    itemsDetails: state.itemsDetails,
  }));

  const [open, setOpen] = useState(false);

  const { prodCategory } = useStoreProductCategory((state) => ({
    prodCategory: state.prodCategory,
  }));

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
      keyMapper: (index) => productList[index]?.uuid_product || index,
    }),
  );

  const handleClickItem = (data) => {
    data.group = [];
    if (
      getSetting().isDirectOrder &&
      !data.is_variant &&
      data.modifier_group?.length < 1 &&
      data.group.length < 1 &&
      !data.is_have_group
    ) {
      data.timestamp = new Date().getTime();
      data.quantity = 1;
      addToCart(data);
      openSuccessSnackBar(`1 x "${data.plu_name}" ${t('hasBeenAddedToCart')}`);
    } else {
      setCartModal(true, data);
    }
  };

  const rowRenderer = ({ key, index, style }) => {
    const dataIndex = productList[index];
    const dataVariants = itemsDetails.get(dataIndex.uuid_product);
    const data = { ...dataIndex, ...dataVariants };
    return (
      <div
        key={key}
        style={{
          ...style,
          paddingTop: '5px',
          paddingRight: theme.spacing(2),
          paddingLeft: theme.spacing(2),
        }}>
        <Box
          boxShadow={3}
          className={`${classes.menus} ${
            !isProductActive(data) && classes.disabledMenus
          }`}>
          <ButtonBase
            style={{ width: '100%' }}
            disabled={!isProductActive(data)}>
            <PanelComponent
              classes={classes}
              t={t}
              getSellBandId={getSellBandId}
              getSetting={getSetting}
              data={data}
              onClickItem={(data) => {
                handleClickItem({ ...data, signature_token: uuidv4() });
              }}
            />
          </ButtonBase>
        </Box>
      </div>
    );
  };

  useEffect(() => {
    const productMap = prodCategory.get(category.uuid_product_category);
    // if (!getAuthState('productTabSelected').products && products) {
    //   console.log('kosong-----');
    //   setOpen(true);
    //   spreadProductData(products);
    // console.log(productMap)
    // }
    setProductList(productMap.products);

    // eslint-disable-next-line
  }, [getAuthState('tabCategory').index, category]);

  const checkLengthActive = (data) => {
    const newData = data.filter(
      (x) => !x.is_variant || (x.variant_item && x.variant_item.length > 0),
    );
    const response = {
      length: newData.length,
      data: newData,
    };
    return response;
  };

  const rowCount = checkLengthActive(productList).length;

  return (
    <div sx={{ height: '100vh' }}>
      <AlertProductDialog
        open={open}
        data={category}
        close={() => setOpen(false)}
      />
      <Box className={classes.panelTitle}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          {' '}
          &#8226; {category.category_name}
        </Typography>
      </Box>
      {productList && productList.length > 0 && (
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                width={width}
                height={height * rowCount * 2}
                className={classes.viewList}
                style={{
                  paddingBottom:
                    getBadgeCount() > 0 && isLoggedIn()
                      ? theme.spacing(18)
                      : theme.spacing(1),
                }}
                rowCount={rowCount}
                rowHeight={100}
                rowRenderer={rowRenderer}
              />
            );
          }}
        </AutoSizer>
      )}
    </div>
  );
};

export default ReListViewPanelTab;
