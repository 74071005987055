import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, ButtonGroup, Button, Typography } from '@mui/material';
import { Remove, Add } from '@mui/icons-material';
import { useStoreAppColor } from '@state/useStoreAppColor';
import theme from '@styles/theme';

const useStyles = makeStyles({
  buttonQty: {
    display: 'flex',
  },
});

const ReButtonQuantity = ({
  onChangeValue = () => { },
  propsValue,
  max,
  qtyProduct,
  isDisable,
  small
}) => {
  const { appColor } = useStoreAppColor(state => ({
    appColor: state.appColor
  }))
  const classes = useStyles();
  const [value, setValue] = useState(propsValue || 1);

  const handleIncreaseQty = () => {
    setValue(value + 1);
    onChangeValue(value + 1);
  };

  const handleDecreaseQty = () => {
    setValue(value - 1);
    onChangeValue(value - 1);
  };

  useEffect(() => {
    setValue(propsValue || 1);
  }, [propsValue]);

  return (
    <Box className={classes.buttonQty}>
      <ButtonGroup
        id={'qtyButton'}
        size="small"
        variant="contained"
        sx={{ 
          height: 25, 
           border: 'none',
          outline: 'none',
          '& .MuiButtonGroup-grouped': {
            border: 'none !important',  // Use !important to override default styles
          }
            }}>
        <Button
          classes={{ label: 'class-btn-minus' }}
          id={'minusButton'}
          onClick={handleDecreaseQty}
          disabled={value === 1 || isDisable}
          sx={{
            border: 'none',
            backgroundColor: value !== 1 ? appColor.primaryThemeColor : '', 
            padding: 0,
            minWidth: small ? theme.spacing(7) : theme.spacing(10),
            '&:hover': {
              backgroundColor: appColor.secondaryThemeColor,
              border: 'none',
            },
          }}>
          <Remove
            sx={{ fontSize: 16, color: appColor.secondaryFontColor }}
          />
        </Button>
        <Box
          id={'countButton'}
          sx={{
            width: 20,
            textAlign: 'center',
            color: appColor.secondaryFontColor,
            border:'none',
            backgroundColor: appColor.primaryThemeColor,
          }}>
          <Typography variant="caption">
            {value * (qtyProduct > 1 ? qtyProduct : 1)}
          </Typography>
        </Box>
        <Button
          classes={{ label: 'class-btn-plus' }}
          id={'plusButton'}
          onClick={handleIncreaseQty}
          disabled={value > max || isDisable}
          sx={{
            border: 'none',
            backgroundColor: appColor.primaryThemeColor, 
            padding: 0, 
            minWidth: small ? theme.spacing(6) : theme.spacing(10),
            '&:hover': {
              backgroundColor: appColor.secondaryThemeColor,
              border: 'none',
            }
          }}>
          <Add
            sx={{ fontSize: 16, color: appColor.secondaryFontColor, padding: 0 }}
          />
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default ReButtonQuantity;
