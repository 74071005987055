import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames"
export const useStoreOutlet = create(persist((set, get) => ({
    outlet: {},
    kodeOutlet: '008',
    _hasHydratedOutlet: false,
    setHasHydratedOutlet: (state) => {
        set({  _hasHydratedOutlet: state  });
    },
    updateOutlet: (newOutlet) => {
        set({ outlet: newOutlet })
    },
    getOutletInfo: () => {
        return get().outlet
    },
    getOutletName: () => {
        return get().outlet.outletName || '';
    },
    getTableId: () => {
        return get().outlet.tableId
    },
    getOutletId: () => {
        return get().outlet.outletId || '';
    },
    getTableName: () => {
        return get().outlet.tableName || '';
    },
    getSalesType: () => {
        let outletInfo = get().outlet;
        return outletInfo.salestype || [];
    },
    getActiveSalesType: () => {
        const salesType = get().getSalesType().sales;
        const sales = salesType.filter((sales) => sales.sequence > 0);
        return sales[0];
    },
    setOpen: (value) => {
        let currentOuletSettings = get().outlet
        currentOuletSettings["openingHours"] = value
        saveOutletData(currentOuletSettings)
        set({ outlet: currentOuletSettings })
    },
    isOpen: () => {
        let outletInfo = get().outlet;
        return outletInfo.openingHours;
    },
    getNameSalesType: (paramId) => {
        if (paramId) {
            let salesTypes = get().outlet.salestype.sales;

            let index = salesTypes.findIndex(
                (x) => x.type_sales_id.toString() === paramId.toString(),
            );

            return salesTypes[index]?.title;
        }
        return null;
    },
    clearOutlet: () => set({ outlet: {} })
}),
    {
        name: localStorageNames.OUTLET,
        onRehydrateStorage: () => (state) => {
            state.setHasHydratedOutlet(true)
        }
    }
))